<template>
    <div class="cabinet">
        <div class="navigation">
            <SidebarPage v-if="showSidebar"/>
            <div class="lk">
                <NavbarPage/>
                <ModalPage v-if="showModal" @regdone="showModal = !showModal" :result="modalResult" @closeModal="closeModal" />
                <router-view v-else></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import ModalPage from './ModalPage.vue';
import NavbarPage from './NavbarPage.vue';
import SidebarPage from './SidebarPage.vue';

export default {
    components: { NavbarPage, SidebarPage, ModalPage},
    data() {
    return {
      showModal: false,
      modalResult: 0,
      showSidebar: false,
    };
  },
  mounted() {
    this.checkUserProfile();
    this.delaySidebar();
  },
  methods: {
    delaySidebar() {
      setTimeout(() => {
        this.showSidebar = true;
      }, 300);
    },
    async checkUserProfile() {
      try {
        const response = await this.$axios.post('/api/checkprofile/' + this.$store.getters.getRegistrationData.user_id, {withCredentials: true}, {withCredentials: true});
        
        // Проверяем результат и показываем модальное окно при необходимости
        if (response.data.result === 0) {
          this.showModal = true;
          this.modalResult = 0;
          console.log('окно показанно')
        }else if(response.data.result===2){
          this.$router.push('/dashboard/home/')
        } else {
          this.modalResult = 1;
          this.$router.push('/dashboard/service/')
        }
      } catch (error) {
        console.error('Ошибка при выполнении запроса:', error);
      }
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>


<style scoped>
.navigation{
    display: flex;
}

.cabinet{
    background-color: #FAFAFA;
}

.lk{
    width: 100%;
    min-height: 100vh;
    padding: 20px 20px 20px 0;
    margin-left: 300px;
}
@media (max-width: 768px){
  .lk {
    padding: 0;
    border-radius: 0;
    margin-left: 65px;
  }
}
</style>