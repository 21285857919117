<template>
    <div class="lock">
      <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40.8499 62.35V45.15H45.1499V62.35H40.8499Z" fill="#535C69"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.6499 27.95V20.0667C23.6499 9.06771 32.2313 0 42.9999 0C53.7685 0 62.3499 9.06771 62.3499 20.0667V27.95H64.4999C69.2495 27.95 73.0999 31.8004 73.0999 36.55V70.95C73.0999 75.6996 69.2495 79.55 64.4999 79.55H21.4999C16.7503 79.55 12.8999 75.6996 12.8999 70.95V36.55C12.8999 31.8004 16.7503 27.95 21.4999 27.95H23.6499ZM27.9499 20.0667C27.9499 11.2754 34.7699 4.3 42.9999 4.3C51.2299 4.3 58.0499 11.2754 58.0499 20.0667V27.95H27.9499V20.0667ZM68.7999 70.95V36.55C68.7999 34.1752 66.8747 32.25 64.4999 32.25H21.4999C19.1251 32.25 17.1999 34.1752 17.1999 36.55V70.95C17.1999 73.3248 19.1251 75.25 21.4999 75.25H64.4999C66.8747 75.25 68.7999 73.3248 68.7999 70.95Z" fill="#535C69"/>
      </svg>
      <p>Вам закрыт доступ в этот раздел :(</p>
      <p>Вашего уровня доступа не достаточно или вы заполнили не всю информацию о компании (сотрудники, услуги, филиалы), чтобы попасть на эту страницу.</p>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.lock {
  height: 87vh;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  }
  
  .lock p {
    color: #535C69;
    font-family: "TT Norms Medium";
    font-size: 20px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
  }
  
  .lock p:last-child {
    font-size: 16px;
    line-height: 16px;
    color: #AFB6C1;
  }
  p{
    margin: 0;
    max-width: 400px;
  }
  @media (max-width: 768px){
    svg{
      height: 54px;
      width: 54px;
    }
    .lock p {
      color: #535C69;
      font-family: "TT Norms Medium";
      font-size: 16px;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: center;
    }
    .lock p:last-child {
      font-size: 13px;
      line-height: 16px;
      color: #AFB6C1;
    }
  }
</style>