<template>
  <div class="modal">
    <div :class="{'overlay-show' : true}"></div>
    <div :class="{'modal-show' : true}">
      <div class="modal-content">
        <p class="text-header-modal">Несохраненные изменения</p>
        <p class="modal-subtext">
          Мы заметили, что вы не заполнили данные до конца. Вы хотите завершить
          настройку и сохранить изменения?
        </p>
        <div class="btn_container">
          <button class="delete" @click="$emit('confirm')">Завершить настройку</button>
          <button class="exit" @click="$emit('cancel')">Покинуть раздел</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
    }
  },
}
</script>

<style scoped>
.overlay-show {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Задний фон с прозрачностью 60% */
  z-index: 98;
  opacity: 1;
  visibility: visible;
  transition: all .1s ease;
}
.overlay-hide {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Задний фон с прозрачностью 60% */
  z-index: 98;
  opacity: 0;
  visibility: hidden;
  transition: all .1s ease;
}
.modal-show{
  height: auto;
  position: absolute;
  padding: 40px;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  transition: all .1s ease;
  max-width: 400px;
}
.modal-hide{
  max-width: 400px;
  height: auto;
  position: absolute;
  padding: 40px;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  transition: all .1s ease;
}
.text-header-modal{
  font-family: TT Norms Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.24px;
  text-align: left;
  margin: 0;
  color: #535C69;
}
.modal-subtext{
  font-family: TT Norms Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  letter-spacing: 0em;
  text-align: left;
  color: #AFB6C1;
  margin: 0;
  margin-top: 10px;
}
.delete{
  color: #F97F7F;
  background-color: rgba(249, 127, 127, 0.2);
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}
.delete:hover{
  background: #F97F7F;
  color: #FFFFFF;
}
.exit{
  color: #535C69;
  border: 1px solid #DDE1E5;
  background: #FFFFFF;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}
.exit:hover{
  border: 1px solid #AFB6C1;
  background: #F5F5F5;
}
.btn_container{
  margin-top: 30px;
  display: flex;
  gap: 10px;
}
</style>
