<template>
  <div class="main">
    <div class="transition">
      <router-link to="/dashboard/project" class="eidt-link">Мои проекты</router-link>
      <div class="arrow-container">
        <img src="../../static/img/arrow-right.png" alt="Стрелка вправо" class="arrow-icon">
      </div>
      <p class="creation_text" style="color: #AFB6C1;">Редактирование</p>
      <div class="arrow-container">
        <img src="../../static/img/arrow-right.png" alt="Стрелка вправо" class="arrow-icon">
      </div>
      <p class="creation_text">{{ companyName }}</p>
    </div>
    <div class="edit">
      <div class="head_container">
        <p class="header_text">Редактирование проекта</p>
        <p class="subheader_text">Здесь вы можете изменить основные данные проекта</p>
      </div>
      <div class="form">
        <div class="form_container">
          <label for="">Название проекта</label>
          <input maxlength="25" type="text" placeholder="Введите название проекта" v-model="companyName">
          <p class="small-text">Для ввода доступно — <span class="remaining-characters">{{ remainingCharacters }}</span>/25</p>
        </div>
        <div class="form_container">
          <label for="service">Часовой пояс</label>
          <SelectPage
          :options="['0 — Лондон, Дублин', '+1 — Париж, Рим', '+2 — Афины, Каир', '+3 — Москва, Стамбул', '+4 — Дубай, Новосибирск', '+5 — Астана, Ташкент', '+6 — Омск, Бишкек', '+7 — Бангкок, Джакарта', '+8 — Гонконг, Сингапур', '-8 — Лос-Анджелес, Ванкувер', '-7 — Денвер, Эдмонтон', '-6 — Чикаго, Мехико', '-5 — Нью-Йорк, Монреаль', '-4 — Галифакс, Каракас', '-3 — Рио-де-Жанейро, Буэнос-Айрес']"          :default="'Выберите свой часовой пояс'"
          class="select"
          :value="this.timezone"
          @input="option => this.timezone = option"
          />
        </div>
        <div class="form_container">
          <label for="service">Валюта</label>
          <SelectPage
          :options="['RUB — Российский рубль', 'BYN — Белорусский рубль', 'USD — Доллар США', 'EUR — Евро', 'KZT — Казахстанский тенге', 'UAH — Украинская гривна', 'CNY — Китайский юань']"
          :value="this.currency"
          class="select"
          @input="option => this.currency = option"
          />
        </div>
      </div>
      <div class="picker">
        <p class="color_text">Цвет иконки проекта</p>
        <div class="color_container">
          <div class="color" @click="color = '#F3F5F6'" style="background:#F3F5F6"><img v-if="color == '#F3F5F6'" src="../../static/img/checkbox.png" alt=""></div>
          <div class="color" @click="color = '#7DCD37'" style="background:#7DCD37"><img v-if="color == '#7DCD37'" src="../../static/img/checkbox.png" alt=""></div>
          <div class="color" @click="color = '#28CCF0'" style="background:#28CCF0"><img v-if="color == '#28CCF0'" src="../../static/img/checkbox.png" alt=""></div>
          <div class="color" @click="color = '#9D8FF1'" style="background:#9D8FF1"><img v-if="color == '#9D8FF1'" src="../../static/img/checkbox.png" alt=""></div>
          <div class="color" @click="color = '#FB88C0'" style="background:#FB88C0"><img v-if="color == '#FB88C0'" src="../../static/img/checkbox.png" alt=""></div>
          <div class="color" @click="color = '#F97F7F'" style="background:#F97F7F"><img v-if="color == '#F97F7F'" src="../../static/img/checkbox.png" alt=""></div>
          <div class="color" @click="color = '#F7D37D'" style="background:#F7D37D"><img v-if="color == '#F7D37D'" src="../../static/img/checkbox.png" alt=""></div>
        </div>
      </div>

      <div class="bottom">
        <div class="bottom_container">
          <button class="save" @click="editProject()">Сохранить</button>
          <button class="back" @click="this.$router.push('/dashboard/project')">Вернуться</button>
        </div>
        
        <button class="delete" @click="toggleModal">
          <svg id="delete" @click="toggleModal" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.36363 1.77778C3.36363 1.34822 3.72994 1 4.18181 1H11.8182C12.2701 1 12.6364 1.34822 12.6364 1.77778C12.6364 2.20733 12.2701 2.55556 11.8182 2.55556H4.18181C3.72994 2.55556 3.36363 2.20733 3.36363 1.77778ZM2.81818 5.66667H2V4.11111H14V5.66667H13.1818V12.1481C13.1818 13.7232 11.8387 15 10.1818 15H5.81818C4.16132 15 2.81818 13.7232 2.81818 12.1481V5.66667ZM11.5455 5.66667H4.45455V12.1481C4.45455 12.8641 5.06508 13.4444 5.81818 13.4444H10.1818C10.9349 13.4444 11.5455 12.8641 11.5455 12.1481V5.66667Z" fill="#D2D8DE"/>
          </svg>
          Удалить проект
        </button>
      </div>
    </div>
    <div :class="{'overlay-show' : showModal, 'overlay-hide' : !showModal}"></div>
    <div :class="{'modal-show' : showModal, 'modal-hide' : !showModal}">
          <div class="modal-content">
            <p class="text-header">Удаление проекта</p>
            <p class="modal-subtext">Вы действительно хотите удалить<br> проект <span class="modal_span"> {{ companyName }}</span>?</p>
            <div class="btn_container">
              <button class="delete_edit" @click="deleteProject">Удалить</button>
              <button class="exit" @click="toggleModal">Отмена</button>
            </div>
          </div>
    </div>
    <MessageAlert :message="alertMessage" :color="alertColor"/>
  </div>
</template>

<script>
import SelectPage from '../components/SelectPage.vue';
import MessageAlert from "./MessageAlert.vue";

export default {
  components: { SelectPage, MessageAlert },
  props: ['project'],
  data() {
    return {
      companyName: '',
      currency: '',
      timezone: '',
      color: '',
      alertMessage: '',
      alertColor: '',

      showModal: false,
    };
  },
  computed: {
    remainingCharacters() {
      const maxLength = 25;
      return maxLength - this.companyName.length;
    },
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    async fetchProject() {
      try {
        const response = await this.$axios.get('/api/get_projectbyid/', {
          params: {
            variable: this.project
          }
        });
        this.companyName = response.data.name
        this.currency = response.data.currency
        this.timezone = response.data.timezone
        this.color = response.data.colour
      } catch (error) {
        console.error('Error fetching project:', error);
      }
    },
    async deleteProject()
    {
      try
      {
        const response = await this.$axios.delete(`/api/delete_project/${this.project}/`, {withCredentials: true}, {withCredentials: true});
        
        this.toggleModal();
        this.alertMessage = 'Проект ' + this.companyName + ' успешно удалён'
        this.alertColor = '#212326'
        

        setTimeout(() => {
          this.$router.push('/dashboard/project');
        }, 2000)
        
        
        return response.data;
      }
      catch (error) 
      {
        this.alertMessage = 'Произошла ошибка при удалении проекта'
        this.alertColor = '#F97F7F'
        throw error;
      }
    },
    async editProject()
    {
      try
      {
        let formdata = new FormData();
        formdata.append('name', this.companyName);
        formdata.append('timezone', this.timezone);
        formdata.append('currency', this.currency);
        formdata.append('colour', this.color);

        const response = await this.$axios.patch(`/api/edit_project/${this.project}/`, formdata, {withCredentials: true});
        this.alertMessage = 'Настройки успешно сохранены'
        this.alertColor = '#0BB6A1'
        setTimeout(() => {
          this.$router.push('/dashboard/project');
        }, 2000)
        return response.data;
      }
      catch (error)
      {
        console.error('Error updating project:', error);
        throw error;
      }
    },
  },
  mounted(){
    this.fetchProject();
  }
};
</script>

<style scoped>
.modal-show{
    width: auto;
    height: auto;
    position: absolute;
    padding: 40px;
    border-radius: 10px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: white;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    transition: all .1s ease;
  }
  .modal-hide{
    width: auto;
    height: auto;
    position: absolute;
    padding: 40px;
    border-radius: 10px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: white;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    transition: all .1s ease;
  }
  .overlay-show {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Задний фон с прозрачностью 60% */
    z-index: 98;
    opacity: 1;
    visibility: visible;
    transition: all .1s ease;
  }
  .overlay-hide {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Задний фон с прозрачностью 60% */
    z-index: 98;
    opacity: 0;
    visibility: hidden;
    transition: all .1s ease;
  }
.delete svg path{
  fill: #F97F7F;
  transition: all .2s ease;
}
.delete:hover svg path{
  fill: #ffffff;
}
.transition {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
}
.eidt-link {
  font-family: TT Norms Medium;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  text-decoration: none;
  color: #AFB6C1;
}
.creation_text {
  color: #535C69;
  font-family: TT Norms Medium;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
}
.arrow-container {
  display: flex;
  align-items: center;
}
.arrow-icon {
  height: 50%;
}
.edit {
  width: 50%;
  height: auto;
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: 0px 2px 4px 0px #7D879033;
}
.form{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.head_container{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.form_container{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.header_text{
  font-family: TT Norms Medium;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #535C69;
  margin: 0;
}
.subheader_text{
  font-family: TT Norms Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #AFB6C1;
  margin: 0;
}
select {
  padding: 10px;
  font-family: TT Norms Medium;
  font-size: 14px;
  line-height: 20px;
  color: #D2D8DE;
  border: none;
  background-color: #F3F5F6;
  border-radius: 3px;
}
input{
  margin: 0;
}
select option {
  font-family: TT Norms Medium;
  font-size: 14px;
  line-height: 20px;
  color: #535C69;
}
select#service {
  width: 100%;
  padding: 10px;
  font-family: TT Norms Medium;
  font-size: 14px;
  line-height: 20px;
  color: #D2D8DE;
  border: none;
  background-color: #F3F5F6;
}
label{
  margin: 0;
}
.color_text{
  font-family: TT Norms Medium;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #52565F;
}
.color_container{
  display: flex;
  gap: 5px;
}
.color{
  width: 24px;
  height: 24px;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
}
.color img{
  position: absolute;
  top: 25%;
  left: 25%;
}
.bottom_container{
  display: flex;
  gap: 10px;
  width: 50%;
}
.bottom{
  display: flex;
  justify-content: space-between;
}
.save{
  background: #EFEFFF;
  color: #6266EA;
}
.back{
  color: #535C69;
  background: #FFFFFF;
  border: 1px solid #DDE1E5;
}
.save:hover{
  color: #FFFFFF;
  background: #6266EA;
}
.delete{
  color: #F97F7F;
  background: #F97F7F33;
}
.delete:hover{
  background: #F97F7F;
  color: #FFFFFF;
}
.small-text {
  color:#D2D8DE;
  font-family: "TT Norms Medium";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  margin: 0;
}
.small-text .remaining-characters {
  color: #6266EA;
}
.modal{
  width: 36vh;
  height: auto;
  position: absolute;
  padding: 40px;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  z-index: 99;
}
.delete_edit{
  color: #F97F7F;
  background-color: rgba(249, 127, 127, 0.2);
  font-size: 14px;
  line-height: 16.52px;
  text-align: center;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.delete_edit:hover{
  color: #ffffff;
  background-color: #F97F7F;
}

.delete:hover{
  background: #F97F7F;
  color: #FFFFFF;
}
.exit{
  color: #535C69;
  border: 1px solid #DDE1E5;
  background: #FFFFFF;  
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.52px;
}
.exit:hover{
  border: 1px solid #AFB6C1;
  background: #F5F5F5;
}
.btn_container{
  margin-top: 30px;
  display: flex;
  gap: 10px;
}
.text-header{
  font-family: TT Norms Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.24px;
  text-align: left;
  margin: 0;
  color: #535C69;
}
.modal-subtext{
  font-family: TT Norms Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  letter-spacing: 0em;
  text-align: left;
  color: #AFB6C1;
  margin: 0;
  margin-top: 10px;
}
.modal_span{
  color: #AFB6C1;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Задний фон с прозрачностью 60% */
  z-index: 98;
}
.back:hover{
  color: #6266EA;
}
</style>