<template>
  <div class="palitra">
    <div class="palitra_container">
      <div class="svg_container">
        <svg width="12" height="12" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.86932 4.41535L6.98265 4.29673C7.18369 4.09183 7.18735 3.851 6.97899 3.6461L6.87662 3.54547C7.45785 3.04221 8.08659 2.9919 8.49599 2.5857C9.06256 2.02135 8.91636 1.20899 8.48136 0.777624C8.05002 0.346287 7.22389 0.213271 6.64268 0.763266C6.22598 1.16225 6.17845 1.78413 5.66668 2.35566L5.56801 2.255C5.35965 2.05013 5.11108 2.05731 4.90271 2.25142L4.78208 2.36284C4.53718 2.58931 4.57374 2.80499 4.79305 3.01707L4.93928 3.16445L2.26351 5.79567C1.08647 6.95673 1.55437 6.93155 0.980469 7.7152L1.35697 8.1034C2.12827 7.54265 2.17214 8.04947 3.36014 6.88842L6.05418 4.25719L6.20405 4.40457C6.42335 4.61665 6.63538 4.6526 6.86932 4.41535ZM2.21234 6.96033C2.09171 6.83092 2.11364 6.70869 2.2489 6.57571L5.32308 3.53829L5.68131 3.89414L2.59617 6.92798C2.47554 7.04657 2.32567 7.08253 2.21234 6.96033Z" fill="#1A88FF"/>
        </svg>
      </div>
      <p style="width: auto;">Выберите цвет</p>
      <div class="circle_container">
        <div class="palitra_circle" @click="submit">
          <svg width="12" height="12" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.58578 10L4.29288 14.2929L5.70709 15.7071L10 11.4142L14.2929 15.7071L15.7071 14.2929L11.4142 10L15.7071 5.70712L14.2929 4.29291L10 8.58579L5.70712 4.29291L4.29291 5.70712L8.58578 10Z" fill="#AFB6C1"/>
          </svg>
        </div>
      </div>
    </div>

    <div class="palette">
      <div class="main_color" style="background:#FEFFFE" @click="selectedColor = '#FEFFFE'; selectColor()"></div>
      <div class="main_color" style="background:#EBEBEB" @click="selectedColor = '#EBEBEB'; selectColor()"></div>
      <div class="main_color" style="background:#D6D6D6" @click="selectedColor = '#D6D6D6'; selectColor()"></div>
      <div class="main_color" style="background:#C2C2C2" @click="selectedColor = '#C2C2C2'; selectColor()"></div>
      <div class="main_color" style="background:#ADADAD" @click="selectedColor = '#ADADAD'; selectColor()"></div>
      <div class="main_color" style="background:#999999" @click="selectedColor = '#999999'; selectColor()"></div>
      <div class="main_color" style="background:#858585" @click="selectedColor = '#858585'; selectColor()"></div>
      <div class="main_color" style="background:#707070" @click="selectedColor = '#707070'; selectColor()"></div>
      <div class="main_color" style="background:#5C5C5C" @click="selectedColor = '#5C5C5C'; selectColor()"></div>
      <div class="main_color" style="background:#474747" @click="selectedColor = '#474747'; selectColor()"></div>
      <div class="main_color" style="background:#333333" @click="selectedColor = '#333333'; selectColor()"></div>
      <div class="main_color" style="background:#000000" @click="selectedColor = '#000000'; selectColor()"></div>
      <div class="main_color" style="background:#00374A" @click="selectedColor = '#00374A'; selectColor()"></div>
      <div class="main_color" style="background:#011D57" @click="selectedColor = '#011D57'; selectColor()"></div>
      <div class="main_color" style="background:#11053B" @click="selectedColor = '#11053B'; selectColor()"></div>
      <div class="main_color" style="background:#2E063D" @click="selectedColor = '#2E063D'; selectColor()"></div>
      <div class="main_color" style="background:#3C071B" @click="selectedColor = '#3C071B'; selectColor()"></div>
      <div class="main_color" style="background:#5C0701" @click="selectedColor = '#5C0701'; selectColor()"></div>
      <div class="main_color" style="background:#5A1C00" @click="selectedColor = '#5A1C00'; selectColor()"></div>
      <div class="main_color" style="background:#583300" @click="selectedColor = '#583300'; selectColor()"></div>
      <div class="main_color" style="background:#563D00" @click="selectedColor = '#563D00'; selectColor()"></div>
      <div class="main_color" style="background:#666100" @click="selectedColor = '#666100'; selectColor()"></div>
      <div class="main_color" style="background:#4F5504" @click="selectedColor = '#4F5504'; selectColor()"></div>
      <div class="main_color" style="background:#263E0F" @click="selectedColor = '#263E0F'; selectColor()"></div>
      <div class="main_color" style="background:#004D65" @click="selectedColor = '#004D65'; selectColor()"></div>
      <div class="main_color" style="background:#012F7B" @click="selectedColor = '#012F7B'; selectColor()"></div>
      <div class="main_color" style="background:#1A0A52" @click="selectedColor = '#1A0A52'; selectColor()"></div>
      <div class="main_color" style="background:#450D59" @click="selectedColor = '#450D59'; selectColor()"></div>
      <div class="main_color" style="background:#551029" @click="selectedColor = '#551029'; selectColor()"></div>
      <div class="main_color" style="background:#831100" @click="selectedColor = '#831100'; selectColor()"></div>
      <div class="main_color" style="background:#7B2900" @click="selectedColor = '#7B2900'; selectColor()"></div>
      <div class="main_color" style="background:#7A4A00" @click="selectedColor = '#7A4A00'; selectColor()"></div>
      <div class="main_color" style="background:#785800" @click="selectedColor = '#785800'; selectColor()"></div>
      <div class="main_color" style="background:#8D8602" @click="selectedColor = '#8D8602'; selectColor()"></div>
      <div class="main_color" style="background:#6F760A" @click="selectedColor = '#6F760A'; selectColor()"></div>
      <div class="main_color" style="background:#38571A" @click="selectedColor = '#38571A'; selectColor()"></div>
      <div class="main_color" style="background:#016E8F" @click="selectedColor = '#016E8F'; selectColor()"></div>
      <div class="main_color" style="background:#0042A9" @click="selectedColor = '#0042A9'; selectColor()"></div>
      <div class="main_color" style="background:#2C0977" @click="selectedColor = '#2C0977'; selectColor()"></div>
      <div class="main_color" style="background:#61187C" @click="selectedColor = '#61187C'; selectColor()"></div>
      <div class="main_color" style="background:#791A3D" @click="selectedColor = '#791A3D'; selectColor()"></div>
      <div class="main_color" style="background:#B51A00" @click="selectedColor = '#B51A00'; selectColor()"></div>
      <div class="main_color" style="background:#AD3E00" @click="selectedColor = '#AD3E00'; selectColor()"></div>
      <div class="main_color" style="background:#A96800" @click="selectedColor = '#A96800'; selectColor()"></div>
      <div class="main_color" style="background:#A67B01" @click="selectedColor = '#A67B01'; selectColor()"></div>
      <div class="main_color" style="background:#C4BC00" @click="selectedColor = '#C4BC00'; selectColor()"></div>
      <div class="main_color" style="background:#9BA50E" @click="selectedColor = '#9BA50E'; selectColor()"></div>
      <div class="main_color" style="background:#4E7A27" @click="selectedColor = '#4E7A27'; selectColor()"></div>
      <div class="main_color" style="background:#008CB4" @click="selectedColor = '#008CB4'; selectColor()"></div>
      <div class="main_color" style="background:#0056D6" @click="selectedColor = '#0056D6'; selectColor()"></div>
      <div class="main_color" style="background:#371A94" @click="selectedColor = '#371A94'; selectColor()"></div>
      <div class="main_color" style="background:#7A219E" @click="selectedColor = '#7A219E'; selectColor()"></div>
      <div class="main_color" style="background:#99244F" @click="selectedColor = '#99244F'; selectColor()"></div>
      <div class="main_color" style="background:#E22400" @click="selectedColor = '#E22400'; selectColor()"></div>
      <div class="main_color" style="background:#DA5100" @click="selectedColor = '#DA5100'; selectColor()"></div>
      <div class="main_color" style="background:#D38301" @click="selectedColor = '#D38301'; selectColor()"></div>
      <div class="main_color" style="background:#D19D01" @click="selectedColor = '#D19D01'; selectColor()"></div>
      <div class="main_color" style="background:#F5EC00" @click="selectedColor = '#F5EC00'; selectColor()"></div>
      <div class="main_color" style="background:#C3D117" @click="selectedColor = '#C3D117'; selectColor()"></div>
      <div class="main_color" style="background:#669D34" @click="selectedColor = '#669D34'; selectColor()"></div>
      <div class="main_color" style="background:#00A1D8" @click="selectedColor = '#00A1D8'; selectColor()"></div>
      <div class="main_color" style="background:#0061FD" @click="selectedColor = '#0061FD'; selectColor()"></div>
      <div class="main_color" style="background:#4D22B2" @click="selectedColor = '#4D22B2'; selectColor()"></div>
      <div class="main_color" style="background:#982ABC" @click="selectedColor = '#982ABC'; selectColor()"></div>
      <div class="main_color" style="background:#B92D5D" @click="selectedColor = '#B92D5D'; selectColor()"></div>
      <div class="main_color" style="background:#FF4015" @click="selectedColor = '#FF4015'; selectColor()"></div>
      <div class="main_color" style="background:#FF6A00" @click="selectedColor = '#FF6A00'; selectColor()"></div>
      <div class="main_color" style="background:#FFAB01" @click="selectedColor = '#FFAB01'; selectColor()"></div>
      <div class="main_color" style="background:#FCC700" @click="selectedColor = '#FCC700'; selectColor()"></div>
      <div class="main_color" style="background:#FEFB41" @click="selectedColor = '#FEFB41'; selectColor()"></div>
      <div class="main_color" style="background:#D9EC37" @click="selectedColor = '#D9EC37'; selectColor()"></div>
      <div class="main_color" style="background:#76BB40" @click="selectedColor = '#76BB40'; selectColor()"></div>
      <div class="main_color" style="background:#01C7FC" @click="selectedColor = '#01C7FC'; selectColor()"></div>
      <div class="main_color" style="background:#3A87FD" @click="selectedColor = '#3A87FD'; selectColor()"></div>
      <div class="main_color" style="background:#5E30EB" @click="selectedColor = '#5E30EB'; selectColor()"></div>
      <div class="main_color" style="background:#BE38F3" @click="selectedColor = '#BE38F3'; selectColor()"></div>
      <div class="main_color" style="background:#E63B7A" @click="selectedColor = '#E63B7A'; selectColor()"></div>
      <div class="main_color" style="background:#FE6250" @click="selectedColor = '#FE6250'; selectColor()"></div>
      <div class="main_color" style="background:#FE8648" @click="selectedColor = '#FE8648'; selectColor()"></div>
      <div class="main_color" style="background:#FEB43F" @click="selectedColor = '#FEB43F'; selectColor()"></div>
      <div class="main_color" style="background:#FECB3E" @click="selectedColor = '#FECB3E'; selectColor()"></div>
      <div class="main_color" style="background:#FFF76B" @click="selectedColor = '#FFF76B'; selectColor()"></div>
      <div class="main_color" style="background:#E4EF65" @click="selectedColor = '#E4EF65'; selectColor()"></div>
      <div class="main_color" style="background:#96D35F" @click="selectedColor = '#96D35F'; selectColor()"></div>
      <div class="main_color" style="background:#52D6FC" @click="selectedColor = '#52D6FC'; selectColor()"></div>
      <div class="main_color" style="background:#74A7FF" @click="selectedColor = '#74A7FF'; selectColor()"></div>
      <div class="main_color" style="background:#864FFD" @click="selectedColor = '#864FFD'; selectColor()"></div>
      <div class="main_color" style="background:#D357FE" @click="selectedColor = '#D357FE'; selectColor()"></div>
      <div class="main_color" style="background:#EE719E" @click="selectedColor = '#EE719E'; selectColor()"></div>
      <div class="main_color" style="background:#FF8C82" @click="selectedColor = '#FF8C82'; selectColor()"></div>
      <div class="main_color" style="background:#FEA57D" @click="selectedColor = '#FEA57D'; selectColor()"></div>
      <div class="main_color" style="background:#FEC777" @click="selectedColor = '#FEC777'; selectColor()"></div>
      <div class="main_color" style="background:#FED977" @click="selectedColor = '#FED977'; selectColor()"></div>
      <div class="main_color" style="background:#FFF994" @click="selectedColor = '#FFF994'; selectColor()"></div>
      <div class="main_color" style="background:#EAF28F" @click="selectedColor = '#EAF28F'; selectColor()"></div>
      <div class="main_color" style="background:#B1DD8B" @click="selectedColor = '#B1DD8B'; selectColor()"></div>
      <div class="main_color" style="background:#93E3FC" @click="selectedColor = '#93E3FC'; selectColor()"></div>
      <div class="main_color" style="background:#A7C6FF" @click="selectedColor = '#A7C6FF'; selectColor()"></div>
      <div class="main_color" style="background:#B18CFE" @click="selectedColor = '#B18CFE'; selectColor()"></div>
      <div class="main_color" style="background:#E292FE" @click="selectedColor = '#E292FE'; selectColor()"></div>
      <div class="main_color" style="background:#F4A4C0" @click="selectedColor = '#F4A4C0'; selectColor()"></div>
      <div class="main_color" style="background:#FFB5AF" @click="selectedColor = '#FFB5AF'; selectColor()"></div>
      <div class="main_color" style="background:#FFC5AB" @click="selectedColor = '#FFC5AB'; selectColor()"></div>
      <div class="main_color" style="background:#FED9A8" @click="selectedColor = '#FED9A8'; selectColor()"></div>
      <div class="main_color" style="background:#FDE4A8" @click="selectedColor = '#FDE4A8'; selectColor()"></div>
      <div class="main_color" style="background:#FFFBB9" @click="selectedColor = '#FFFBB9'; selectColor()"></div>
      <div class="main_color" style="background:#F1F7B7" @click="selectedColor = '#F1F7B7'; selectColor()"></div>
      <div class="main_color" style="background:#CDE8B5" @click="selectedColor = '#CDE8B5'; selectColor()"></div>
      <div class="main_color" style="background:#CBF0FF" @click="selectedColor = '#CBF0FF'; selectColor()"></div>
      <div class="main_color" style="background:#D2E2FE" @click="selectedColor = '#D2E2FE'; selectColor()"></div>
      <div class="main_color" style="background:#D8C9FE" @click="selectedColor = '#D8C9FE'; selectColor()"></div>
      <div class="main_color" style="background:#EFCAFE" @click="selectedColor = '#EFCAFE'; selectColor()"></div>
      <div class="main_color" style="background:#F9D3E0" @click="selectedColor = '#F9D3E0'; selectColor()"></div>
      <div class="main_color" style="background:#FFDAD8" @click="selectedColor = '#FFDAD8'; selectColor()"></div>
      <div class="main_color" style="background:#FFE2D6" @click="selectedColor = '#FFE2D6'; selectColor()"></div>
      <div class="main_color" style="background:#FEECD4" @click="selectedColor = '#FEECD4'; selectColor()"></div>
      <div class="main_color" style="background:#FEF1D5" @click="selectedColor = '#FEF1D5'; selectColor()"></div>
      <div class="main_color" style="background:#FDFBDD" @click="selectedColor = '#FDFBDD'; selectColor()"></div>
      <div class="main_color" style="background:#F6FADB" @click="selectedColor = '#F6FADB'; selectColor()"></div>
      <div class="main_color" style="background:#DEEED4" @click="selectedColor = '#DEEED4'; selectColor()"></div>
    </div>
    <div class="hex_container">
      <p>Указать HEX</p>
      <div class="pick">
        <input type="text" v-model="selectedColor" maxlength="7">
      </div>
    </div>
    <div class="divider"></div>
    <div class="current_container">
      <div class="current_color" :style="{ backgroundColor: selectedColor }"></div>
      <div class="colors">
        <div class="small_color"></div>
        <div class="small_color" style="background:#B08CFE"></div>
        <div class="small_color" style="background:#F188AE"></div>
        <div class="small_color" style="background:#5626C7"></div>
        <div class="small_color" style="background:#17014F"></div>
        <div class="small_color" style="background:#FFC25A"></div>
        <div class="small_color" style="background:#FF8C82"></div>
        <div class="small_color" style="background:#FF552F"></div>
        <div class="small_color" style="background:#FE7869"></div>
        <div class="plus_svg">
          <svg width="10" height="10" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.00006 11V17H11.0001V11H17V9H11.0001V3H9.00006V9H3V11H9.00006Z" fill="#D2D8DE"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
export default {
  data() {
    return {
      selectedColor: '#B08CFE',
    };
  },
  methods: {
    selectColor() {
      console.log(this.selectedColor)
    },
    submit(){
      this.$emit('updateColor', this.selectedColor);
    }
  }
}
</script>

<style scoped>
.palitra{
  height: auto;
  background: #212326;
  border-radius: 5px;
  position: absolute;
  bottom: 60px;
  right: -60px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 999999;
}
.palitra_container{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.circle_container{
  display: flex;
  justify-content: center;
}
.palitra_circle{
  width: 20px;
  height: 20px;
  border-radius: 25px;
  background: #33373D;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all .2s ease;
}
.palitra_circle:hover{
  background: #585f6a;
}
p{
  font-family: TT Norms Medium;
  font-size: 12px;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
  color: #FFFFFF;
  margin: 0;
}
.hex_container{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pick{
  background: #33373D;  
  border-radius: 5px;
  color: #FFFFFF;
  width: auto;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.pick input{
  font-family: TT Norms Medium;
  font-size: 12px;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFFFFF;
  margin: 0;
  padding: 0;
  height: auto;
  width: 80px;
  background-color: transparent;
}
.divider {
  border-bottom: 1px solid #33373D; 
  width: auto;
  margin: 0;
}
.current_container{
  display: flex;
  justify-content: space-between;
  gap: 15px;
}
.current_color{
  width: 40px;
  height: 40px;
  border-radius: 10px;
}
.colors{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.small_color{
  width: 15px;
  height: 15px;
  background:#6266EA;
  border-radius: 25px;
}
.palette{
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(10, 1fr);
  border-radius: 7px;
  overflow: hidden;
}
.main_color{
  width: 14.57px;
  height: 13.87px;
  cursor: pointer;
  transition: all .2s ease;
}

.main_color:hover{
  filter: brightness(80%);
}
.plus_svg{
  display: flex;
  justify-content: center;
  align-items: center;
}
.svg_container{
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>