<template>
  <div class="site" :style="{'scale' : size==='desktop' ? '1' : '1'}">
    <div class="main" :id="colortheme">
      <div v-if="!activeFilial.name" class="compo-container" :style="{ backgroundImage: `url(/${widgetData.cover})`, backgroundSize: 'cover' }">
        <div class="compo-wrap">
          <div class="compo-top">
          <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5 4V3.5C5 1.84315 6.34315 0.5 8 0.5H12C13.6569 0.5 15 1.84315 15 3.5V4H18C19.1046 4 20 4.89543 20 6V16C20 17.1046 19.1046 18 18 18H2C0.895431 18 0 17.1046 0 16V6C0 4.89543 0.895431 4 2 4H5ZM7 3.5C7 2.94772 7.44772 2.5 8 2.5H12C12.5523 2.5 13 2.94772 13 3.5V4H7V3.5ZM10 10C10.8284 10 11.5 9.32843 11.5 8.5C11.5 7.67157 10.8284 7 10 7C9.17157 7 8.5 7.67157 8.5 8.5C8.5 9.32843 9.17157 10 10 10Z" fill="white"/>
          </svg>
          <p class="compo-text">{{ activeFilial.name || 'Пожалуйста, выбирите филиал' }}</p>
        </div>
          <div class="compo-top" v-if="activeFilial.name">
          <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2 7.98124C2 3.52416 5.53247 0 10 0C14.3636 0 18 3.62781 18 7.98124C18 13.3347 10 20 10 20C10 20 2 13.4383 2 7.98124ZM10 11C11.6569 11 13 9.65685 13 8C13 6.34315 11.6569 5 10 5C8.34315 5 7 6.34315 7 8C7 9.65685 8.34315 11 10 11Z" fill="white"/>
          </svg>
          <p class="compo-text">{{ activeFilial.address }}</p>
          </div>
        </div>
      </div>

      <div v-else class="compo-container" :style="{ backgroundImage: `url('/${activeFilial.images[index_slider].image}')` }" style="background-size: cover;">
        <div class="compo-wrap">
          <div class="compo-top">
          <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5 4V3.5C5 1.84315 6.34315 0.5 8 0.5H12C13.6569 0.5 15 1.84315 15 3.5V4H18C19.1046 4 20 4.89543 20 6V16C20 17.1046 19.1046 18 18 18H2C0.895431 18 0 17.1046 0 16V6C0 4.89543 0.895431 4 2 4H5ZM7 3.5C7 2.94772 7.44772 2.5 8 2.5H12C12.5523 2.5 13 2.94772 13 3.5V4H7V3.5ZM10 10C10.8284 10 11.5 9.32843 11.5 8.5C11.5 7.67157 10.8284 7 10 7C9.17157 7 8.5 7.67157 8.5 8.5C8.5 9.32843 9.17157 10 10 10Z" fill="white"/>
          </svg>
          <p class="compo-text">{{ activeFilial.name || 'Пожалуйста, выбирите филиал' }}</p>
        </div>
          <div class="compo-top" v-if="activeFilial.name">
          <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2 7.98124C2 3.52416 5.53247 0 10 0C14.3636 0 18 3.62781 18 7.98124C18 13.3347 10 20 10 20C10 20 2 13.4383 2 7.98124ZM10 11C11.6569 11 13 9.65685 13 8C13 6.34315 11.6569 5 10 5C8.34315 5 7 6.34315 7 8C7 9.65685 8.34315 11 10 11Z" fill="white"/>
          </svg>
          <p class="compo-text">{{ activeFilial.address }}</p>
          </div>
        </div>
        <div class="divider_container">
          <div 
            :style="{ backgroundColor: index_slider === (i-1) ? '#ffffffc8' : '' }" 
            v-for="i in activeFilial.images.length" 
            :key="i" 
            class="compo_divider" 
            @click="index_slider = i-1">
          </div>
        </div>
      </div>

      <div class="branch" v-if="currentPage === 'branch'">
        <div class="search">
          <input type="text" placeholder="Введите название улицы или филиала" v-model="branchSearch">
        </div>
        <div v-if="Filials.length == 0" style="display: flex; align-items: center; justify-items: center; flex-direction: column; margin-top: 40px;">
          <Loader/>
        </div>
        
        <div class="branch_card">
          <div v-for="filial in Filials" :key="filial.id">
            <filialCard :activeFilial="activeFilial" :data="filial" v-if="filial.name.toLowerCase().includes(branchSearch.toLowerCase()) || !branchSearch"/>
          </div>
        </div>
        <div class="card_next">
          <div class="card_next_container">
            <p class="card_next_text">Выбор филиала</p>
            <div class="divider"></div>
            <p class="card_next_subtext">Всего филиалов: <span class="card_number">{{ Filials.length }}</span></p>
          </div>
          <div class="card_btn">
            <button :class="{'card_next_btn-disabled' : !activeFilial.name, 'card_next_btn-active' : activeFilial.name}" @click="showChoice">{{size === 'desktop' ? 'Продолжить' : 'Далее'}}</button>
          </div>
        </div>        
      </div>
      <div class="choice" v-else-if="currentPage === 'choice'">

        <div class="imageBanner">
          <div class="choice-banner" :style="{ backgroundImage: `url('/${activeFilial.images[index].image}')` }"></div>
          <div class="arrows" v-if="activeFilial.images.length>1">
            <button @click="index == 0 ? index=activeFilial.images.length-1 : index--">&lt;</button>
            <button @click="index == activeFilial.images.length-1 ? index=0 : index++">></button>
          </div>
        </div>
        

        <div class="choice_overview ">
          <div class="choice_rate_container">
            <p class="choice_rate">{{'5.0'}}</p>
            <div class="choice_star_container">
              <div class="stars">
                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2575 5.95727C11.7944 5.95727 11.3874 5.66134 11.2602 5.2321L11.2426 5.1728C11.2405 5.16555 11.2382 5.15833 11.2359 5.15112L9.7288 0.469756C9.7288 0.469756 9.7288 0.469756 9.7288 0.469756C9.53202 -0.141467 8.64216 -0.1614 8.41615 0.440352C8.41615 0.440352 8.41615 0.440352 8.41615 0.440352L6.65496 5.12944C6.64683 5.15109 6.63948 5.17301 6.63292 5.19515L6.62196 5.23211C6.49474 5.66134 6.08776 5.95727 5.62468 5.95727H5.582H0.693556C0.693556 5.95727 0.693556 5.95727 0.693556 5.95727C0.0294735 5.95727 -0.253536 6.77131 0.276429 7.15708C0.276429 7.15708 0.27643 7.15708 0.276429 7.15708L4.37907 10.1434C4.39527 10.1552 4.41183 10.1666 4.42873 10.1774L4.48064 10.2108C4.85591 10.452 5.02647 10.8993 4.90256 11.3174L4.87737 11.4024C4.87568 11.4081 4.87393 11.4138 4.87213 11.4195L3.37747 16.1422C3.18269 16.7577 3.92148 17.2519 4.45137 16.8606L8.27591 14.0367C8.28719 14.0284 8.29829 14.0198 8.30921 14.011L8.33601 13.9895C8.72065 13.6805 9.27936 13.6805 9.66399 13.9895L9.6908 14.011C9.70171 14.0198 9.71282 14.0284 9.7241 14.0367L13.5486 16.8606C14.0785 17.2519 14.8173 16.7577 14.6225 16.1422L13.1279 11.4195C13.1261 11.4138 13.1243 11.4081 13.1226 11.4024L13.0974 11.3174C12.9735 10.8993 13.1441 10.452 13.5194 10.2108L13.5713 10.1774C13.5882 10.1666 13.6047 10.1552 13.6209 10.1434L17.7236 7.15708C17.7236 7.15708 17.7236 7.15708 17.7236 7.15708C18.2535 6.77131 17.9705 5.95727 17.3064 5.95727H12.3001H12.2575Z" fill="#F7D37D"/>
                </svg>
                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2575 5.95727C11.7944 5.95727 11.3874 5.66134 11.2602 5.2321L11.2426 5.1728C11.2405 5.16555 11.2382 5.15833 11.2359 5.15112L9.7288 0.469756C9.7288 0.469756 9.7288 0.469756 9.7288 0.469756C9.53202 -0.141467 8.64216 -0.1614 8.41615 0.440352C8.41615 0.440352 8.41615 0.440352 8.41615 0.440352L6.65496 5.12944C6.64683 5.15109 6.63948 5.17301 6.63292 5.19515L6.62196 5.23211C6.49474 5.66134 6.08776 5.95727 5.62468 5.95727H5.582H0.693556C0.693556 5.95727 0.693556 5.95727 0.693556 5.95727C0.0294735 5.95727 -0.253536 6.77131 0.276429 7.15708C0.276429 7.15708 0.27643 7.15708 0.276429 7.15708L4.37907 10.1434C4.39527 10.1552 4.41183 10.1666 4.42873 10.1774L4.48064 10.2108C4.85591 10.452 5.02647 10.8993 4.90256 11.3174L4.87737 11.4024C4.87568 11.4081 4.87393 11.4138 4.87213 11.4195L3.37747 16.1422C3.18269 16.7577 3.92148 17.2519 4.45137 16.8606L8.27591 14.0367C8.28719 14.0284 8.29829 14.0198 8.30921 14.011L8.33601 13.9895C8.72065 13.6805 9.27936 13.6805 9.66399 13.9895L9.6908 14.011C9.70171 14.0198 9.71282 14.0284 9.7241 14.0367L13.5486 16.8606C14.0785 17.2519 14.8173 16.7577 14.6225 16.1422L13.1279 11.4195C13.1261 11.4138 13.1243 11.4081 13.1226 11.4024L13.0974 11.3174C12.9735 10.8993 13.1441 10.452 13.5194 10.2108L13.5713 10.1774C13.5882 10.1666 13.6047 10.1552 13.6209 10.1434L17.7236 7.15708C17.7236 7.15708 17.7236 7.15708 17.7236 7.15708C18.2535 6.77131 17.9705 5.95727 17.3064 5.95727H12.3001H12.2575Z" fill="#F7D37D"/>
                </svg>
                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2575 5.95727C11.7944 5.95727 11.3874 5.66134 11.2602 5.2321L11.2426 5.1728C11.2405 5.16555 11.2382 5.15833 11.2359 5.15112L9.7288 0.469756C9.7288 0.469756 9.7288 0.469756 9.7288 0.469756C9.53202 -0.141467 8.64216 -0.1614 8.41615 0.440352C8.41615 0.440352 8.41615 0.440352 8.41615 0.440352L6.65496 5.12944C6.64683 5.15109 6.63948 5.17301 6.63292 5.19515L6.62196 5.23211C6.49474 5.66134 6.08776 5.95727 5.62468 5.95727H5.582H0.693556C0.693556 5.95727 0.693556 5.95727 0.693556 5.95727C0.0294735 5.95727 -0.253536 6.77131 0.276429 7.15708C0.276429 7.15708 0.27643 7.15708 0.276429 7.15708L4.37907 10.1434C4.39527 10.1552 4.41183 10.1666 4.42873 10.1774L4.48064 10.2108C4.85591 10.452 5.02647 10.8993 4.90256 11.3174L4.87737 11.4024C4.87568 11.4081 4.87393 11.4138 4.87213 11.4195L3.37747 16.1422C3.18269 16.7577 3.92148 17.2519 4.45137 16.8606L8.27591 14.0367C8.28719 14.0284 8.29829 14.0198 8.30921 14.011L8.33601 13.9895C8.72065 13.6805 9.27936 13.6805 9.66399 13.9895L9.6908 14.011C9.70171 14.0198 9.71282 14.0284 9.7241 14.0367L13.5486 16.8606C14.0785 17.2519 14.8173 16.7577 14.6225 16.1422L13.1279 11.4195C13.1261 11.4138 13.1243 11.4081 13.1226 11.4024L13.0974 11.3174C12.9735 10.8993 13.1441 10.452 13.5194 10.2108L13.5713 10.1774C13.5882 10.1666 13.6047 10.1552 13.6209 10.1434L17.7236 7.15708C17.7236 7.15708 17.7236 7.15708 17.7236 7.15708C18.2535 6.77131 17.9705 5.95727 17.3064 5.95727H12.3001H12.2575Z" fill="#F7D37D"/>
                </svg>
                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2575 5.95727C11.7944 5.95727 11.3874 5.66134 11.2602 5.2321L11.2426 5.1728C11.2405 5.16555 11.2382 5.15833 11.2359 5.15112L9.7288 0.469756C9.7288 0.469756 9.7288 0.469756 9.7288 0.469756C9.53202 -0.141467 8.64216 -0.1614 8.41615 0.440352C8.41615 0.440352 8.41615 0.440352 8.41615 0.440352L6.65496 5.12944C6.64683 5.15109 6.63948 5.17301 6.63292 5.19515L6.62196 5.23211C6.49474 5.66134 6.08776 5.95727 5.62468 5.95727H5.582H0.693556C0.693556 5.95727 0.693556 5.95727 0.693556 5.95727C0.0294735 5.95727 -0.253536 6.77131 0.276429 7.15708C0.276429 7.15708 0.27643 7.15708 0.276429 7.15708L4.37907 10.1434C4.39527 10.1552 4.41183 10.1666 4.42873 10.1774L4.48064 10.2108C4.85591 10.452 5.02647 10.8993 4.90256 11.3174L4.87737 11.4024C4.87568 11.4081 4.87393 11.4138 4.87213 11.4195L3.37747 16.1422C3.18269 16.7577 3.92148 17.2519 4.45137 16.8606L8.27591 14.0367C8.28719 14.0284 8.29829 14.0198 8.30921 14.011L8.33601 13.9895C8.72065 13.6805 9.27936 13.6805 9.66399 13.9895L9.6908 14.011C9.70171 14.0198 9.71282 14.0284 9.7241 14.0367L13.5486 16.8606C14.0785 17.2519 14.8173 16.7577 14.6225 16.1422L13.1279 11.4195C13.1261 11.4138 13.1243 11.4081 13.1226 11.4024L13.0974 11.3174C12.9735 10.8993 13.1441 10.452 13.5194 10.2108L13.5713 10.1774C13.5882 10.1666 13.6047 10.1552 13.6209 10.1434L17.7236 7.15708C17.7236 7.15708 17.7236 7.15708 17.7236 7.15708C18.2535 6.77131 17.9705 5.95727 17.3064 5.95727H12.3001H12.2575Z" fill="#F7D37D"/>
                </svg>
                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2575 5.95727C11.7944 5.95727 11.3874 5.66134 11.2602 5.2321L11.2426 5.1728C11.2405 5.16555 11.2382 5.15833 11.2359 5.15112L9.7288 0.469756C9.7288 0.469756 9.7288 0.469756 9.7288 0.469756C9.53202 -0.141467 8.64216 -0.1614 8.41615 0.440352C8.41615 0.440352 8.41615 0.440352 8.41615 0.440352L6.65496 5.12944C6.64683 5.15109 6.63948 5.17301 6.63292 5.19515L6.62196 5.23211C6.49474 5.66134 6.08776 5.95727 5.62468 5.95727H5.582H0.693556C0.693556 5.95727 0.693556 5.95727 0.693556 5.95727C0.0294735 5.95727 -0.253536 6.77131 0.276429 7.15708C0.276429 7.15708 0.27643 7.15708 0.276429 7.15708L4.37907 10.1434C4.39527 10.1552 4.41183 10.1666 4.42873 10.1774L4.48064 10.2108C4.85591 10.452 5.02647 10.8993 4.90256 11.3174L4.87737 11.4024C4.87568 11.4081 4.87393 11.4138 4.87213 11.4195L3.37747 16.1422C3.18269 16.7577 3.92148 17.2519 4.45137 16.8606L8.27591 14.0367C8.28719 14.0284 8.29829 14.0198 8.30921 14.011L8.33601 13.9895C8.72065 13.6805 9.27936 13.6805 9.66399 13.9895L9.6908 14.011C9.70171 14.0198 9.71282 14.0284 9.7241 14.0367L13.5486 16.8606C14.0785 17.2519 14.8173 16.7577 14.6225 16.1422L13.1279 11.4195C13.1261 11.4138 13.1243 11.4081 13.1226 11.4024L13.0974 11.3174C12.9735 10.8993 13.1441 10.452 13.5194 10.2108L13.5713 10.1774C13.5882 10.1666 13.6047 10.1552 13.6209 10.1434L17.7236 7.15708C17.7236 7.15708 17.7236 7.15708 17.7236 7.15708C18.2535 6.77131 17.9705 5.95727 17.3064 5.95727H12.3001H12.2575Z" fill="#F7D37D"/>
                </svg>           
              </div>
              <p class="choice_star_text">24 оценки</p>
            </div>
          </div>
          <div class="choice_info">
            <div class="choice_info_container">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.25 5C1.25 2.44066 2.7056 1.51888 3.75412 1.3033C4.06243 1.23991 4.36115 1.39819 4.55 1.65L5.97962 3.55616C6.27821 3.95428 6.23862 4.51138 5.88673 4.86327L4.75965 5.99035C4.45529 6.29471 4.38168 6.75866 4.60979 7.12367C4.96976 7.69966 5.59983 8.59983 6.5 9.5C7.40017 10.4002 8.30034 11.0302 8.87633 11.3902C9.24134 11.6183 9.70529 11.5447 10.0097 11.2403L11.1367 10.1133C11.4886 9.76138 12.0457 9.72179 12.4438 10.0204L14.35 11.45C14.6018 11.6389 14.7601 11.9376 14.6967 12.2459C14.4811 13.2944 13.5593 14.75 11 14.75C8 14.75 5.75 12.5 4.625 11.375C3.5 10.25 1.25 8 1.25 5Z" fill="var(--color-text)"/>
              </svg>
              <p class="choice_info_text">{{activeFilial.phone}}</p>
            </div>
            <div class="choice_info_container">
              <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 7.5C15.5 11.6421 12.1421 15 8 15C3.85786 15 0.5 11.6421 0.5 7.5C0.5 3.35786 3.85786 0 8 0C12.1421 0 15.5 3.35786 15.5 7.5ZM8.375 3.375H6.875V7.43566L10.8447 11.4053L11.9053 10.3447L8.375 6.81434V3.375Z" fill="var(--color-text)"/>
              </svg>
              <p class="choice_info_text">{{activeFilial.active_days}}: {{activeFilial.work_hours}}</p>
            </div>
          </div>
        </div>
        <div class="service">
          <div class="service_container" @click="activeUslugi = !activeUslugi, activePersonal ? activePersonal=false : activePersonal = false; selectedUslugi.pop">
            <p class="service_container_text" :style="activeUslugi ? { color: 'var(--color-global)' } : {}">Выбрать<br>услугу</p>
            <img src="../../static/img/tools.svg" alt="" class="service_tools">
            <div class="checkmark" v-if="!activeUslugi">
              <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2556 6.15492L9.05226 14.4665L4.29285 9.7071L5.70706 8.29289L8.94764 11.5335L14.7443 4.84506L16.2556 6.15492Z" fill="#F5F5F5"/>
              </svg>
            </div>
            <div class="active-checkmark" v-else >
              <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2556 6.15492L9.05226 14.4665L4.29285 9.7071L5.70706 8.29289L8.94764 11.5335L14.7443 4.84506L16.2556 6.15492Z" fill="#F5F5F5"/>
              </svg>
            </div>
          </div>
          <div class="service_container" @click="activePersonal = !activePersonal, activeUslugi ? activeUslugi=false : activeUslugi = false;  ">
            <p class="service_container_text"  :style="activePersonal ? { color: 'var(--color-global)' } : {}">Выбрать<br>специалиста</p>
            <img src="../../static/img/ppl.svg" alt="" class="service_ppl">
            <div class="checkmark" v-if="!activePersonal">
              <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2556 6.15492L9.05226 14.4665L4.29285 9.7071L5.70706 8.29289L8.94764 11.5335L14.7443 4.84506L16.2556 6.15492Z" fill="#F5F5F5"/>
              </svg>
            </div>
            <div class="active-checkmark" v-else >
              <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2556 6.15492L9.05226 14.4665L4.29285 9.7071L5.70706 8.29289L8.94764 11.5335L14.7443 4.84506L16.2556 6.15492Z" fill="#F5F5F5"/>
              </svg>
            </div>
          </div>
        </div>
        <div class="card_next">
          <div class="card_next_container">
            <p class="card_next_text">Новая запись</p>
            <div class="divider"></div>
            <p class="card_next_subtext">Приступим</p>
          </div>
          <div class="card_btn">
            <button v-if="size === 'desktop'" class="card_back_btn" @click="showBranch()">Назад</button>
            <button v-else class="card_back_btn-arrow" @click="showBranch()">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.3999 6.96667L4.8999 3.5V6.3H13.2999V7.7H4.8999V10.5L1.3999 6.96667Z" fill="var(--color-text)"/>
                </svg>
            </button>
            <button :disabled="!activeUslugi && !activePersonal" @click="activeUslugi ? showFavor() : showEmployees(); step = 1" :class="{'card_next_btn-disabled' : !activeUslugi || !activePersonal, 'card_next_btn-active' : activeUslugi || activePersonal}">{{size === 'desktop' ? 'Продолжить' : 'Далее'}}</button>
          </div>
        </div>
      </div>
      <div class="favor" v-else-if="currentPage === 'favor'">
        <div class="search">
          <input type="text" placeholder="Введите название улицы или филиала">
        </div>
        <div class="favor_card_container">
          <div class="favor_card" v-for="u in uslugi" :key="u.id">
            <div class="favor_compo-wrapper">
              <img :src="u.serviceCover" alt="" class="card-avatar">
              <p class="favor_text">{{u.name}}</p>
            </div>
            <div class="compo-wrapper-tariff">
              <div class="tariff">
                <div class="tariff-item">
                  <p class="tariff_text">{{u.cost}}</p>
                </div>
                <div class="dot"></div>
                <div class="tariff-item">
                  <p class="tariff_text">{{u.time}}</p>
                </div>
              </div>
              <button v-if="!selectedUslugi.includes(u)" @click="UslugaSelected(u)" class="btn-wrapper">Выбрать</button>
              <div v-else class="delete">
                <button @click="UslugaDeleting(u)" class="delete-btn">
                  <svg width="16" height="16" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.94318 1.55556C1.94318 1.1797 2.2637 0.875 2.65909 0.875H9.34092C9.73631 0.875 10.0568 1.1797 10.0568 1.55556C10.0568 1.93142 9.73631 2.23611 9.34092 2.23611H2.65909C2.2637 2.23611 1.94318 1.93142 1.94318 1.55556ZM1.46591 4.95833H0.75V3.59722H11.25V4.95833H10.5341V10.6296C10.5341 12.0078 9.35886 13.125 7.9091 13.125H4.09091C2.64116 13.125 1.46591 12.0078 1.46591 10.6296V4.95833ZM9.10227 4.95833H2.89773V10.6296C2.89773 11.2561 3.43194 11.7639 4.09091 11.7639H7.9091C8.56806 11.7639 9.10227 11.2561 9.10227 10.6296V4.95833Z" fill="#F97F7F"/>
                    </svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="favor_bottom">
          <div class="card_next">
            <div class="card_next_container">
              <p class="card_next_text">Выбор услуги</p>
              <div class="divider_step_container" v-if="step === 1">
                <div class="divider_step_one"></div>
                <div class="divider_step_two"></div>
              </div>
              <div class="divider_step_container" v-else>
                <div class="employees_divider_step_one"></div>
                <div class="employees_divider_step_two"></div>
              </div>
              <p class="card_next_subtext">Шаг {{ step }} из 4</p>
            </div>
            <div class="card_btn">
              <button v-if="size === 'desktop'" class="card_back_btn" @click="activeUslugi ? showChoice() : showEmployees()">Назад</button>
              <button v-else class="card_back_btn-arrow" @click="activeUslugi ? showChoice() : showEmployees()">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.3999 6.96667L4.8999 3.5V6.3H13.2999V7.7H4.8999V10.5L1.3999 6.96667Z" fill="var(--color-text)"/>
                  </svg>
              </button>
              <button :disabled="!selectedUslugi.length > 0" @click="!activeUslugi ? generate14DayDictionary(this.selectedEmployees[0]) : pass; activeUslugi ? showEmployees() : showCalendar(); activeUslugi ? step = 2 : step = 3;" :class="{'card_next_btn-disabled' : !selectedUslugi.length > 0, 'card_next_btn-active' : selectedUslugi.length > 0}">{{size === 'desktop' ? 'Продолжить' : 'Далее'}}</button>
            </div>
          </div> 
        </div>

      </div>
      <div class="employees" v-else-if="currentPage === 'employees'">
        <div class="search">
          <input type="text" placeholder="Введите название улицы или филиала">
        </div>

        <div class="employees_card_container">
          <div class="employees_card">
            <div class="employees_compo-wrapper">
              <div class="user-alt">
                <img src="../../static/img/user-alt.svg" alt="">
              </div>
              <div class="employees_container">
                <p class="employees_text">Любой сотрудник</p>
                <p class="employees_subtext">Система подберет случайного специалиста</p>
              </div>

            </div>
            <div class="alt_btn">
              <button v-if="!selectedEmployees.includes('random') && !choiceLoading" @click="choiceLoading = true, EmployeeSelected('random')" class="btn-wrapper">Подобрать</button>
              <button v-if="choiceLoading" @click="EmployeeSelected('random'), choiceLoading = true" class="btn-wrapper">Подбор...</button>
            </div>
          </div>

          <div class="employees_card" v-for="e in employees" :key="e.id">
            <div class="employees_compo-wrapper">
              <img :src=" e.avatar" alt="" class="card-avatar">
              <div class="employees_container">
                <p class="employees_text">{{ e.firstname + ' ' + e.secondname }}</p>
                <p class="employees_subtext">{{ e.rank }}</p>
              </div>

            </div>
            <div class="compo-wrapper-tariff">
              <div class="employees_rate">
                <div class="star_container">
                  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2575 5.95727C11.7944 5.95727 11.3874 5.66134 11.2602 5.2321L11.2426 5.1728C11.2405 5.16555 11.2382 5.15833 11.2359 5.15112L9.7288 0.469756C9.7288 0.469756 9.7288 0.469756 9.7288 0.469756C9.53202 -0.141467 8.64216 -0.1614 8.41615 0.440352C8.41615 0.440352 8.41615 0.440352 8.41615 0.440352L6.65496 5.12944C6.64683 5.15109 6.63948 5.17301 6.63292 5.19515L6.62196 5.23211C6.49474 5.66134 6.08776 5.95727 5.62468 5.95727H5.582H0.693556C0.693556 5.95727 0.693556 5.95727 0.693556 5.95727C0.0294735 5.95727 -0.253536 6.77131 0.276429 7.15708C0.276429 7.15708 0.27643 7.15708 0.276429 7.15708L4.37907 10.1434C4.39527 10.1552 4.41183 10.1666 4.42873 10.1774L4.48064 10.2108C4.85591 10.452 5.02647 10.8993 4.90256 11.3174L4.87737 11.4024C4.87568 11.4081 4.87393 11.4138 4.87213 11.4195L3.37747 16.1422C3.18269 16.7577 3.92148 17.2519 4.45137 16.8606L8.27591 14.0367C8.28719 14.0284 8.29829 14.0198 8.30921 14.011L8.33601 13.9895C8.72065 13.6805 9.27936 13.6805 9.66399 13.9895L9.6908 14.011C9.70171 14.0198 9.71282 14.0284 9.7241 14.0367L13.5486 16.8606C14.0785 17.2519 14.8173 16.7577 14.6225 16.1422L13.1279 11.4195C13.1261 11.4138 13.1243 11.4081 13.1226 11.4024L13.0974 11.3174C12.9735 10.8993 13.1441 10.452 13.5194 10.2108L13.5713 10.1774C13.5882 10.1666 13.6047 10.1552 13.6209 10.1434L17.7236 7.15708C17.7236 7.15708 17.7236 7.15708 17.7236 7.15708C18.2535 6.77131 17.9705 5.95727 17.3064 5.95727H12.3001H12.2575Z" fill="#F7D37D"/>
                  </svg>
                  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2575 5.95727C11.7944 5.95727 11.3874 5.66134 11.2602 5.2321L11.2426 5.1728C11.2405 5.16555 11.2382 5.15833 11.2359 5.15112L9.7288 0.469756C9.7288 0.469756 9.7288 0.469756 9.7288 0.469756C9.53202 -0.141467 8.64216 -0.1614 8.41615 0.440352C8.41615 0.440352 8.41615 0.440352 8.41615 0.440352L6.65496 5.12944C6.64683 5.15109 6.63948 5.17301 6.63292 5.19515L6.62196 5.23211C6.49474 5.66134 6.08776 5.95727 5.62468 5.95727H5.582H0.693556C0.693556 5.95727 0.693556 5.95727 0.693556 5.95727C0.0294735 5.95727 -0.253536 6.77131 0.276429 7.15708C0.276429 7.15708 0.27643 7.15708 0.276429 7.15708L4.37907 10.1434C4.39527 10.1552 4.41183 10.1666 4.42873 10.1774L4.48064 10.2108C4.85591 10.452 5.02647 10.8993 4.90256 11.3174L4.87737 11.4024C4.87568 11.4081 4.87393 11.4138 4.87213 11.4195L3.37747 16.1422C3.18269 16.7577 3.92148 17.2519 4.45137 16.8606L8.27591 14.0367C8.28719 14.0284 8.29829 14.0198 8.30921 14.011L8.33601 13.9895C8.72065 13.6805 9.27936 13.6805 9.66399 13.9895L9.6908 14.011C9.70171 14.0198 9.71282 14.0284 9.7241 14.0367L13.5486 16.8606C14.0785 17.2519 14.8173 16.7577 14.6225 16.1422L13.1279 11.4195C13.1261 11.4138 13.1243 11.4081 13.1226 11.4024L13.0974 11.3174C12.9735 10.8993 13.1441 10.452 13.5194 10.2108L13.5713 10.1774C13.5882 10.1666 13.6047 10.1552 13.6209 10.1434L17.7236 7.15708C17.7236 7.15708 17.7236 7.15708 17.7236 7.15708C18.2535 6.77131 17.9705 5.95727 17.3064 5.95727H12.3001H12.2575Z" fill="#F7D37D"/>
                  </svg>
                  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2575 5.95727C11.7944 5.95727 11.3874 5.66134 11.2602 5.2321L11.2426 5.1728C11.2405 5.16555 11.2382 5.15833 11.2359 5.15112L9.7288 0.469756C9.7288 0.469756 9.7288 0.469756 9.7288 0.469756C9.53202 -0.141467 8.64216 -0.1614 8.41615 0.440352C8.41615 0.440352 8.41615 0.440352 8.41615 0.440352L6.65496 5.12944C6.64683 5.15109 6.63948 5.17301 6.63292 5.19515L6.62196 5.23211C6.49474 5.66134 6.08776 5.95727 5.62468 5.95727H5.582H0.693556C0.693556 5.95727 0.693556 5.95727 0.693556 5.95727C0.0294735 5.95727 -0.253536 6.77131 0.276429 7.15708C0.276429 7.15708 0.27643 7.15708 0.276429 7.15708L4.37907 10.1434C4.39527 10.1552 4.41183 10.1666 4.42873 10.1774L4.48064 10.2108C4.85591 10.452 5.02647 10.8993 4.90256 11.3174L4.87737 11.4024C4.87568 11.4081 4.87393 11.4138 4.87213 11.4195L3.37747 16.1422C3.18269 16.7577 3.92148 17.2519 4.45137 16.8606L8.27591 14.0367C8.28719 14.0284 8.29829 14.0198 8.30921 14.011L8.33601 13.9895C8.72065 13.6805 9.27936 13.6805 9.66399 13.9895L9.6908 14.011C9.70171 14.0198 9.71282 14.0284 9.7241 14.0367L13.5486 16.8606C14.0785 17.2519 14.8173 16.7577 14.6225 16.1422L13.1279 11.4195C13.1261 11.4138 13.1243 11.4081 13.1226 11.4024L13.0974 11.3174C12.9735 10.8993 13.1441 10.452 13.5194 10.2108L13.5713 10.1774C13.5882 10.1666 13.6047 10.1552 13.6209 10.1434L17.7236 7.15708C17.7236 7.15708 17.7236 7.15708 17.7236 7.15708C18.2535 6.77131 17.9705 5.95727 17.3064 5.95727H12.3001H12.2575Z" fill="#F7D37D"/>
                  </svg>
                  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2575 5.95727C11.7944 5.95727 11.3874 5.66134 11.2602 5.2321L11.2426 5.1728C11.2405 5.16555 11.2382 5.15833 11.2359 5.15112L9.7288 0.469756C9.7288 0.469756 9.7288 0.469756 9.7288 0.469756C9.53202 -0.141467 8.64216 -0.1614 8.41615 0.440352C8.41615 0.440352 8.41615 0.440352 8.41615 0.440352L6.65496 5.12944C6.64683 5.15109 6.63948 5.17301 6.63292 5.19515L6.62196 5.23211C6.49474 5.66134 6.08776 5.95727 5.62468 5.95727H5.582H0.693556C0.693556 5.95727 0.693556 5.95727 0.693556 5.95727C0.0294735 5.95727 -0.253536 6.77131 0.276429 7.15708C0.276429 7.15708 0.27643 7.15708 0.276429 7.15708L4.37907 10.1434C4.39527 10.1552 4.41183 10.1666 4.42873 10.1774L4.48064 10.2108C4.85591 10.452 5.02647 10.8993 4.90256 11.3174L4.87737 11.4024C4.87568 11.4081 4.87393 11.4138 4.87213 11.4195L3.37747 16.1422C3.18269 16.7577 3.92148 17.2519 4.45137 16.8606L8.27591 14.0367C8.28719 14.0284 8.29829 14.0198 8.30921 14.011L8.33601 13.9895C8.72065 13.6805 9.27936 13.6805 9.66399 13.9895L9.6908 14.011C9.70171 14.0198 9.71282 14.0284 9.7241 14.0367L13.5486 16.8606C14.0785 17.2519 14.8173 16.7577 14.6225 16.1422L13.1279 11.4195C13.1261 11.4138 13.1243 11.4081 13.1226 11.4024L13.0974 11.3174C12.9735 10.8993 13.1441 10.452 13.5194 10.2108L13.5713 10.1774C13.5882 10.1666 13.6047 10.1552 13.6209 10.1434L17.7236 7.15708C17.7236 7.15708 17.7236 7.15708 17.7236 7.15708C18.2535 6.77131 17.9705 5.95727 17.3064 5.95727H12.3001H12.2575Z" fill="#F7D37D"/>
                  </svg>
                  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2575 5.95727C11.7944 5.95727 11.3874 5.66134 11.2602 5.2321L11.2426 5.1728C11.2405 5.16555 11.2382 5.15833 11.2359 5.15112L9.7288 0.469756C9.7288 0.469756 9.7288 0.469756 9.7288 0.469756C9.53202 -0.141467 8.64216 -0.1614 8.41615 0.440352C8.41615 0.440352 8.41615 0.440352 8.41615 0.440352L6.65496 5.12944C6.64683 5.15109 6.63948 5.17301 6.63292 5.19515L6.62196 5.23211C6.49474 5.66134 6.08776 5.95727 5.62468 5.95727H5.582H0.693556C0.693556 5.95727 0.693556 5.95727 0.693556 5.95727C0.0294735 5.95727 -0.253536 6.77131 0.276429 7.15708C0.276429 7.15708 0.27643 7.15708 0.276429 7.15708L4.37907 10.1434C4.39527 10.1552 4.41183 10.1666 4.42873 10.1774L4.48064 10.2108C4.85591 10.452 5.02647 10.8993 4.90256 11.3174L4.87737 11.4024C4.87568 11.4081 4.87393 11.4138 4.87213 11.4195L3.37747 16.1422C3.18269 16.7577 3.92148 17.2519 4.45137 16.8606L8.27591 14.0367C8.28719 14.0284 8.29829 14.0198 8.30921 14.011L8.33601 13.9895C8.72065 13.6805 9.27936 13.6805 9.66399 13.9895L9.6908 14.011C9.70171 14.0198 9.71282 14.0284 9.7241 14.0367L13.5486 16.8606C14.0785 17.2519 14.8173 16.7577 14.6225 16.1422L13.1279 11.4195C13.1261 11.4138 13.1243 11.4081 13.1226 11.4024L13.0974 11.3174C12.9735 10.8993 13.1441 10.452 13.5194 10.2108L13.5713 10.1774C13.5882 10.1666 13.6047 10.1552 13.6209 10.1434L17.7236 7.15708C17.7236 7.15708 17.7236 7.15708 17.7236 7.15708C18.2535 6.77131 17.9705 5.95727 17.3064 5.95727H12.3001H12.2575Z" fill="#F7D37D"/>
                  </svg>

                </div>
                <p class="employees_rate_text">{{ e.reviews || '0' }} отзывов</p>
              </div>
              <button v-if="!selectedEmployees.includes(e)" @click="EmployeeSelected(e)" class="btn-wrapper">Выбрать</button>
              <div v-else class="delete">
                <button @click="EmployeeDeleting(e)" class="delete-btn">
                  <svg width="16" height="16" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.94318 1.55556C1.94318 1.1797 2.2637 0.875 2.65909 0.875H9.34092C9.73631 0.875 10.0568 1.1797 10.0568 1.55556C10.0568 1.93142 9.73631 2.23611 9.34092 2.23611H2.65909C2.2637 2.23611 1.94318 1.93142 1.94318 1.55556ZM1.46591 4.95833H0.75V3.59722H11.25V4.95833H10.5341V10.6296C10.5341 12.0078 9.35886 13.125 7.9091 13.125H4.09091C2.64116 13.125 1.46591 12.0078 1.46591 10.6296V4.95833ZM9.10227 4.95833H2.89773V10.6296C2.89773 11.2561 3.43194 11.7639 4.09091 11.7639H7.9091C8.56806 11.7639 9.10227 11.2561 9.10227 10.6296V4.95833Z" fill="#F97F7F"/>
                    </svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="employees_bottom">
          <div class="card_next">
            <div class="card_next_container">
              <p class="card_next_text">Выбор специалиста</p>
              <div class="divider_step_container" v-if="step === 1">
                <div class="divider_step_one"></div>
                <div class="divider_step_two"></div>
              </div>
              <div class="divider_step_container" v-else>
                <div class="employees_divider_step_one"></div>
                <div class="employees_divider_step_two"></div>
              </div>
              <p class="card_next_subtext">Шаг {{ step }} из 4</p>
            </div>
            <div class="card_btn">
              <button v-if="size === 'desktop'" class="card_back_btn" @click="activeUslugi ? showFavor() : showChoice(); activeUslugi ? step = 1 : step = 0;">Назад</button>
              <button v-else class="card_back_btn-arrow" @click="activeUslugi ? showFavor() : showChoice(); activeUslugi ? step = 1 : step = 0;">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.3999 6.96667L4.8999 3.5V6.3H13.2999V7.7H4.8999V10.5L1.3999 6.96667Z" fill="var(--color-text)"/>
                  </svg>
              </button>
              <button :disabled="!selectedEmployees.length > 0" @click="activeUslugi ? generate14DayDictionary(this.selectedEmployees[0]) : pass; activeUslugi ? showCalendar() : showFavor(); activeUslugi ? step = 3 : step = 2; " :class="{'card_next_btn-disabled' : !selectedEmployees.length > 0, 'card_next_btn-active' : selectedEmployees.length > 0}">{{size === 'desktop' ? 'Продолжить' : 'Далее'}}</button>
            </div>
          </div> 
        </div>
      </div>
      
      <div class="calendar" v-else-if="currentPage === 'calendar'">
        <div class="calendar_numbers_container">
          <div class="calendar_numbers">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 5V0H7V5H5Z" fill="var(--color-global)"/>
            <path d="M13 5V0H15V5H13Z" fill="var(--color-global)"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2 19C1.44772 19 1 18.5523 1 18V3C1 2.44772 1.44772 2 2 2H4V6H8V2H12V6H16V2H18C18.5523 2 19 2.44772 19 3V18C19 18.5523 18.5523 19 18 19H2ZM17 17V8H3V17H17Z" fill="var(--color-global)"/>
            </svg>
          </div>
            <div 
            v-for="(d, index) in calendarSchedule.slice(0, 8)"
            :key="index" 
            :style="{ background: selectedDay === d ? 'var(--color-global)' : 'var(--color-gray)'}"
            class="calendar_numbers" 
            @click="selectedDay = d, dayChanged(d.day, d.month)">
            <p :style="{color: selectedDay === d ? '#FFFFFF' : 'var(--color-text)'}" class="calendar_numbers_head">
              {{ d.day + ' ' }}
              <span :style="d.day_of_week === 'Сб' || d.day_of_week === 'Вс' ? 'color: #F97F7F' : ''">{{ d.day_of_week }}</span>
            </p>
            <p :style="{color: selectedDay === d ? '#FFFFFF' : 'var(--color-text)'}" class="calendar_numbers_sub">
              {{ d.month }}
            </p>
          </div>
        </div>

        <div class="calendar_card">
          <div class="calendar_wrapper">
            <div class="calendar_compo-wrapper">
              <img :src="selectedEmployees[0].avatar" class="card-avatar" alt="">
              <div class="calendar_container">
                <p class="calendar_text">{{ selectedUslugi[0].name }} у {{ selectedEmployees[0].firstname + ' ' + selectedEmployees[0].secondname }}</p>
                <div class="tariff">
                  <div class="tariff-item">
                    <p class="tariff_text">{{ selectedUslugi[0].cost }}</p>
                  </div>
                  <div class="dot"></div>
                  <div class="tariff-item">
                    <p class="tariff_text">{{ selectedUslugi[0].time }}</p>
                  </div>
                </div>
              </div>            
            </div>
            <div class="calendar_rate">
              <div class="star_container">
                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2575 5.95727C11.7944 5.95727 11.3874 5.66134 11.2602 5.2321L11.2426 5.1728C11.2405 5.16555 11.2382 5.15833 11.2359 5.15112L9.7288 0.469756C9.7288 0.469756 9.7288 0.469756 9.7288 0.469756C9.53202 -0.141467 8.64216 -0.1614 8.41615 0.440352C8.41615 0.440352 8.41615 0.440352 8.41615 0.440352L6.65496 5.12944C6.64683 5.15109 6.63948 5.17301 6.63292 5.19515L6.62196 5.23211C6.49474 5.66134 6.08776 5.95727 5.62468 5.95727H5.582H0.693556C0.693556 5.95727 0.693556 5.95727 0.693556 5.95727C0.0294735 5.95727 -0.253536 6.77131 0.276429 7.15708C0.276429 7.15708 0.27643 7.15708 0.276429 7.15708L4.37907 10.1434C4.39527 10.1552 4.41183 10.1666 4.42873 10.1774L4.48064 10.2108C4.85591 10.452 5.02647 10.8993 4.90256 11.3174L4.87737 11.4024C4.87568 11.4081 4.87393 11.4138 4.87213 11.4195L3.37747 16.1422C3.18269 16.7577 3.92148 17.2519 4.45137 16.8606L8.27591 14.0367C8.28719 14.0284 8.29829 14.0198 8.30921 14.011L8.33601 13.9895C8.72065 13.6805 9.27936 13.6805 9.66399 13.9895L9.6908 14.011C9.70171 14.0198 9.71282 14.0284 9.7241 14.0367L13.5486 16.8606C14.0785 17.2519 14.8173 16.7577 14.6225 16.1422L13.1279 11.4195C13.1261 11.4138 13.1243 11.4081 13.1226 11.4024L13.0974 11.3174C12.9735 10.8993 13.1441 10.452 13.5194 10.2108L13.5713 10.1774C13.5882 10.1666 13.6047 10.1552 13.6209 10.1434L17.7236 7.15708C17.7236 7.15708 17.7236 7.15708 17.7236 7.15708C18.2535 6.77131 17.9705 5.95727 17.3064 5.95727H12.3001H12.2575Z" fill="#F7D37D"/>
                </svg>
                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2575 5.95727C11.7944 5.95727 11.3874 5.66134 11.2602 5.2321L11.2426 5.1728C11.2405 5.16555 11.2382 5.15833 11.2359 5.15112L9.7288 0.469756C9.7288 0.469756 9.7288 0.469756 9.7288 0.469756C9.53202 -0.141467 8.64216 -0.1614 8.41615 0.440352C8.41615 0.440352 8.41615 0.440352 8.41615 0.440352L6.65496 5.12944C6.64683 5.15109 6.63948 5.17301 6.63292 5.19515L6.62196 5.23211C6.49474 5.66134 6.08776 5.95727 5.62468 5.95727H5.582H0.693556C0.693556 5.95727 0.693556 5.95727 0.693556 5.95727C0.0294735 5.95727 -0.253536 6.77131 0.276429 7.15708C0.276429 7.15708 0.27643 7.15708 0.276429 7.15708L4.37907 10.1434C4.39527 10.1552 4.41183 10.1666 4.42873 10.1774L4.48064 10.2108C4.85591 10.452 5.02647 10.8993 4.90256 11.3174L4.87737 11.4024C4.87568 11.4081 4.87393 11.4138 4.87213 11.4195L3.37747 16.1422C3.18269 16.7577 3.92148 17.2519 4.45137 16.8606L8.27591 14.0367C8.28719 14.0284 8.29829 14.0198 8.30921 14.011L8.33601 13.9895C8.72065 13.6805 9.27936 13.6805 9.66399 13.9895L9.6908 14.011C9.70171 14.0198 9.71282 14.0284 9.7241 14.0367L13.5486 16.8606C14.0785 17.2519 14.8173 16.7577 14.6225 16.1422L13.1279 11.4195C13.1261 11.4138 13.1243 11.4081 13.1226 11.4024L13.0974 11.3174C12.9735 10.8993 13.1441 10.452 13.5194 10.2108L13.5713 10.1774C13.5882 10.1666 13.6047 10.1552 13.6209 10.1434L17.7236 7.15708C17.7236 7.15708 17.7236 7.15708 17.7236 7.15708C18.2535 6.77131 17.9705 5.95727 17.3064 5.95727H12.3001H12.2575Z" fill="#F7D37D"/>
                </svg>
                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2575 5.95727C11.7944 5.95727 11.3874 5.66134 11.2602 5.2321L11.2426 5.1728C11.2405 5.16555 11.2382 5.15833 11.2359 5.15112L9.7288 0.469756C9.7288 0.469756 9.7288 0.469756 9.7288 0.469756C9.53202 -0.141467 8.64216 -0.1614 8.41615 0.440352C8.41615 0.440352 8.41615 0.440352 8.41615 0.440352L6.65496 5.12944C6.64683 5.15109 6.63948 5.17301 6.63292 5.19515L6.62196 5.23211C6.49474 5.66134 6.08776 5.95727 5.62468 5.95727H5.582H0.693556C0.693556 5.95727 0.693556 5.95727 0.693556 5.95727C0.0294735 5.95727 -0.253536 6.77131 0.276429 7.15708C0.276429 7.15708 0.27643 7.15708 0.276429 7.15708L4.37907 10.1434C4.39527 10.1552 4.41183 10.1666 4.42873 10.1774L4.48064 10.2108C4.85591 10.452 5.02647 10.8993 4.90256 11.3174L4.87737 11.4024C4.87568 11.4081 4.87393 11.4138 4.87213 11.4195L3.37747 16.1422C3.18269 16.7577 3.92148 17.2519 4.45137 16.8606L8.27591 14.0367C8.28719 14.0284 8.29829 14.0198 8.30921 14.011L8.33601 13.9895C8.72065 13.6805 9.27936 13.6805 9.66399 13.9895L9.6908 14.011C9.70171 14.0198 9.71282 14.0284 9.7241 14.0367L13.5486 16.8606C14.0785 17.2519 14.8173 16.7577 14.6225 16.1422L13.1279 11.4195C13.1261 11.4138 13.1243 11.4081 13.1226 11.4024L13.0974 11.3174C12.9735 10.8993 13.1441 10.452 13.5194 10.2108L13.5713 10.1774C13.5882 10.1666 13.6047 10.1552 13.6209 10.1434L17.7236 7.15708C17.7236 7.15708 17.7236 7.15708 17.7236 7.15708C18.2535 6.77131 17.9705 5.95727 17.3064 5.95727H12.3001H12.2575Z" fill="#F7D37D"/>
                </svg>
                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2575 5.95727C11.7944 5.95727 11.3874 5.66134 11.2602 5.2321L11.2426 5.1728C11.2405 5.16555 11.2382 5.15833 11.2359 5.15112L9.7288 0.469756C9.7288 0.469756 9.7288 0.469756 9.7288 0.469756C9.53202 -0.141467 8.64216 -0.1614 8.41615 0.440352C8.41615 0.440352 8.41615 0.440352 8.41615 0.440352L6.65496 5.12944C6.64683 5.15109 6.63948 5.17301 6.63292 5.19515L6.62196 5.23211C6.49474 5.66134 6.08776 5.95727 5.62468 5.95727H5.582H0.693556C0.693556 5.95727 0.693556 5.95727 0.693556 5.95727C0.0294735 5.95727 -0.253536 6.77131 0.276429 7.15708C0.276429 7.15708 0.27643 7.15708 0.276429 7.15708L4.37907 10.1434C4.39527 10.1552 4.41183 10.1666 4.42873 10.1774L4.48064 10.2108C4.85591 10.452 5.02647 10.8993 4.90256 11.3174L4.87737 11.4024C4.87568 11.4081 4.87393 11.4138 4.87213 11.4195L3.37747 16.1422C3.18269 16.7577 3.92148 17.2519 4.45137 16.8606L8.27591 14.0367C8.28719 14.0284 8.29829 14.0198 8.30921 14.011L8.33601 13.9895C8.72065 13.6805 9.27936 13.6805 9.66399 13.9895L9.6908 14.011C9.70171 14.0198 9.71282 14.0284 9.7241 14.0367L13.5486 16.8606C14.0785 17.2519 14.8173 16.7577 14.6225 16.1422L13.1279 11.4195C13.1261 11.4138 13.1243 11.4081 13.1226 11.4024L13.0974 11.3174C12.9735 10.8993 13.1441 10.452 13.5194 10.2108L13.5713 10.1774C13.5882 10.1666 13.6047 10.1552 13.6209 10.1434L17.7236 7.15708C17.7236 7.15708 17.7236 7.15708 17.7236 7.15708C18.2535 6.77131 17.9705 5.95727 17.3064 5.95727H12.3001H12.2575Z" fill="#F7D37D"/>
                </svg>
                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2575 5.95727C11.7944 5.95727 11.3874 5.66134 11.2602 5.2321L11.2426 5.1728C11.2405 5.16555 11.2382 5.15833 11.2359 5.15112L9.7288 0.469756C9.7288 0.469756 9.7288 0.469756 9.7288 0.469756C9.53202 -0.141467 8.64216 -0.1614 8.41615 0.440352C8.41615 0.440352 8.41615 0.440352 8.41615 0.440352L6.65496 5.12944C6.64683 5.15109 6.63948 5.17301 6.63292 5.19515L6.62196 5.23211C6.49474 5.66134 6.08776 5.95727 5.62468 5.95727H5.582H0.693556C0.693556 5.95727 0.693556 5.95727 0.693556 5.95727C0.0294735 5.95727 -0.253536 6.77131 0.276429 7.15708C0.276429 7.15708 0.27643 7.15708 0.276429 7.15708L4.37907 10.1434C4.39527 10.1552 4.41183 10.1666 4.42873 10.1774L4.48064 10.2108C4.85591 10.452 5.02647 10.8993 4.90256 11.3174L4.87737 11.4024C4.87568 11.4081 4.87393 11.4138 4.87213 11.4195L3.37747 16.1422C3.18269 16.7577 3.92148 17.2519 4.45137 16.8606L8.27591 14.0367C8.28719 14.0284 8.29829 14.0198 8.30921 14.011L8.33601 13.9895C8.72065 13.6805 9.27936 13.6805 9.66399 13.9895L9.6908 14.011C9.70171 14.0198 9.71282 14.0284 9.7241 14.0367L13.5486 16.8606C14.0785 17.2519 14.8173 16.7577 14.6225 16.1422L13.1279 11.4195C13.1261 11.4138 13.1243 11.4081 13.1226 11.4024L13.0974 11.3174C12.9735 10.8993 13.1441 10.452 13.5194 10.2108L13.5713 10.1774C13.5882 10.1666 13.6047 10.1552 13.6209 10.1434L17.7236 7.15708C17.7236 7.15708 17.7236 7.15708 17.7236 7.15708C18.2535 6.77131 17.9705 5.95727 17.3064 5.95727H12.3001H12.2575Z" fill="#F7D37D"/>
                </svg>
              </div>
              <p class="calendar_rate_text">0 отзывов</p>
            </div>
          </div>
          <div class="calendar_time_container" v-if="intervals">
            <div
              class="calendar_time"
              @click="available ? selectedTime = time : selectedTime = ''"
              v-for="[time, available] in Object.entries(intervals)"
              :key="time"
              :style="{
                'cursor': available ? 'pointer' : 'not-allowed',
                'color': available ? 'var(--color-text)' : 'var(--color-text)',
                'background-color': selectedTime === time ? 'var(--color-global)' : 'var(--color-main)'
              }"
            >
              <div
                class="availableFlag"
                :style="{'backgroundColor': available ? '#04C562' : '#F97F7F'}"
              ></div>
              <p
                class="selectwidget"
                :style="{
                  'opacity': available ? '1' : '.15',
                  'color': selectedTime === time ? 'var(--color-selected-text)' : 'var(--color-text)'
                }"
              >
                {{ time }}
              </p>
            </div>
          </div>
        </div>

        <div class="calendar_bottom">
          <div class="card_next">
            <div class="card_next_container">
              <p class="card_next_text">Выбор даты и времени</p>
              
              <div class="divider_step_container">
                <div class="calendar_divider_step_one"></div>
                <div class="calendar_divider_step_two"></div>
              </div>
              <p class="card_next_subtext">Шаг {{ step }} из 4</p>
            </div>
            <div class="card_btn">
              <button v-if="size === 'desktop'" class="card_back_btn" @click="activeUslugi ? showEmployees() : showFavor(); step = 2">Назад</button>
              <button v-else class="card_back_btn-arrow" @click="activeUslugi ? showEmployees() : showFavor(); step = 2">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.3999 6.96667L4.8999 3.5V6.3H13.2999V7.7H4.8999V10.5L1.3999 6.96667Z" fill="var(--color-text)"/>
                  </svg>
              </button>
              <button :class="{'card_next_btn-disabled' : !selectedTime.length>0, 'card_next_btn-active' : selectedTime.length>0}" @click="showData()">{{size === 'desktop' ? 'Продолжить' : 'Далее'}}</button>
            </div>
          </div> 
        </div>
      </div>

      <div class="data" v-else-if="currentPage === 'data'">
        <div class="data_wrap">
          <div class="data_container">
            <div class="notes_data" v-if="size === 'desktop'">
              <div class="data_info">
                <div class="data_info_card">
                  <img :src="selectedEmployees[0].avatar" class="data_img">
                  <div class="data_info_container">
                    <p class="data_info_head">{{ selectedEmployees[0].firstname }}</p>
                    <p class="data_info_sub">Ваш мастер</p>
                  </div>    
                </div>
                <div class="data_info_card">
                  <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 5V0H7V5H5Z" fill="var(--color-global)"/>
                    <path d="M13 5V0H15V5H13Z" fill="var(--color-global)"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 19C1.44772 19 1 18.5523 1 18V3C1 2.44772 1.44772 2 2 2H4V6H8V2H12V6H16V2H18C18.5523 2 19 2.44772 19 3V18C19 18.5523 18.5523 19 18 19H2ZM17 17V8H3V17H17Z" fill="var(--color-global)"/>
                  </svg>
                  <div class="data_info_container">
                    <p class="data_info_head">{{ selectedDay.day + ' ' + selectedDay.month }}, {{ selectedTime }}</p>
                    <p class="data_info_sub">Дата и время</p>
                  </div>    
                </div>
              </div>

              <div class="divider_dotted_container">
                <div class="divider_dotted"></div>
              </div>

              <div class="data_info_bottom">
                <div class="data_info_card">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5998 3.20006H1.9998L11.9998 6.10352e-05L13.5998 3.20006Z" fill="var(--color-global)"/>
                    <path d="M0.799805 6.40011C0.799805 5.51645 1.51615 4.80011 2.3998 4.80011H13.5998C14.4835 4.80011 15.1998 5.51645 15.1998 6.40011V8.80001H12.5331C11.7776 8.80001 11.1998 9.32002 11.1998 10C11.1998 10.68 11.7776 11.2 12.5331 11.2H15.1998V13.6001C15.1998 14.4838 14.4835 15.2001 13.5998 15.2001H2.3998C1.51615 15.2001 0.799805 14.4838 0.799805 13.6001V6.40011Z" fill="var(--color-global)"/>
                  </svg>
                  <div class="data_info_container">
                    <p class="data_info_head">{{ selectedUslugi[0].cost }}</p>
                    <p class="data_info_sub">Стоимость</p>
                  </div>    
                </div>
              </div>
            </div>
            <div class="data_info" v-else>
                <div class="data_info_card">
                  <img :src="selectedEmployees[0].avatar" alt="" class="data_img">
                  <div class="data_info_container">
                    <p class="data_info_head">{{ selectedEmployees[0].firstname }}</p>
                    <p class="data_info_sub">Ваш мастер</p>
                  </div>    
                </div>

                <div style="display: flex; gap: 10px;">
                  <div class="data_info_card">
                    <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5 5V0H7V5H5Z" fill="var(--color-global)"/>
                      <path d="M13 5V0H15V5H13Z" fill="var(--color-global)"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M2 19C1.44772 19 1 18.5523 1 18V3C1 2.44772 1.44772 2 2 2H4V6H8V2H12V6H16V2H18C18.5523 2 19 2.44772 19 3V18C19 18.5523 18.5523 19 18 19H2ZM17 17V8H3V17H17Z" fill="var(--color-global)"/>
                    </svg>
                    <div class="data_info_container">
                      <p class="data_info_head">{{ selectedDay.day + ' ' + selectedDay.month }}, {{ selectedTime }}</p>
                      <p class="data_info_sub">Дата и время</p>
                    </div>
                  </div>
                  <div class="data_info_card">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5998 3.20006H1.9998L11.9998 6.10352e-05L13.5998 3.20006Z" fill="var(--color-global)"/>
                      <path d="M0.799805 6.40011C0.799805 5.51645 1.51615 4.80011 2.3998 4.80011H13.5998C14.4835 4.80011 15.1998 5.51645 15.1998 6.40011V8.80001H12.5331C11.7776 8.80001 11.1998 9.32002 11.1998 10C11.1998 10.68 11.7776 11.2 12.5331 11.2H15.1998V13.6001C15.1998 14.4838 14.4835 15.2001 13.5998 15.2001H2.3998C1.51615 15.2001 0.799805 14.4838 0.799805 13.6001V6.40011Z" fill="var(--color-global)"/>
                    </svg>
                    <div class="data_info_container">
                      <p class="data_info_head">{{ selectedUslugi[0].cost }}</p>
                      <p class="data_info_sub">Стоимость</p>
                    </div>    
                  </div>
                </div>
                <p class="figures_text-mob" v-if="size === 'mobile'">Убедитесь, что все указано верно</p>
              </div>
    
            <div class="data_details">
              <p class="data_details_head">Пожалуйста, укажите свои реальные контактные данные:</p>
              <div class="data_forma">
                <div class="data_forma_container">
                  <p class="data_forma_text">Имя <span class="data_forma_star">*</span></p>
                  <input type="text" id="" placeholder="Введите имя" v-model="clientFisrtName">
                </div>
                <div class="data_forma_container">
                  <p class="data_forma_text">Фамилия</p>
                  <input type="text" id="" placeholder="Введите фамилию" v-model="clientSecondName">
                </div>
                <div class="data_forma_container">
                  <p class="data_forma_text">Телефон <span class="data_forma_star">*</span></p>
                  <div class="form-group-phone" style="display: flex;">      
                    <div class="card flex justify-content-center">
                      <SelectWithFlag
                        :MainColor="MainColor" :WidgetColor="WidgetColor" :BakcgroundColor="BakcgroundColor" :TextColor="TextColor"
                        :options="countries"
                        class="select"
                        @input="option => selectedCountryPhone = option"
                        :placeholderdata="' '"
                        style="width: 70px;"
                        :value="selectedCountryPhone"
                      />
                    </div>
                    <InputMaskComponent autocomplete="new-password" @input="handleInput" id="basic" v-model="value" :mask="computedMask" :placeholder="computedPlaceholder" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="figures">
            <p class="figures_text" v-if="size === 'desktop'">Убедитесь, что все указано верно</p>
            <div class="figures_container">
              <div class="mark" v-if="!Mark" @click="Mark = true"></div>
              <div class="mark_active" v-else @click="Mark = false">
                <img src="../../static/img/checkmark.svg" alt="">
              </div>
              <p class="figures_personal">Согласен на обработку <span class="personal_span"> персональных данных</span></p>
            </div>
          </div>
        </div>
        <div class="data_bottom">
          <div class="card_next">
            <div class="card_next_container">
              <p class="card_next_text">Почти готово!</p>
              <div class="divider_step_container">
                <div class="data_divider_step_one"></div>
              </div>
              <p class="card_next_subtext">Шаг 4 из 4</p>
            </div>
            <div class="card_btn">
              <button v-if="size === 'desktop'" class="card_back_btn" @click="showCalendar">Назад</button>
              <button v-else class="card_back_btn-arrow" @click="showCalendar">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.3999 6.96667L4.8999 3.5V6.3H13.2999V7.7H4.8999V10.5L1.3999 6.96667Z" fill="var(--color-text)"/>
                  </svg>
              </button>
              <button :class="{'card_next_btn-disabled' : !clientFisrtName || !clientSecondName || !Mark || value.length < 6, 'card_next_btn-active' : clientFisrtName && clientSecondName && Mark && value.length > 6}" @click="generateApplicationAndClient()">Записаться</button>
            </div>
          </div> 
        </div>
      </div>

      <div class="notes" v-else-if="currentPage === 'notes'">
        <div class="notes_container">
          <div class="notes_data">
            <div class="notes_info">
              <div class="img_checkmark">
                <svg width="16.75px" height="13.47px" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7582 2.61701L6.67344 14.2532L0.0102539 7.59005L1.99015 5.61016L6.52697 10.147L14.6422 0.783203L16.7582 2.61701Z" fill="white"/>
                </svg>
              </div>
              <div class="data_info_card">
                <img :src="selectedEmployees[0].avatar" alt="" class="data_img">
                <div class="data_info_container">
                  <p class="data_info_head">{{ selectedEmployees[0].firstname }}</p>
                  <p class="data_info_sub">Ваш мастер</p>
                </div>    
              </div>
              <div class="data_info_card">
                <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 5V0H7V5H5Z" fill="var(--color-global)"/>
                  <path d="M13 5V0H15V5H13Z" fill="var(--color-global)"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2 19C1.44772 19 1 18.5523 1 18V3C1 2.44772 1.44772 2 2 2H4V6H8V2H12V6H16V2H18C18.5523 2 19 2.44772 19 3V18C19 18.5523 18.5523 19 18 19H2ZM17 17V8H3V17H17Z" fill="var(--color-global)"/>
                </svg>
                <div class="data_info_container">
                  <p class="data_info_head">{{ selectedDay.day + ' ' + selectedDay.month }}, {{ selectedTime }}</p>
                  <p class="data_info_sub">Дата и время</p>
                </div>    
              </div>
            </div>
            <div class="divider_dotted_container">
              <div class="divider_dotted"></div>
            </div>

            <div class="notes_info_bottom">
              <div class="data_info_card">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5998 3.20006H1.9998L11.9998 6.10352e-05L13.5998 3.20006Z" fill="var(--color-global)"/>
                  <path d="M0.799805 6.40011C0.799805 5.51645 1.51615 4.80011 2.3998 4.80011H13.5998C14.4835 4.80011 15.1998 5.51645 15.1998 6.40011V8.80001H12.5331C11.7776 8.80001 11.1998 9.32002 11.1998 10C11.1998 10.68 11.7776 11.2 12.5331 11.2H15.1998V13.6001C15.1998 14.4838 14.4835 15.2001 13.5998 15.2001H2.3998C1.51615 15.2001 0.799805 14.4838 0.799805 13.6001V6.40011Z" fill="var(--color-global)"/>
                </svg>
                <div class="data_info_container">
                  <p class="data_info_head">{{ selectedUslugi[0].cost }}</p>
                  <p class="data_info_sub">Стоимость</p>
                </div>    
              </div>
            </div>
          </div>

          <div class="notes_confirm">
            <div class="notes_confirm_container">
              <p class="notes_confirm_head">Вы записались!</p>
              <p class="notes_confirm_sub">Ниже, вы можете посмотреть прошлые записи, перенести время или отменить текущую запись</p>
            </div>
            <button class="notes_button">
              <svg width="14" height="14" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 12H19V14H10V12Z" fill="var(--color-global)"/>
                <path d="M10 16H16V18H10V16Z" fill="var(--color-global)"/>
                <path d="M1 13C1 12.4477 1.44772 12 2 12H7C7.55228 12 8 12.4477 8 13V18C8 18.5523 7.55228 19 7 19H2C1.44772 19 1 18.5523 1 18V13Z" fill="var(--color-global)"/>
                <path d="M10 5H16V7H10V5Z" fill="var(--color-global)"/>
                <path d="M10 1H19V3H10V1Z" fill="var(--color-global)"/>
                <path d="M1 2C1 1.44772 1.44772 1 2 1H7C7.55228 1 8 1.44772 8 2V7C8 7.55228 7.55228 8 7 8H2C1.44772 8 1 7.55228 1 7V2Z" fill="var(--color-global)"/>
              </svg>
              Мои записи
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
  
</template>

<script>

// import SelectWidget from '../components/SelectWidget.vue';

import Loader from '../components/LoadingSpan.vue';
import filialCard from '../components/WidgetBranchCard.vue';
import SelectWithFlag from '../components/SelectWithFlag.vue';

export default {
  components: { Loader, filialCard, SelectWithFlag},
  props: ['userId', 'projectId', 'widgetLink'],
  // components: { SelectWidget} ,

  data() {
    return {
      size: '',
      widgetId: null,

      Filials: [],
 
      branchSearch: '',
      index: 0,
      index_slider: 0,
      uslugi: [],
      employees:[],
      widgetData: '',

      Mark: false,
      currentPage: 'branch',
      selectedCountryPhone: { name: ' ', code: '+7', flag: require('@/images/flag/russia.png')},
      value: '', // Начальное значение для InputMaskComponent
      countries: [
      { name: ' ', code: '+7', flag: require('@/images/flag/russia.png') },
      { name: ' ', code: '+375', flag: require('@/images/flag/belarusi.png') },
      { name: ' ', code: '+7', flag: require('@/images/flag/kazahstan.png') },
      { name: ' ', code: '+380', flag: require('@/images/flag/ukraina.png') },
      ],
      currentImageIndex: 0,


      activeFilial: {},
      activeUslugi: false,
      activePersonal: false,

      selectedUslugi: [],
      selectedEmployees: [],

      selectedDay: {},
      selectedTime: '',

      theme: false,//по дефолту false - это светлая; true - темная
      colortheme: '',
      MainColor: '', // акцентный цвет виджета
      WidgetColor: '', // цвет у фона
      BakcgroundColor: '', // цвет у карточек
      TextColor: '', // цвет текста

      clientID: Number,
      ProjectID: Number,

      calendarSchedule: [],
      intervals: [],

      timeRange: '',
      interval: '',
      breakTime: '',

      branchImages: [],

      busyInterval: '',
      timeForDB: '',
      choiceLoading: false,
    };
  },
  created() {
    this.setSize(window.innerWidth);
    window.addEventListener('resize', this.onResize);
  },
  unmounted() {
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
    computedMask() {
      if (this.selectedCountryPhone) {
        const countryCode = this.selectedCountryPhone.code;
        if (countryCode === '+375' || countryCode === '+380') {
          return `${countryCode} (99) 999-99-99`;
        } else {
          return `${countryCode} (999) 999-99-99`;
        }
      } else {
        return '+7 (999) 999-99-99'; // Default mask
      }
    },
    computedPlaceholder() {
      return this.selectedCountryPhone ? this.selectedCountryPhone.code + ' |' : '+7 |';
    },
  },
  watch: {
    selectedCountry(newCountry) {
      if (newCountry) {
        this.value = newCountry.code + ' ' + this.value.replace(/^\s*\+\d\s*\|\s*/, '');
      }
    },
    activeFilial(){
      this.index_slider = 0
      if (this.activeFilial.name) {
        setInterval(() => {
          if (this.index_slider == this.activeFilial.images.length-1) {
            this.index_slider = 0
          }else{
            this.index_slider++
          }
          
        }, 5000);
      }
      
    }

  },
  mounted() {
    this.get_widgetid();
    
    setTimeout(() => {
      this.updateColors();
      this.filialsAddToArray();
      this.regWidgetLoad();
    }, 500);

  },
  methods: {
    async regWidgetLoad(){
      const loadTime = new Date().toISOString().replace('T', ' ').split('.')[0];
      console.log(this.widgetData.id)
      try {
        await this.$axios.post('/api/widget_load/', {
          widget_id: this.widgetData.id,
          load_time: loadTime,
          project: this.projectId,
        }, {withCredentials: true});
      } catch (error) {
        console.error('Error loading widget:', error);
      }
    },

    fetchImage(id) {
      this.$axios.get(`/api/get_image/?id=${id}`)
        .then(response => {
          return '' + response.data.image
        })
        .catch(error => {
          console.error('Error getting branch image:', error);
        });
    },

    async createApplicationFromWidget()
    
    {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');
      let nowtime = `${hours}:${minutes}:${seconds}`

      const formData = new FormData()
      formData.append('status', 'New');
      formData.append('data', this.timeForDB + ' ' + nowtime);
      formData.append('employee_id', this.selectedEmployees[0].id);
      formData.append('project_id', this.ProjectID);
      formData.append('usluga_id',  this.selectedUslugi[0].id);
      formData.append('client_id', this.clientID);
      formData.append('branch_id', this.activeFilial.id);
      formData.append('time', this.timeForDB + ' ' + this.selectedTime + ':01')
      formData.append('color', '#6266EA')

      console.log(formData)

      this.$axios.post('/api/create_application_from_widget/', formData, {withCredentials: true})
        .then(response => {
          console.log('application created:', response.data);
          this.showNotes()
        })
        .catch(error => {
          console.error('Error creating application:', error);
        });
    },

    async get_time(day, month, year){
      try {
        switch (month) {
          case 'Янв':
          month = '01'
            break;
            case 'Фев':
          month = '02'
            break;
            case 'Мар':
          month = '03'
            break;
            case 'Апр':
          month = '04'
            break;
            case 'Май':
          month = '05'
            break;
            case 'Июн':
          month = '06'
            break;
            case 'Июл':
          month = '07'
            break;
            case 'Авг':
          month = '08'
            break;
            case 'Сен':
          month = '09'
            break;
            case 'Окт':
          month = '10'
            break;
            case 'Ноя':
          month = '11'
            break;
            case 'Дек':
          month = '12'
            break;
        }

        this.timeForDB  = "" + year + '-' + month + '-' + day;
        console.log(this.timeForDB)
        let date = this.timeForDB
        let employee_id = this.selectedEmployees[0].id
        let dayofWeek = this.selectedDay.day_of_week
        let usluga_id = this.selectedUslugi[0].id
        const response = await this.$axios.get(`/api/get_time/?employee_id=${employee_id}&usluga_id=${usluga_id}&date=${date}&dayofWeek=${dayofWeek}`);
        this.intervals = response.data
      } catch (error) {
        console.error('Error fetching applications:', error);
      }
    },

    async getusluga(i) {
        try {
            const id = i;
            const response = await this.$axios.get(`/api/get_uslugabyid/?variable=${id}`);
            console.log(response)
            return response.data;
        } catch (error) {
            console.error('Ошибка при получении данных о Услуге:', error);
            throw error; // throw error, чтобы предоставить возможность обработки ошибки вверх по стеку вызовов
        }
    },
    dayChanged(d, m){
      this.timeRange = this.selectedDay.workTime
      this.breakTime = this.selectedDay.chillTime
      this.interval = this.selectedUslugi[0].time
      this.get_time(d, m, '2024')
    },

    generate14DayDictionary(employeeData) {
    const daysOfWeek = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
    const daysOfWeekShort = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
    const months = ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"];
    const dictionary = [];
    const today = new Date();

    const { recordType, daystime, schedule, startday, generalworktime, generalchilltime } = employeeData;

    if (recordType === 'weekly') {
        const scheduleData = JSON.parse(daystime);

        for (let i = 0; i < 14; i++) {
            const currentDate = new Date(today);
            currentDate.setDate(today.getDate() + i);

            const day = currentDate.getDate();
            const month = months[currentDate.getMonth()];
            const dayOfWeek = currentDate.getDay();
            const dayOfWeekShort = daysOfWeekShort[dayOfWeek];

            if (scheduleData[dayOfWeekShort] && scheduleData[dayOfWeekShort].work_time !== "") {
                dictionary.push({
                    "workTime": scheduleData[dayOfWeekShort].work_time,
                    "chillTime": scheduleData[dayOfWeekShort].chill_time,
                    "day": day,
                    "month": month,
                    "day_of_week": daysOfWeek[dayOfWeek]
                });
            }
        }
    } else if (recordType === 'replaceable') {
        const [daysOn, daysOff] = schedule.split('х').map(Number);
        const startDayIndex = daysOfWeekShort.indexOf(startday);

        for (let i = 0; i < 14; i++) {
            const currentDate = new Date(today);
            currentDate.setDate(today.getDate() + i);

            const day = currentDate.getDate();
            const month = months[currentDate.getMonth()];
            const dayOfWeek = currentDate.getDay();

            const daysSinceStart = (i + (today.getDay() - startDayIndex + 7) % 7);
            const isWorkingDay = daysSinceStart % (daysOn + daysOff) < daysOn;

            if (isWorkingDay) {
                dictionary.push({
                    "workTime": generalworktime,
                    "chillTime": generalchilltime,
                    "day": day,
                    "month": month,
                    "day_of_week": daysOfWeek[dayOfWeek]
                });
            }
        }
    }

    this.calendarSchedule = dictionary;
    console.log(dictionary);
},

    async filialsAddToArray(){
      while (this.Filials.length > 0){
        this.Filials.pop(); 
      } 
      try {
        // Ожидаем разрешения Promise
        let filialsArray = await this.getfilial();
        
        // Перебираем каждый словарь в массиве
        for (let f of filialsArray) {
            // Добавляем в массив Filials целиком
            this.Filials.push(f);
            
            // Выводим массив Filials в консоль (для отладки)
            console.log('Filials:', this.Filials);
        }
        this.Filials.forEach(filial => {
          this.fetchImage(filial.id);
        });
      } catch (error) {
          console.error('Error:', error);
      }
    },

    async getfilial() {
        try {
          console.log(this.widgetData.id)
            const response = await this.$axios.get(`/api/get_branch_bylink/?user=${this.userId}&widget=${this.widgetData.id}`);
            return response.data;
        } catch (error) {
            console.error('Ошибка при получении данных о филиалах:', error);
            throw error; // throw error, чтобы предоставить возможность обработки ошибки вверх по стеку вызовов
        }
    },


    async getuslugi_by_specialist(ei, fi) {
        try {
            const employee_id = ei;
            const filial_id = fi;
            const response = await this.$axios.get(`/api/getuslugi_by_specialist/?filial=${filial_id}&employee=${employee_id}`);
            return response.data;
        } catch (error) {
            console.error('Ошибка при получении данных о услугах:', error);
            throw error;
        }
    },

    async getspecialist_by_usluga(ui, fi) {
        try {
            const usluga_id = ui;
            const filial_id = fi;
            const response = await this.$axios.get(`/api/getspecialist_by_usluga/?filial=${filial_id}&usluga=${usluga_id}`);
            return response.data;
        } catch (error) {
            console.error('Ошибка при получении данных о специалистах:', error);
            throw error;
        }
    },

    onResize(event) {
      this.setSize(event.target.innerWidth);
    },
    setSize(width) {
      if (width > 768) {
        this.size = 'desktop';
      } else {
        this.size = 'mobile';
      }
    },

    updateColors() {
      if (this.theme) {
        this.colortheme = 'darkmode';
      } else {
        this.colortheme = 'lightmode';
      }

      if (this.MainColor) {
        this.$el.style.setProperty('--cm', this.MainColor);

        const transparentColor = this.changeTransparency(this.MainColor, 0.2);
        this.$el.style.setProperty('--cmlight', transparentColor);
      }
      if (this.WidgetColor) {
        this.$el.style.setProperty('--cw', this.WidgetColor);
      }
      if (this.BakcgroundColor) {
        this.$el.style.setProperty('--cb', this.BakcgroundColor);
      }
      if (this.TextColor) {
        this.$el.style.setProperty('--ct', this.TextColor);
      }
    },
    changeTransparency(hex, alpha) {
      const isValidHex = /^#([0-9A-Fa-f]{3}){1,2}$/i.test(hex);
      if (!isValidHex) {
        console.error('Invalid HEX color:', hex);
        return hex;
      }

      hex = hex.replace(/^#/, '');
      const bigint = parseInt(hex, 16);

      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;

      return `rgba(${r},${g},${b},${alpha})`;
    },

    async activateFilial(filialData) {
        try {
          this.activeFilial = filialData;
            while (this.uslugi.length > 0){
              this.uslugi.pop(); 
            }
            this.uslugi = await this.getuslugi_by_specialist(0, filialData.id);
            while (this.employees.length > 0){
              this.employees.pop(); 
            }
            this.employees = await this.getspecialist_by_usluga(0, filialData.id);
        } catch (error) {
            console.error('Ошибка при активации Филиала:', error);
        }
    },

    async UslugaSelected(data){
      while(this.selectedUslugi.length > 0) {
        this.selectedUslugi.pop();
      }
      this.selectedUslugi.push(data);
      while (this.employees.length > 0){
          this.employees.pop(); 
      }
      this.employees = await this.getspecialist_by_usluga(data.id, this.activeFilial.id);
      console.log(this.employees)
    },

    UslugaDeleting(data) {
      let indexToRemove = this.selectedUslugi.indexOf(data);
        if (indexToRemove !== -1) {
          this.selectedUslugi.splice(indexToRemove, 1);
        }
    },

    async EmployeeSelected(data){
      if (data == 'random') {
        this.selectedEmployees.pop();
        const randomIndex = Math.floor(Math.random() * this.employees.length);
        const randomEmployee = this.employees[randomIndex];
        
        
        this.uslugi = await this.getuslugi_by_specialist(randomEmployee.id, this.activeFilial.id);
        setTimeout(() => {
          this.selectedEmployees.push(randomEmployee);
          this.choiceLoading = false;
        }, 1000);
        
      }else{
        while(this.selectedEmployees.length > 0) {
          this.selectedEmployees.pop();
        }
        this.selectedEmployees.push(data);
        while (this.uslugi.length > 0){
          this.uslugi.pop(); 
        }
        this.uslugi = await this.getuslugi_by_specialist(data.id, this.activeFilial.id);
      }
    },

    EmployeeDeleting(data) {
      let indexToRemove = this.selectedEmployees.indexOf(data);
        if (indexToRemove !== -1) {
          this.selectedEmployees.splice(indexToRemove, 1);
        }
    },
    
    handleInput() {
      const countryCode = this.selectedCountry ? this.selectedCountry.code : '';
      this.value = countryCode + ' ' + this.value.replace(/^\s*\+\d\s*\|\s*/, '');
    },
    showBranch() {
      this.currentPage = 'branch';
    },
    showFavor() {
      this.currentPage = 'favor';
    },
    showChoice() {
      if (this.activeFilial.name) {
        this.currentPage = 'choice';
      }
    },
    showEmployees() {
      this.currentPage = 'employees';
    },
    showCalendar() {
      console.log(this.selectedEmployees[0])
      this.currentPage = 'calendar';
    },
    showData() {
      if (this.selectedTime.length>0) {
        this.currentPage = 'data';
      }
    },
    showNotes() {
      if (this.clientFisrtName && this.clientSecondName && this.Mark && this.value.length > 6) {
        this.currentPage = 'notes';
      }
    },

    async get_widgetid(){
      try {
        const response = await this.$axios.get(`/api/get_widgetid/?userId=${this.userId}&projectId=${this.projectId}&widgetLink=${this.widgetLink}`);
        this.ProjectID = response.data.project
        this.theme = response.data.theme
        this.MainColor = response.data.main
        this.WidgetColor = response.data.back
        this.BakcgroundColor = response.data.plashka
        this.TextColor = response.data.text

        this.widgetData = response.data

        this.updateColors(response.data)
      } catch (error) {
        console.error('Error fetching get_widgetid:', error);
      }
    },
    async generateApplicationAndClient(){
      this.create_client();
      setTimeout(() => {
        this.createApplicationFromWidget();
      }, 1000);
    },
    async create_client() {
      const formData = new FormData();
      formData.append('firstname', this.clientFisrtName);
      formData.append('secondname', this.clientSecondName);
      formData.append('mail', 'NaN');
      formData.append('phone', this.value);

      let now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');

      let formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;

      formData.append('date', formattedDate);
      formData.append('project', this.ProjectID);
      await this.$axios.post('/api/create_client/', formData, {withCredentials: true})
        .then(response => {
          this.clientID = response.data.client_id
        })
        .catch(error => {
          console.error('Error creating client:', error);
        });
    },
    setActiveProject(projectId) {
      this.$store.commit('setActiveProject', projectId);
    },
    deactivateProject() {
      this.$store.commit('deactivateProject');
    },
    changeColor(index) {
      // Сброс выбора всех элементов
      this.isClicked = this.isClicked.map((value, i) => i === index);
    }
  }
};
</script>

<style scoped>
.arrows{
  display: none;
}
.site{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

#lightmode {
  --color-main: var(--cw, white);
  --color-gray: var(--cb, #FAFAFA);
  --color-btnmain: var(--cmlight, #EBEDFF);
  --color-global: var(--cm, #6266EA);
  --color-text: var(--ct, #535C69);
  --color-shadow: rgb(216, 216, 216);
  --color-selected-text: #FFFFFF;
}

#darkmode{
  --color-main: var(--cw, #1A1B27);
  --color-gray: var(--cb, #222433);
  --color-btnmain: var(--cmlight, rgba(255, 194, 90, 0.1));
  --color-global: var(--cm, #FFCF7D);
  --color-text: var(--ct, #F5F5F5);
  --color-shadow: rgb(21, 20, 29);
  --color-selected-text: #1A1B27;
}

input[type="number"] {
    -moz-appearance: textfield;
  }

  /* Для Webkit (Chrome, Safari) */
  input::-webkit-inner-spin-button,
  input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }


*,
html * /* override x.xhtml.ru style */ 
{
  scrollbar-width: thin;
  scrollbar-color: #E5E8EB transparent;
}

*::-webkit-scrollbar,
html *::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}
*::-webkit-scrollbar-track,
html *::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb,
html *::-webkit-scrollbar-thumb {
  background-color: #E5E8EB;
  border-radius: 5px;
  border: 3px solid transparent;
}

.card-avatar{
  width: 54px;
  height: 54px;
  object-fit: cover;
  border-radius: 5px;
}


.card_back_btn-arrow{
  border: 1px solid var(--color-text);
  background: var(--color-main);
  border-radius: 100px;
  width: 30px;
  height: 30px;
  padding: 0;
}

.card_back_btn-arrow:hover{
  border: 1px solid var(--color-global);
}

.card_back_btn-arrow:hover svg path{
  fill: var(--color-global);
}


.choice-banner{
  display: none;
}

SelectPage>>>.items{
  background-color: var(--color-gray);
}
.p-dropdown-panel{
  background: var(--color-gray);
}
.delete-btn{
    width: 30px;
    height: 30px;
    background-color: rgba(249, 144, 144, 0.1);
    border-radius: 50%;
    padding: 0;
  }

  .delete-btn:hover{
    cursor: pointer;
    width: 30px;
    height: 30px;
    background-color: rgba(241, 129, 129, 0.391);
    border-radius: 50%;
    padding: 0;
  }
.main{
  width: 600px;
  filter: drop-shadow(0 0 20px rgb(194, 192, 192));
  border-radius: 20px;
  background: transparent;
}
.choice{
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px 40px;
  border-radius: 25px;
  height: 427px;
  background: var(--color-main); 
}
.branch{
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 30px 40px;
  border-radius: 25px;
  height: 427px;
  background: var(--color-main);
}
.search input{
  background-color: var(--color-main);
  color:#D2D8DE;
  margin: 0;
  width: 100%;
  border: 1px solid #D2D8DE;
}

.search input:focus{
  outline: none;
  border: 1px solid var(--color-global);
}
.search input{
  background-image: url(../../static/img/search.svg);
  background-repeat: no-repeat;
  padding-left: 35px;
  background-position: 15px;
}
.card{
  background-color: var(--color-gray);
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}

.card:hover .card_name{
  color: var(--color-global);
}

.card:hover .card_address, .card:hover .card_time{
  color: var(--color-text);
}

svg path{
  transition: all .2s ease;
}

.card:hover svg path{
  fill: var(--color-text);
}

.card_container{
  width: 245px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.card_address_container{
  display: flex;
  align-items: center;
  gap: 5px;
}
.card_time_container{
  display: flex;
  align-items: center;
  gap: 5px;
}
.card_name{
  font-family: TT Norms Medium;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
  margin: 0;
  transition: all .2s ease;
}
.card_address{
  font-family: TT Norms Medium;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #AFB6C1;
  margin: 0;
  transition: all .2s ease;
}
.card_time{
  font-family: TT Norms Medium;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  color: #AFB6C1;
  margin: 0;
  transition: all .2s ease;
}
.card_next_container{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.card_next{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card_next_text{
  font-family: TT Norms Medium;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
  margin: 0;
}
.divider{
  width: 200px;
  height: 3px;
  border-radius: 15px;
  border: none;
  background: var(--color-gray);
  margin: 0;
}
.card_next_subtext{
  font-family: TT Norms Medium;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
  margin: 0;
}
.card_number{
  text-decoration: underline;
  color: var(--color-global);
}
.card_next_btn-disabled{
  border-radius: 3px;
  cursor: not-allowed;
  background: var(--color-gray);
  color: rgba(var(--color-text), 0.5);
  filter: brightness(100%);
}

.card_next_btn-active{
  border-radius: 3px;
  cursor: pointer;
  background: var(--color-global);
  color: var(--color-main);
  filter: brightness(100%);
}

.card_next_btn-active:hover{
  cursor: pointer;
  background: var(--color-btnmain);
  color: var(--color-global);
}

.choice_overview{
  display: flex;
  justify-content: space-between;
  height: 90px;
  background: var(--color-gray);
  padding: 20px;
  border-radius: 15px;
}
.choice_rate_container{
  display: flex;
  align-items: center;
  gap: 15px;
}
.choice_rate{
  font-family: TT Norms Medium;
  font-size: 42px;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--color-text);
}
.choice_star_text{
  font-family: TT Norms Medium;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
  text-decoration: underline;
  cursor: pointer;
}
p{
  margin: 0;
}
.stars{
  display: flex;
  gap: 7px;
}
.choice_star_container{
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.choice_info_container{
  display: flex;
  align-items: center;
  gap: 10px;
}
.choice_info_text{
  font-family: TT Norms Medium;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
}
.service{
  display: flex;
  gap: 20px;
}
.service_container{
  position: relative;
  padding: 20px;
  width: 250px;
  height: 170px;
  background: var(--color-gray);
  border-radius: 15px;
  cursor: pointer;
}

.service_container:hover .service_container_text{
  color: var(--color-global);
}

.service_container_text{
  font-family: TT Norms Medium;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
  transition: all .2s ease;
}
.favor{
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 40px;
  height: 427px;
  background: var(--color-main);
  border-radius: 25px;
}
.favor_card_container{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  width: 540px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.favor_card{
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: var(--color-gray);
  border-radius: 10px;
  padding: 10px;
  width: 255px;
}
.favor_compo-wrapper{
  display: flex;
  align-items: center;
  gap: 10px;
}
.favor_text{
  text-align: left;
  font-family: TT Norms Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: var(--color-text);  
}
.compo-wrapper-tariff{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tariff{
  display: flex;
  align-items: center;
  gap: 5px;
}
.tariff_text{
  font-family: TT Norms light;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
}
.dot {
  display: flex;
  align-items: center;
  height: 2px;
  width: 2px;
  background: var(--color-global);
  border-radius: 5px;
}
.selectwidget{
  position: relative;
  font-family: TT Norms Medium;
  font-size: 9px;
  font-weight: 500;
  line-height: 9px;
  text-align: left;
}
.selectwidget_dot {
  position: absolute;
  top: 3px;
  right: 3px;
  display: flex;
  align-items: center;
  height: 2px;
  width: 2px;
  background: #04C562;
  border-radius: 5px;
}
.btn-wrapper{
  border-radius: 100px;
  background: var(--color-btnmain);
  color: var(--color-global);
  opacity: .9;
  font-weight: 500;
  height: 30px;
  font-family: TT Norms Medium; 
  padding: 2px 20px;
}

.divider_step_container{
  width: 200px;
  display: flex;
  gap: 5px;
}
.divider_step_one{
  width: 30px;
  height: 3px;
  border-radius: 100px;
  background: var(--color-global);
}
.divider_step_two{
  width: 166px;
  height: 3px;
  border-radius: 100px;
  background: var(--color-gray);
}

.card_btn{
  display: flex;
  align-items: center;
  gap: 5px;
}
.card_back_btn{
  font-family: TT Norms Medium;
  background: var(--color-main);
  color: var(--color-text);
}
.card_back_btn:hover{
  background: var(--color-main);
  color: var(--color-global);
}
.employees{
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 40px;
  height: 427px;
  background: var(--color-main);
  border-radius: 25px;
}
.employees_divider_step_one{
  width: 107px;
  height: 3px;
  border-radius: 100px;
  background: var(--color-global);
}
.employees_divider_step_two{
  width: 91px;
  height: 3px;
  border-radius: 100px;
  background: var(--color-gray);
}
.employees_card_container{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  width: 540px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.employees_card{
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: var(--color-gray);
  border-radius: 10px;
  padding: 10px;
  width: 255px;
}
.employees_compo-wrapper{
  display: flex;
  gap: 10px;
}
.employees_text{
  text-align: left;
  font-family: TT Norms Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: var(--color-text);  
}
.employees_subtext{
  font-family: TT Norms Light;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
}
.employees_container{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.star_container{
  display: flex;
  gap: 5px;
}
.employees_rate{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.employees_rate_text{
  font-family: TT Norms Light;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
  text-decoration: underline;
  cursor: pointer;
}
.user-alt{
  width: 70px;
  height: 54px;
  background: var(--color-main);
  display: flex;
  align-items: center;
  justify-content: center;
}
.alt_btn{
  display: flex;
  justify-content: end;
}
.btn-wrapper:hover{
  opacity: 1;
}
.calendar{
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 40px;
  height: fit-content;
  background: var(--color-main);
  border-radius: 25px;
}

.availableFlag{
  height: 2px;
  width: 2px;
  background-color: rgb(78, 214, 85);
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 50%;
}
.calendar_numbers_container{
  display: flex;
  justify-content: start;
  gap: 5px;
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  scrollbar-width: thin;
}
.calendar_numbers{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 33px;
  border-radius: 3px;
  background: var(--color-gray);
  cursor: pointer;
}
.calendar_numbers_head{
  font-family: TT Norms Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
}
.calendar_numbers_sub{
  font-family: TT Norms light;
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
}
.weekend{
  color: #F97F7F;
}
.calendar_card_container{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}
.calendar_card{
  background: var(--color-gray);
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.calendar_compo-wrapper{
  display: flex;
  align-items: center;
  gap: 10px;
}
.calendar_text{
  text-align: left;
  font-family: TT Norms Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: var(--color-text);  
}
.calendar_container{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.calendar_wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.calendar_rate{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.calendar_rate_text{
  font-family: TT Norms light;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
  text-decoration: underline;
  cursor: pointer;
}
.calendar_time_container{
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px; 
}
.calendar_time{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-main);
  font-family: TT Norms light;
  font-size: 10px;
  font-weight: 300;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
  width: 45px;
  height: 24px;
  border-radius: 3px;
  position: relative;
}
.calendar_divider_step_one{
  width: 171px;
  height: 3px;
  border-radius: 100px;
  background: var(--color-global);
}
.calendar_divider_step_two{
  width: 28px;
  height: 3px;
  border-radius: 100px;
  background: #D8DDE3;
}
.data{
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 30px 40px;
  height: 427px;
  background: var(--color-main);
  border-radius: 25px;
}
.data_wrap{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.data_info{
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px 10px 0 0;
  width: 236px;
  background: var(--color-gray);
  padding: 25px 15px 12px 15px;
}
.data_info_card{
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  background: var(--color-main);
  padding: 10px;
  border-radius: 3px;
}
.data_img{
  width: 40px;
  aspect-ratio: 1;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 5px;
}
.data_info_container{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.data_info_head{
  font-family: TT Norms Medium;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
}
.data_info_sub{
  font-family: TT Norms Medium;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
  color: #AFB6C1;
}
.data_container{
  display: flex;
  gap: 25px;
}
.data_details_head{
  font-family: TT Norms Medium;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
}
.data_details{
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 260px;
}
.data_forma_text{
  font-family: TT Norms Medium;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
}
.data_forma{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.data_forma_star{
  font-family: TT Norms Medium;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #F97F7F;
}
.data_forma_container{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
input{
  margin: 0;
  background: var(--color-gray);
  color: var(--color-text);
}
.figures{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.figures_text{
  width: 51%;
  font-family: TT Norms Medium;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #D2D8DE;
}

.figures_text-mob{
  width: 100%;
  font-family: TT Norms Medium;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
  color: #D2D8DE;
}
.figures_container{
  width: 49%;
  display: flex;
  align-items: center;
  gap: 5px;
}
.mark{
  width: 16px;
  height: 16px;
  border-radius: 3px;
  border: 1px solid #C6CBD2;
  cursor: pointer;
  user-select:none;
}
.mark_active{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  border: 1px solid var(--color-global);
  cursor: pointer;
  background: var(--color-global);
  user-select:none;
}
.figures_personal{
  font-family: TT Norms Medium;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  color: #C6CBD2;
}
.personal_span{
  color: var(--color-global);
  cursor: pointer;
  text-decoration: underline;
}
.data_divider_step_one{
  width: 201px;
  height: 3px;
  border-radius: 100px;
  background: var(--color-global);
}
.notes{
  display: flex;
  flex-direction: column;
  padding: 30px 40px;
  height: 356px;
  background: var(--color-main);
  border-radius: 25px;
}
.notes_container{
  display: flex;
  align-items: center;
  gap: 15px;
}
.notes_info{
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px 10px 0 0;
  width: 236px;
  background: var(--color-gray);
  padding: 25px 15px 12px 15px;
}
.notes_confirm{
  width: 261px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.notes_confirm_container{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.notes_confirm_head{
  font-family: TT Norms Medium;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
}
.notes_confirm_sub{
  font-family: TT Norms light;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
}
.notes_button{
  width: fit-content;
  font-family: TT Norms Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  gap: 5px;
  background: var(--color-btnmain);
  color: var(--color-global);
}

.notes_button:hover{
  cursor: pointer;
  background: var(--color-btnmain);
  color: var(--color-global);
}

.notes_button:hover svg path{
  fill: var(--color-global);
}
.branch_card{
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 262px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.compo-container{
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 10px;
  height: 230px;
  padding: 40px 40px 50px 40px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  margin-bottom: -30px;
  object-fit: cover;
  width: 100%;
  box-shadow: inset 0px -100px 30px rgba(0, 0, 0, 0.825);
}
.compo-wrap{
  display: flex;
  gap: 20px;
}
.compo-top{
  display: flex;
  align-items: center;
  gap: 5px;
}
.compo-text{
  color: white;
  font-family: TT Norms Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
}
.divider_container{
  justify-content: center;
  display: flex;
  gap: 3px;
}
.compo_divider{
  width: 100px;
  height: 5px;
  border-radius: 100px;
  background: #FFFFFF80;
  cursor: pointer;
  transition: all .2s ease;
}
.compo_divider:hover{
  background: #ffffffc8;
}
.service_tools{
  position: absolute;
  bottom: -4px;
  right: 0;
}
.service_ppl{
  position: absolute;
  bottom: 0;
  right: -25px;
}
.checkmark{
  position: absolute;
  top: 10px;
  right: 10px;
  width: 18px;
  height: 18px;
  background: var(--color-main);
  border: 1px solid #F5F5F5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}
.checkmark:hover{
  background: #04C562;
  cursor: pointer;
}

.active-checkmark{
  position: absolute;
  top: 10px;
  right: 10px;
  width: 18px;
  height: 18px;
  background: #04C562;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease
}
.img_checkmark{
  position: absolute;
  top: -20px;
  left: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #04C562;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.notes_data{
  display: flex;
  flex-direction: column;
}
.notes_info_bottom{
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 0 0 10px 10px;
  width: 236px;
  background: var(--color-gray);
  padding: 8px 15px 25px 15px;
}
.data_info_bottom{
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 0 0 10px 10px;
  width: 236px;
  background: var(--color-gray);
  padding: 8px 15px 25px 15px;
}
.divider_dotted_container{
  position: relative;
  background: var(--color-gray);
  height: 12px;
  display: flex;
  align-items: center;
}
.divider_dotted{
  width: 100%;
  height: 2px;
  border-bottom: 2px dashed #AFB6C1;
}
.divider_dotted_container::before{
  position: absolute;
  top: 0;
  width: 12px;
  height: 12px;
  left: 0;
  content: '';
  border-radius: 50%;
  background: var(--color-main);
  transform: translateX(-50%)
}
.divider_dotted_container::after{
  position: absolute;
  top: 0;
  width: 12px;
  height: 12px;
  right: 0;
  content: '';
  border-radius: 50%;
  background: var(--color-main);
    transform: translateX(50%)
}


@media (max-width: 768px){
  .site{
    height: 100vh;
    display: block;
    background-color: white;
  }
  .choice-banner{
    display: block;
    height: 250px;
    width: 100%;
    background-size: cover;
    border-radius: 15px;
  }
  .p-dropdown{
    background: var(--color-gray);
  }
  .delete-btn{
      width: 30px;
      height: 30px;
      background-color: rgba(249, 144, 144, 0.1);
      border-radius: 50%;
      padding: 0;
    }


    .delete-btn:hover{
      cursor: pointer;
      width: 30px;
      height: 30px;
      background-color: rgba(241, 129, 129, 0.391);
      border-radius: 50%;
      padding: 0;
    }
  .main{
    width: 100%;
    border-radius: 0px;
    background: transparent;
  }
  .choice{
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 10px;
    padding: 10px 10px 15px 10px;
    border-radius: 0;
    height: 80vh;
    overflow-y: scroll;
    background: var(--color-main);
  }
  .branch{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 10px 15px 10px;
    border-radius: 0;
    height: 80vh;
    background: var(--color-main);
  }
  .search input{
    background-color: var(--color-main);
    color:#D2D8DE;
    margin: 0;
    width: 100%;
    border: 1px solid #D2D8DE;
  }

  .search input:focus{
    outline: none;
    border: 1px solid var(--color-global);
  }
  .search input{
    background-image: url(../../static/img/search.svg);
    background-repeat: no-repeat;
    padding-left: 35px;
    background-position: 15px;
  }
  .card{
    background-color: var(--color-gray);
    border-radius: 15px;
    display: block;
    align-items: center;
    cursor: pointer;
  }

  .card:hover .card_name{
    color: var(--color-global);
  }

  .card:hover .card_address, .card:hover .card_time{
    color: var(--color-text);
  }

  svg path{
    transition: all .2s ease;
  }

  .card:hover svg path{
    fill: var(--color-text);
  }

  .card_container{
    background-color: var(--color-gray);
    width: 100%;
    padding: 20px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .card_address_container{
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .card_time_container{
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .card_name{
    font-family: TT Norms Medium;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text);
    margin: 0;
    transition: all .2s ease;
  }
  .card_address{
    font-family: TT Norms Medium;
    font-size: 10px;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #AFB6C1;
    margin: 0;
    transition: all .2s ease;
  }
  .card_time{
    font-family: TT Norms Medium;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #AFB6C1;
    margin: 0;
    transition: all .2s ease;
  }
  .card_next_container{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .card_next{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .card_next_text{
    font-family: TT Norms Medium;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text);
    margin: 0;
  }
  .divider{
    width: 130px;
    height: 3px;
    border-radius: 15px;
    border: none;
    background: var(--color-gray);
    margin: 0;
  }
  .card_next_subtext{
    font-family: TT Norms Medium;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text);
    margin: 0;
  }
  .card_number{
    text-decoration: underline;
    color: var(--color-global);
  }
  .card_next_btn-disabled{
    border-radius: 30px;
    cursor: not-allowed;
    background: var(--color-btnmain);
    color: var(--color-global);
    filter: brightness(50%);
  }

  .card_next_btn-active{
    border-radius: 30px;
    cursor: pointer;
    background: var(--color-global);
    color: var(--color-main);
    filter: brightness(100%);
  }

  .card_next_btn-active:hover{
    cursor: pointer;
    background: var(--color-btnmain);
    color: var(--color-global);
  }

  /* .choice>*:nth-child(3){
    margin-top: auto;
  } */

  .choice_overview{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: fit-content;
    background: var(--color-gray);
    padding: 20px;
    border-radius: 15px;
    gap: 20px;
  }
  .choice_rate_container{
    display: flex;
    align-items: center;
    gap: 15px;
  }
  .choice_rate{
    font-family: TT Norms Medium;
    font-size: 42px;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--color-text);
  }
  .choice_star_text{
    font-family: TT Norms Medium;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text);
    text-decoration: underline;
    cursor: pointer;
  }
  p{
    margin: 0;
  }
  .stars{
    display: flex;
    gap: 7px;
  }
  .choice_star_container{
    display: flex;
    flex-direction: column;
    gap: 7px;
  }
  .choice_info_container{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .choice_info_text{
    font-family: TT Norms Medium;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text);
  }
  .service{
    display: flex;
    justify-content: space-between;
    gap: 0;
  }
  .service_container{
    position: relative;
    padding: 20px;
    width: 48%;
    height: 170px;
    background: var(--color-gray);
    border-radius: 15px;
    cursor: pointer;
  }

  .service_container:hover .service_container_text{
    color: var(--color-global);
  }

  .service_container_text{
    font-family: TT Norms Medium;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text);
    transition: all .2s ease;
  }
  .favor{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 10px 15px 10px;
    border-radius: 0;
    height: 80vh;
    background: var(--color-main);
  }
  .favor_card_container{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .favor_card{
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: var(--color-gray);
    border-radius: 10px;
    padding: 10px;
    width: 100%;
  }
  .favor_compo-wrapper{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .favor_text{
    text-align: left;
    font-family: TT Norms Medium;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: var(--color-text);  
  }
  .compo-wrapper-tariff{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .tariff{
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .tariff_text{
    font-family: TT Norms light;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text);
  }
  .dot {
    display: flex;
    align-items: center;
    height: 2px;
    width: 2px;
    background: var(--color-global);
    border-radius: 5px;
  }
  .selectwidget{
    position: relative;
  }
  .selectwidget_dot {
    position: absolute;
    top: 3px;
    right: 3px;
    display: flex;
    align-items: center;
    height: 2px;
    width: 2px;
    background: #04C562;
    border-radius: 5px;
  }
  .btn-wrapper{
    border-radius: 100px;
    background: var(--color-btnmain);
    color: var(--color-global);
    font-weight: 500;
    height: 30px;
    font-family: TT Norms Medium; 
    padding: 2px 20px;
  }

  .divider_step_container{
    width: 125px;
    display: flex;
    gap: 5px;
  }
  .divider_step_one{
    width: 30px;
    height: 3px;
    border-radius: 100px;
    background: var(--color-global);
  }
  .divider_step_two{
    width: 166px;
    height: 3px;
    border-radius: 100px;
    background: var(--color-gray);
  }
  .card_btn_container{
    display: flex;
    gap: 10px;
  }
  .card_back_btn{
    font-family: TT Norms Medium;
    background: var(--color-main);
    color: var(--color-text);
  }
  .card_back_btn:hover{
    background: var(--color-main);
    color: var(--color-global);
  }
  .employees{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 10px 15px 10px;
    border-radius: 0;
    height: 80vh;
    background: var(--color-main);
  }
  .employees_divider_step_one{
    width: 107px;
    height: 3px;
    border-radius: 100px;
    background: var(--color-global);
  }
  .employees_divider_step_two{
    width: 91px;
    height: 3px;
    border-radius: 100px;
    background: var(--color-gray);
  }
  .employees_card_container{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .employees_card{
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: var(--color-gray);
    border-radius: 10px;
    padding: 10px;
    width: 100%;
  }
  .employees_compo-wrapper{
    display: flex;
    gap: 10px;
  }
  .employees_text{
    text-align: left;
    font-family: TT Norms Medium;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: var(--color-text);  
  }
  .employees_subtext{
    font-family: TT Norms Medium;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text);
  }
  .employees_container{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .star_container{
    display: flex;
    gap: 5px;
  }
  .employees_rate{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .employees_rate_text{
    font-family: TT Norms Medium;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text);
    text-decoration: underline;
    cursor: pointer;
  }
  .user-alt{
    width: 70px;
    height: 54px;
    background: var(--color-main);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .alt_btn{
    display: flex;
    justify-content: end;
  }
  .btn-wrapper:hover{
    filter: brightness(70%);
  }
  .calendar{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 10px 15px 10px;
    border-radius: 0;
    height: 80vh;
    background: var(--color-main);
  }
  .calendar_numbers_container{
    display: flex;
    gap: 10px;
    overflow-x: scroll;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }
  .calendar_numbers{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 45px;
    height: 33px;
    border-radius: 3px;
    background: var(--color-gray);
    cursor: pointer;
  }
  .calendar_numbers_head{
    font-family: TT Norms Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text);
  }
  .calendar_numbers_sub{
    font-family: TT Norms light;
    font-size: 10px;
    font-weight: 500;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text);
  }
  .weekend{
    color: #F97F7F;
  }
  .calendar_card_container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
  .calendar_card{
    background: var(--color-gray);
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 15px;
    flex-grow: 5;
  }
  .calendar_compo-wrapper{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .calendar_text{
    text-align: left;
    font-family: TT Norms Medium;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: var(--color-text);  
  }
  .calendar_container{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .calendar_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;
    align-items: start;
  }
  .calendar_rate{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .calendar_rate_text{
    font-family: TT Norms Medium;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text);
    text-decoration: underline;
  }
  .calendar_time_container{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 5px; 
  }
  .calendar_time{
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-main);
    font-family: TT Norms Medium;
    font-size: 10px;
    font-weight: 300;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    width: 45px;
    height: 24px;
  }
  .calendar_divider_step_one{
    width: 171px;
    height: 3px;
    border-radius: 100px;
    background: var(--color-global);
  }
  .calendar_divider_step_two{
    width: 28px;
    height: 3px;
    border-radius: 100px;
    background: #D8DDE3;
  }
  .data{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 10px 15px 10px;
    border-radius: 0;
    height: 80vh;
    background: var(--color-main);
  }
  .data_wrap{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .data_info{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 10px;
    width: 100%;
    background: var(--color-gray);
    padding: 10px;
  }
  .data_info_card{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    background: var(--color-main);
    padding: 10px;
    border-radius: 3px;
  }
  .data_img{
    width: 40px;
    height: 40px;
  }
  .data_info_container{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .data_info_head{
    font-family: TT Norms Medium;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text);
  }
  .data_info_sub{
    font-family: TT Norms Medium;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    color: #AFB6C1;
  }
  .data_container{
    display: block;
    gap: 25px;
  }
  .data_details_head{
    font-family: TT Norms Medium;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text);
  }
  .data_details{
    margin: 20px 30px 0 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: auto;
  }
  .data_forma_text{
    font-family: TT Norms Medium;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text);
  }
  .data_forma{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .data_forma_star{
    font-family: TT Norms Medium;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #F97F7F;
  }
  .data_forma_container{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  input{
    margin: 0;
    background: var(--color-gray);
    color: var(--color-text);
  }
  .figures{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .figures_text{
    width: 51%;
    font-family: TT Norms Medium;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #D2D8DE;
  }
  .figures_container{
    margin: 0 30px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .mark{
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: 1px solid #C6CBD2;
    cursor: pointer;
    user-select:none;
  }
  .mark_active{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: 1px solid var(--color-global);
    cursor: pointer;
    background: var(--color-global);
    user-select:none;
  }
  .figures_personal{
    font-family: TT Norms Medium;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #C6CBD2;
  }
  .personal_span{
    color: var(--color-global);
    cursor: pointer;
    text-decoration: underline;
  }
  .data_divider_step_one{
    width: 201px;
    height: 3px;
    border-radius: 100px;
    background: var(--color-global);
  }
  .notes{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 10px 15px 10px;
    border-radius: 0;
    height: 80vh;
    background: var(--color-main);
  }
  .notes_container{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 15px;
  }
  .notes_info{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 10px 10px 0 0;
    width: 100%;
    background: var(--color-gray);
    padding: 25px 15px 12px 15px;
  }
  .data_bottom{
    bottom: 14px;
    width: 95%;
    position: absolute;
  }
  .favor_bottom{
    bottom: 14px;
    width: 95%;
    position: absolute;
  }
  .employees_bottom{
    bottom: 14px;
    width: 95%;
    position: absolute;
  }
  .notes_confirm{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 25px;
  }
  .notes_confirm_container{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .notes_confirm_head{
    font-family: TT Norms Medium;
    font-size: 28px;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--color-text);
  }
  .notes_confirm_sub{
    font-family: TT Norms light;
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--color-text);
  }
  .notes_button{
    font-family: TT Norms Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    gap: 5px;
    width: fit-content;
    border-radius: 100px;
    background: var(--color-global);
    color: var(--color-main);
  }

  .notes_button:hover{
    cursor: pointer;
    background: var(--color-btnmain);
    color: var(--color-global);
  }

  .notes_button:hover svg path{
    fill: var(--color-global);
  }
  .branch_card{
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    overflow: scroll;
  }
  .compo-container{
    display: flex;
    flex-direction: column;
    justify-content: end;
    gap: 10px;
    height: 20vh;
    padding: 10px 10px 10px 10px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    margin-bottom: 0;
    object-fit: cover;
    width: 100%;
    box-shadow: inset 0px -50px 20px rgba(0, 0, 0, 0.779);
    transition: all .2s ease;
  }
  .compo-wrap{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .arrows{
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .arrows button{
    height: 250px;
    background-color: transparent;
  }

  .arrows button:hover{
    height: 250px;
    background-color: #0505052d;
  }
  .imageBanner{
    position: relative;
  }
  .compo-top{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .compo-text{
    color: white;
    font-family: TT Norms Medium;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
  }
  .divider_container{
    justify-content: center;
    display: flex;
    gap: 3px;
  }
  .compo_divider{
    width: 0;
    height: 0;
    border-radius: 100px;
    background: #FFFFFF80;
  }
  .service_tools{
    position: absolute;
    bottom: -4px;
    right: 0;
  }
  .service_ppl{
    position: absolute;
    bottom: 0;
    right: -25px;
  }
  .checkmark{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 18px;
    height: 18px;
    background: var(--color-main);
    border: 1px solid #F5F5F5;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
  }
  .checkmark:hover{
    background: #04C562;
    cursor: pointer;
  }

  .active-checkmark{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 18px;
    height: 18px;
    background: #04C562;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease
  }
  .notes_data{
    width: 100%;
    margin: 40px;
    display: flex;
    flex-direction: column;
  }
  .notes_info_bottom{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 0 0 10px 10px;
    width: 100%;
    background: var(--color-gray);
    padding: 8px 15px 25px 15px;
  }
  .data_info_bottom{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 0 0 10px 10px;
    width: 100%;
    background: var(--color-gray);
    padding: 8px 15px 25px 15px;
  }
  .divider_dotted_container{
    display: flex;
    position: relative;
    background: var(--color-gray);
    height: 12px;
    align-items: center;
  }
  .divider_dotted{
    width: 100%;
    height: 2px;
    border-bottom: 2px dashed #AFB6C1;
  }
  .divider_dotted_container::before{
    position: absolute;
    top: 0;
    width: 12px;
    height: 12px;
    left: 0;
    content: '';
    border-radius: 50%;
    background: var(--color-main);
    transform: translateX(-50%)
  }
  .divider_dotted_container::after{
    position: absolute;
    top: 0;
    width: 12px;
    height: 12px;
    right: 0;
    content: '';
    border-radius: 50%;
    background: var(--color-main);
      transform: translateX(50%)
  }
}
</style>