<template>
  <div class="calendar">
    <div class="calendar-block">
      
      <div class="head">
        Календарь
      </div>
      <div class="chooses">
        <div style="display: flex; gap: 5px;">
          <div class="choose-block">
            <SelectPage
            :selectAll="true"
              :options="filials"
              :placeholderdata="'Выбрать филиал'"
              @input="option => selectedBranch = option"
            />
          </div>
          <div class="choose-block">
            <SelectPage
                :selectAll="true"
                :options="employees.map(item => ({
                name: item.firstname + ' ' + item.secondname,
                id: item.id,
              }))"
          :placeholderdata="'Выбрать сотрудника'"
          @input="option => selectedEmployee = option"
          />
          </div>

          <SelectDate @picked="date => week = date" style="margin-left: 30px;"/>

          <div class="colors">
            <div class="color-block">
              <div class="color" style="background-color: #28CCF0;"></div>
              <p>Новые</p>
            </div>  
            <div class="color-block">
              <div class="color" style="background-color: #F7D37D;"></div>
              <p>Принят</p>
            </div>  
            <div class="color-block">
              <div class="color" style="background-color: #00A490;"></div>
              <p>Завершен</p>
            </div>  
            <div class="color-block">
              <div class="color" style="background-color: #F97F7F;"></div>
              <p>Отменен</p>
            </div>  
          </div>
        </div>

        <button class="reload">
            <svg style="rotate: 45deg;" width="15" height="15" viewBox="0 0 20 20" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.58578 10L4.29288 14.2929L5.70709 15.7071L10 11.4142L14.2929 15.7071L15.7071 14.2929L11.4142 10L15.7071 5.70712L14.2929 4.29291L10 8.58579L5.70712 4.29291L4.29291 5.70712L8.58578 10Z" fill="#FFFFFF"/>
            </svg>
            Создать запись
        </button>
      </div>
    </div>
    <div class="schedule">
      <div class="dates">
        <div class="day" v-for="day in daysOfWeek" :key="day.date">
          <h1 class="day-num">{{ day.date }}</h1>
          <p class="day-name">{{ day.dayOfWeek }}</p>
        </div>
      </div>
      <div class="empty-row-up"></div>
      <div class="table-container">
        <div class="table-full">
          <div class="time-table">
            <p>06:00</p>
            <p>07:00</p>
            <p>08:00</p>
            <p>09:00</p>
            <p>10:00</p>
            <p>11:00</p>
            <p>12:00</p>
            <p>13:00</p>
            <p>14:00</p>
            <p>15:00</p>
            <p>16:00</p>
            <p>17:00</p>
            <p>18:00</p>
            <p>19:00</p>
            <p>20:00</p>
            <p>21:00</p>
            <p>22:00</p>
          </div>
          <div class="table">
            <div v-for="timeSlot in timeSlots()" :key="timeSlot" class="table-row">
              <div
                class="cell"
                v-for="dayName in daysOfWeek"
                :key="dayName.date"
                :style="{
                  'background-color': dayName.dayOfWeek === 'Сб' || dayName.dayOfWeek === 'Вс' ? 'rgba(98, 102, 234, 0.02)' : 'white'
                }"
                @click="openAddEvent = !openAddEvent"
              >
                <div v-for="event in getFilteredEvents(dayName.dayOfWeek, timeSlot, dayName.date)" :key="event.id">
                  <CalendarEvent :eventData="event" :color="event.color"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="empty-row-down"></div>
    </div>
  
  </div>
</template>

<script>
import SelectPage from '../components/SelectPage.vue';
import SelectDate from '../components/SelectDate.vue';
import CalendarEvent from '../components/CalendarEvent.vue';
export default {
  components: { SelectPage, CalendarEvent ,SelectDate },
  data() {
    return {
      selectedMonth: new Date().getMonth(),
      selectedYear: new Date().getFullYear(),
      selectedDate: new Date().getDate(),
      events: [],
      selectedBranch: Object,
      selectedEmployee: Object,
      filials: [],
      employees: [],

      openAddEvent: false,
      daysOfWeek: [],
      week: '22.07.2024 - 28.07.2024',
    };
  },
  mounted(){
    this.getfilials();
    this.get_employee();

    this.getDaysOfWeek(this.week);
  },
  computed: {
    filteredEvents() {
      return this.events.reduce((acc, event) => {
        const date = new Date(event.time);
        const dayName = this.getDayName(date);
        const timeSlot = this.getTimeSlot(date);
        const dayNum = date.getDate();
        
        if (!acc[dayName]) {
          acc[dayName] = {};
        }
        
        if (!acc[dayName][timeSlot]) {
          acc[dayName][timeSlot] = {};
        }
        
        if (!acc[dayName][timeSlot][dayNum]) {
          acc[dayName][timeSlot][dayNum] = [];
        }
        
        acc[dayName][timeSlot][dayNum].push(event);
        
        return acc;
      }, {});
    }
  },
  watch: {
    week() {
      this.getDaysOfWeek(this.week);
    },
    selectedBranch() {
      this.get_request(this.selectedBranch.id, this.selectedEmployee.id);
    },
    selectedEmployee() {
      this.get_request(this.selectedBranch.id, this.selectedEmployee.id);
    },
  },
  methods: {

    getDaysOfWeek(week) {
      if (!week) {
        return [];
      }

      // Разделяем строки дат
      const [startStr, endStr] = week.split(' - ');
      const startDateParts = startStr.split('.');
      const endDateParts = endStr.split('.');

      // Проверяем формат дат
      if (startDateParts.length !== 3 || endDateParts.length !== 3) {
        console.error("Invalid date format");
        return [];
      }

      // Создаем объекты дат
      const startDate = new Date(startDateParts[2], startDateParts[1] - 1, startDateParts[0]);
      const endDate = new Date(endDateParts[2], endDateParts[1] - 1, endDateParts[0]);

      // Проверяем корректность дат
      if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        console.error("Invalid date format");
        return [];
      }

      if (startDate > endDate) {
        console.error("Start date is after end date");
        return [];
      }

      // Сдвигаем startDate до понедельника
      const dayOfWeek = startDate.getDay();
      const offset = (dayOfWeek === 0 ? 6 : dayOfWeek - 1); // Воскресенье = 0, Пн = 1
      const mondayStartDate = new Date(startDate);
      mondayStartDate.setDate(startDate.getDate() - offset);

      const daysOfWeek = [];
      const dayNames = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];

      // Заполняем дни недели
      for (let d = new Date(mondayStartDate); d <= endDate; d.setDate(d.getDate() + 1)) {
        daysOfWeek.push({
          date: d.getDate(),
          dayOfWeek: dayNames[d.getDay()]
        });

        // Ограничиваем цикл семью итерациями
        if (daysOfWeek.length >= 7) {
          break;
        }
      }

      console.log(daysOfWeek);
      this.daysOfWeek = daysOfWeek
    },


    getFilteredEvents(dayOfWeek, timeSlot, dayNum) {
      return (this.filteredEvents[dayOfWeek] && this.filteredEvents[dayOfWeek][timeSlot] && this.filteredEvents[dayOfWeek][timeSlot][dayNum]) || [];
    },
    timeSlots() {
      const slots = [];
      for (let i = 6; i <= 22; i++) {
        const startTime = `${i < 10 ? '0' : ''}${i}:00`;
        const endTime = `${i + 1 < 10 ? '0' : ''}${i + 1}:00`;
        slots.push(`${startTime} - ${endTime}`);
      }
      return slots;
    },
    getDayName(date) {
      const dayNames = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
      return dayNames[date.getDay()];
    },
    
    getTimeSlot(date) {
      const hours = date.getHours();
      const startTime = `${hours < 10 ? '0' : ''}${hours}:00`;
      const endTime = `${hours + 1 < 10 ? '0' : ''}${hours + 1}:00`;
      return `${startTime} - ${endTime}`;
    },

    async get_request(filial_id, employee_id){
      try {
        const response = await this.$axios.get(`/api/get_request/?filial=${filial_id}&employee=${employee_id}&project=${this.$store.state.activeProjectId}`);
        let applications = response.data;
        console.log(applications)
        this.events = applications;

      } catch (error) {
        console.error('Ошибка при получении данных о заявках:', error);
      }
    },

    async get_employee() {
      try {
        const user_id = this.$store.state.registrationData.user_id;
        const response = await this.$axios.get(`/api/get_employees/?user_id=${user_id}&project=${this.$store.state.activeProjectId}`);
        this.employees = response.data;
        console.log(this.employees);
      } catch (error) {
        console.error('Ошибка при получении данных о сотрудниках:', error);
      }
    },

    async getfilials(){
      await this.$axios.get(`/api/get_branch/?variable=${this.$store.state.registrationData.user_id}&project=${this.$store.state.activeProjectId}`)
      .then(response => {
          this.filials = response.data;
          this.filials.reverse();
          console.log(response);
      })
      .catch(error => {
          console.error('Ошибка при получении данных о пользователе:', error);
      });
    },
  },
};
</script>

<style scoped>
.modal-subtext{
    font-family: TT Norms Medium;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #AFB6C1;
    margin: 0;
    margin-top: 10px;
  }
.btn_container{
    margin-top: 30px;
    display: flex;
    gap: 10px;
  }
  span{
    color: #7D838C;
  }
.delete{
    color: #F97F7F;
    background-color: rgba(249, 127, 127, 0.2);
  }
  .delete:hover{
    background: #F97F7F;
    color: #FFFFFF;
  }

  .exit{
    color: #535C69;
    border: 1px solid #DDE1E5;
    background: #FFFFFF;  
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  }
  .exit:hover{
    border: 1px solid #AFB6C1;
    background: #F5F5F5;
  }
.modal-show{
  width: auto;
  height: auto;
  position: absolute;
  padding: 40px;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  transition: all .1s ease;
}
.modal-hide{
  width: auto;
  height: auto;
  position: absolute;
  padding: 40px;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  transition: all .1s ease;
}
.overlay-show {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Задний фон с прозрачностью 60% */
  z-index: 98;
  opacity: 1;
  visibility: visible;
  transition: all .1s ease;
}
.overlay-hide {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Задний фон с прозрачностью 60% */
  z-index: 98;
  opacity: 0;
  visibility: hidden;
  transition: all .1s ease;
}
.text-header{
  font-family: 'TT Norms Medium';
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0em;
  color: #535C69;
  margin: 0;
  text-align: left;
}
.text-subheader{
  font-family: 'TT Norms Medium';
  font-size: 13px;
  line-height: 12px;
  letter-spacing: 0em;
  color: #AFB6C1;
  margin: 0;
  margin-bottom: 10px;
  text-align: left;
}
.colors, .color-block{
  display: flex;
  align-items: center;
}
.colors{
  margin-left: 30px;
  gap: 30px;
}
.color{
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.color-block{
  gap: 5px;
}
.color-block p{
  margin: 0;
  text-align: left;
  line-height: 36px;
  font-family: TT Norms Medium;
  font-size: 13px;
  color: #535C69;
}
.reload{
  background-color:   #6266EA;
  color: #FFFFFF;
  transition: all 0.2s ease;
}
.reload:hover{
  background-color: #464AD9;
}
.choose-block{
  width: 200px;
  height: 36px;
}
.no-title ::v-deep .p-datepicker-title {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}
.no-title ::v-deep .p-datepicker-header{
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-title ::v-deep .p-datepicker-header button{
  background: transparent;
  color: #535C69;
}
.calendar{
  width: 100%;
}
.chooses{
  background-color: transparent;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  border-radius: 5px;
}

.head{
  font-family: TT Norms Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 23.6px;
  text-align: left;
  color: #535C69;
}
.header{
  font-family: TT Norms Medium;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0em;
  color: #535C69;
  margin: 0;
  margin-bottom: 5px;
  text-align: left;
}
.monthes{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.month{
  font-family: TT Norms Medium;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0em;
  color: #535C69;
  margin: 0;
  margin-bottom: 1px;
  text-align: left;
}

.dates{
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.day{
  max-width: 200px;
  display: flex;
  align-items: start;
  gap: 5px;
}

.day-num{
  font-family: TT Norms Medium;
  font-size: 28px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  color: #535C69;
}

.day-name{
  font-family: TT Norms Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: #AFB6C1;
}

.schedule{
  width: 100%;
}

.dates{
  margin-left: 50px;
}

.date-block{
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

.table{
  display: grid;
  grid-template-rows: repeat(1, 70px);
  width: 100%;
}

.table-row{
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
  height: 70px;
}

/* .table-row:nth-child(even){
  filter: brightness(97%);
} */

.table-container{
  display: flex;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 70vh;
}

.table-full{
  width: 100%;
  display: flex;
}

.time-table{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(16, 1fr);
}

.time-table p{
  font-family: TT Norms Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 10px;
  text-align: center;
  color: #AFB6C1;
  height: 70px;
  margin: 0;
  width: 50px;
}
.empty-row-up{
  margin-left: 50px;
  border: 1px solid #e7eaee;
  height: 20px;
  border-radius: 5px 5px 0 0;
  background-color: white;
}

.empty-row-down{
  margin-left: 50px;
  border: 1px solid #e7eaee;
  height: 20px;
  border-radius: 0 0 5px 5px;
  background-color: white;
}

.table-row > * {
  border: 1px solid #e7eaee;
  width: 100%;
  background-color: white;
  cursor: pointer;
}

.cell{
  align-items: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10%, 1fr)); /* Колонки с минимальной шириной 50px и максимальной шириной 1fr */
  grid-column-gap: 5px;
  grid-auto-rows: min-content; /* Высота строк подстраивается под содержимое */
  height: 70px;
  min-width: 100%;
  width: fit-content;
  max-width: 120px;
  padding: 5px;
  transition: all .2s ease;
}


.cell:hover{
  border: 1px solid #b2b5b8;
}

.cell > * {
  /* Если нужно управлять отступами и выравниванием дочерних элементов */
  margin: 0;
  display: inline-block; /* Или flex, если требуется */
}


/* @media (max-width: 1641px){
  .calendar-block{
    display: none;
  }
} */
</style>