<template>
  <div class="main">
    <div class="transition">
      <router-link to="/dashboard/clients" class="employesss-link">Клиенты</router-link>
      <div class="arrow-container">
        <img src="../../static/img/arrow-right.png" alt="Стрелка вправо" class="arrow-icon">
      </div>
      <p class="creation_text">Добавить клиента</p>
    </div>

    <div class="settings">
      <div class="forms">
        <div class="name">
          <div class="name-container">
            <label for="userName">Имя</label>
            <div class="input_container_top">
              <input type="text" placeholder="Введите имя" v-model="firstname" :class="{ 'input-error': v$.firstname.$error }">
            </div>
          </div>
        </div>
        <div class="second_name">
          <div class="name-container">
            <label for="">Фамилия</label>
            <div class="input_container_top">
              <input type="text" placeholder="Введите фамилию" v-model="secondname" :class="{ 'input-error': v$.secondname.$error }">
            </div>
          </div>
        </div>
        <div class="divider"></div>
        <div class="photo">
          <div class="photo-container">
            <label for="userPhoto">Фотография</label>
            <div class="input_container">
              <label class="custom-file-upload" :class="{'custom-file-upload-error' : v$.avatar.$error}" v-if="!fileNameVariable">
                <input type="file" accept="image/*" @change="handleFileUpload($event)" :class="{ 'custom-file-upload-error': v$.fileNameVariable.$error }"/>Нажмите, чтобы добавить 
              </label>
              <label style="color: #535C69;" class="custom-file-upload" :class="{'custom-file-upload-error' : v$.avatar.$error}" v-else>
                <input type="file" accept="image/*" @change="handleFileUpload($event)"  :class="{ 'custom-file-upload-error': v$.fileNameVariable.$error }"/>{{ fileNameVariable }}
              </label>
            </div>
            <p class="photo-info">до 5 МБ, PNG, JPG, JPEG. Для замены - загрузите заново</p>
          </div>
        </div>
        <div class="divider"></div>
        <div class="email">
          <div class="email-container">
            <label for="userMail">Email</label>
            <div class="input_wrapper">
              <input type="email" placeholder="client@mail.ru" class="input_warning" v-model="email" :class="{ 'input-error': v$.email.$error }">
            </div>
            <!-- <p class="email-header">Клиент еще не подтвердил свою почту</p> -->
          </div>
        </div>
        <div class="divider"></div>
        <div class="phone">
          <div class="phone-container">
            <label for="userPhone">Телефон</label>
            <div class="input_wrapper">
              <InputMaskComponent autocomplete="new-password" @input="handleInput" id="userphone" v-model="value" :mask="computedMask" :placeholder="computedPlaceholder"  :class="{ 'input-error': v$.value.$error }"/>
            </div>
            <!-- <p class="email-header">Клиент еще не подтвердил свой номер телефона</p> -->
          </div>
        </div>
        <div class="divider"></div>
        <div class="change">
          <button type="button" class="button-change" @click="create_client()">Добавить клиента</button>
          <button type="button" class="button-back" @click="handleCancel">Вернуться назад</button>
        </div>
      </div>
    </div>
    <MessageAlert :message="alertMessage" :color="alertColor"/>
    <VerifPage
      v-if="showModal"
      @confirm="toggleModal"
      @cancel="cancel"
    />
  </div>
</template>

<script>
import MessageAlert from "../components/MessageAlert.vue";
import VerifPage from '../components/VerifPage.vue';


import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { email } from '@vuelidate/validators'

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  components: { MessageAlert, VerifPage},
  data() {
    return {
      initialServiceName: '',
      showModal: false,
      firstname: '',
      secondname: '',
      email: '',
      value: '',
      avatar: '',
      fileNameVariable:'',
      alertMessage: '',
      alertColor: '',
    }
  },
  validations () {
    return {
      firstname: { required },
      secondname: { required },
      email: { required, email },
      value: { required },
      fileNameVariable: { required },
      avatar: { required },
    }
  },
  computed:{
    computedMask() {
      if (this.selectedCountry) {
        const countryCode = this.selectedCountry.code;
        if (countryCode === '+375' || countryCode === '+380') {
          return `${countryCode} (99) 999-99-99`;
        } else {
          return `${countryCode} (999) 999-99-99`;
        }
      } else {
        return '+7 (999) 999-99-99'; // Default mask
      }
    },
    computedPlaceholder() {
      return this.selectedCountry ? this.selectedCountry.code + ' |' : '+7 |';
    },
  },

  methods:{
    handleCancel() {
        if (
          this.firstname !== '' ||
          this.secondname !== '' ||
          this.email !== '' ||
          this.value !== '' ||
          this.avatar !== ''
        ) {
          this.toggleModal();
        } else {
          this.cancel();
        }
      },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    cancel() {
      this.$router.go(-1);
    },
    handleInput() {
      const countryCode = this.selectedCountry ? this.selectedCountry.code : '';
      this.value = countryCode + ' ' + this.value.replace(/^\s*\+\d\s*\|\s*/, '');
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.avatar = file;
      const fileName = file.name;
      if (fileName.length > 20) {
        this.fileNameVariable = fileName.slice(0, 20) + '...' + fileName.slice(-4);
      }else{
        this.fileNameVariable = fileName.slice(0, 20);
      }

      this.readCoverDataUrl(); 
    },

    readCoverDataUrl() {
      if (!this.avatar) return;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.coverDataUrl = e.target.result;
      };
      reader.readAsDataURL(this.avatar);
    },
    async create_client() {
      const fieldsToValidate = [
        'firstname',
        'secondname',
        'email',
        'avatar',
        'value',
      ];

      fieldsToValidate.forEach(field => {
        this.v$[field].$touch();
      });

      const hasErrors = fieldsToValidate.some(field => this.v$[field].$error);
      this.alertMessage = '';
      if (hasErrors){
        this.alertMessage = 'Пожалуйста, заполните выделенные поля';
        this.alertColor = '#F97F7F';
      }else{
        const formData = new FormData();
        formData.append('firstname', this.firstname);
        formData.append('secondname', this.secondname);
        formData.append('mail', this.email);
        formData.append('phone', this.value);
        formData.append('avatar', this.avatar);

        let now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');

        let formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;

        formData.append('date', formattedDate);
        formData.append('project', this.$store.state.activeProjectId);
        await this.$axios.post('/api/create_client/', formData, {withCredentials: true})
          .then(response => {
            console.log(response)
            this.alertMessage = 'Клиент успешно добавлен';
            this.alertColor = '#0BB6A1';
            setTimeout(() => {
              this.$router.push('/dashboard/clients')
            }, 500);
            
          })
          .catch(error => {
            this.alertMessage = 'Произошла ошибка' + error;
            this.alertColor = '#F97F7F';
          });
      }
    },
  }

}
</script>

<style scoped>
.custom-file-upload-error{
    border: 1px solid #F97F7F !important;
    border-radius: 3px;
  }
.main{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.transition {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.employesss-link {
  font-family: 'TT Norms Medium';
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  text-decoration: none;
  color: #AFB6C1;
}
.creation_text {
  color: #535C69;
  font-family: 'TT Norms Medium';
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
}
.arrow-container {
  display: flex;
  align-items: center;
}

.arrow-icon {
  height: 50%;
}
.inputFile-empty{
  font-family: TT Norms;
  color: #D2D8DE;
}

.inputFile-file{
  font-family: TT Norms;
  color: #535C69;
}
.settings {
  text-align: center;
  width: fit-content;
  height: auto;
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 5px;
}

.name-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

label {
  margin-bottom: 5px;
  font-family: TT Norms Medium;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0em;
}

.input_container {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.input_container_mail {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.input_container_phone {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.input_container_top {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

input {
  margin-bottom: 0;
  width: 100%;
}

.email-btn{
  display: flex;
  flex-direction: row;
  gap: 10px;
}

p{
  text-align: left;
  margin: 10px 0;
  width: 450px;
  font-family: TT Norms Medium;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0em;
  color: rgba(83, 92, 105, 0.7);
}

.email-header{
  font-family: TT Norms Medium;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.16px;
  text-align: left;
  color: #7D838C;
  margin-top: 5px;
  margin-bottom: 0;
}
.phone-btn{
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.button-save {
  background-color: #EFEFFF;
  color: #6266EA;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}
.button-save:hover {
  background-color: #DBDDFF;
  color: #6266EA;
}
.button-change {
  background-color: #EFEFFF;
  color: #6266EA;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.button-change:hover {
  background-color: #DBDDFF;
  color: #6266EA;
}
.divider {
  border-bottom: 1px solid rgba(50, 56, 74, 0.1); 
  width: auto;
  margin: 20px 0;
}
.custom-file-upload {
  width: 100%;
  height: 36px;
  display: flex;
  padding: 8px 10px;
  cursor: pointer;
  background-color: #F3F5F6;
  color: #D2D8DE;
  align-items: center;
  margin-bottom: 0;
  font-weight: 500;
  background-image: url(../../static/img/paperclip.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  border-radius: 3px;
}

.custom-file-upload input[type="file"] {
  display: none;
}
.password{
  color: #7D838C;
  font-family: TT Norms Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
}
h2{
  font-family: TT Norms Medium;
  margin: 0;
  padding-bottom: 20px;
  color: #535C69;
}
.photo-info{
  color: #D2D8DE;
  font-family: TT Norms Medium;
  font-size: 12px;
  line-height: 14.16px;
  text-align: left;
  
  margin: 5px 0;
}
input::placeholder {
  font-family: 'TT Norms Medium';
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0em;
  color: #D2D8DE;
}
.name{
  margin-bottom: 20px;
}
.change{
  justify-content: end;
  display: flex;
  gap: 10px;
}
.button-back{
  background: #FFFFFF;
  border: 1px solid #DDE1E5;
  color: #535C69;
}
.button-back:hover{
  color: #6266EA;
}
.input_wrapper {
  position: relative;
  width: 100%; /* Устанавливаем ширину контейнера инпута */
}

.input_warning {
  width: 100%;
  padding-right: 30px;
  transition: all 0.2s ease;
}

.svg_warning_wrapper {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 9;
  height: 14px;
}

.svg_warning {
  pointer-events: none;
}

.svg_warning_wrapper:hover::after {
  content: "Email адрес не подтвержден";
  position: absolute;
  bottom: -40px; /* Позиционируем сообщение под иконкой */
  left: 50%;
  transform: translateX(-50%);
  background-color: #FFFFFF;
  color: #7D838C;
  z-index: 15;
  padding: 5px 10px; /* Добавляем отступы для текста */
  border-radius: 5px;
  font-family: 'TT Norms Medium', sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  box-shadow: 0px 2px 8px 0px #7D879066;
  display: flex;
  max-width: 202px;
  height: auto;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.phone-container .svg_warning_wrapper:hover::after {
  content: "Телефон не подтвержден";
}
</style>