<template>
  <div class="main" :id="colortheme">


    <div class="compo-container" :style="{ backgroundImage: `url(${cover})`, backgroundSize: 'cover' }">
      <div class="compo-wrap">
        <div class="compo-top">
        <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5 4V3.5C5 1.84315 6.34315 0.5 8 0.5H12C13.6569 0.5 15 1.84315 15 3.5V4H18C19.1046 4 20 4.89543 20 6V16C20 17.1046 19.1046 18 18 18H2C0.895431 18 0 17.1046 0 16V6C0 4.89543 0.895431 4 2 4H5ZM7 3.5C7 2.94772 7.44772 2.5 8 2.5H12C12.5523 2.5 13 2.94772 13 3.5V4H7V3.5ZM10 10C10.8284 10 11.5 9.32843 11.5 8.5C11.5 7.67157 10.8284 7 10 7C9.17157 7 8.5 7.67157 8.5 8.5C8.5 9.32843 9.17157 10 10 10Z" fill="white"/>
        </svg>
        <p class="compo-text">{{ activeFilial.name || 'Пожалуйста, выбирите филиал' }}</p>
      </div>
        <div class="compo-top" v-if="activeFilial.name">
        <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M2 7.98124C2 3.52416 5.53247 0 10 0C14.3636 0 18 3.62781 18 7.98124C18 13.3347 10 20 10 20C10 20 2 13.4383 2 7.98124ZM10 11C11.6569 11 13 9.65685 13 8C13 6.34315 11.6569 5 10 5C8.34315 5 7 6.34315 7 8C7 9.65685 8.34315 11 10 11Z" fill="white"/>
        </svg>
        <p class="compo-text">{{ activeFilial.address }}</p>
        </div>
      </div>
      <div class="divider_container">
        <div v-for="i in images" :key="i.id" class="compo_divider"></div>
      </div>
    </div>

    
    <div class="favor" v-if="currentPage === 'favor'">
      <div class="search">
        <input type="text" placeholder="Введите название улицы или филиала">
      </div>
      <div class="favor_card_container">
        <div class="favor_card" v-for="u in uslugi" :key="u.id">
          <div class="favor_compo-wrapper">
            <img :src=" u.serviceCover" alt="" class="card-avatar">
            <p class="favor_text">{{u.name}}</p>
          </div>
          <div class="compo-wrapper-tariff">
            <div class="tariff">
              <div class="tariff-item">
                <p class="tariff_text">{{u.cost}}</p>
              </div>
              <div class="dot"></div>
              <div class="tariff-item">
                <p class="tariff_text">{{u.time}}</p>
              </div>
            </div>
            <button class="btn-wrapper">Выбрать</button>
          </div>
        </div>
      </div>

      <div class="favor_bottom">
        <div class="card_next">
          <div class="card_next_container">
            <p class="card_next_text">Выбор услуги</p>
            <div class="divider_step_container" v-if="step === 1">
              <div class="divider_step_one"></div>
              <div class="divider_step_two"></div>
            </div>
            <div class="divider_step_container" v-else>
              <div class="employees_divider_step_one"></div>
              <div class="employees_divider_step_two"></div>
            </div>
            <p class="card_next_subtext">Шаг {{ step }} из 4</p>
          </div>
          <div class="card_btn">
            <button v-if="size === 'desktop'" class="card_back_btn" >Назад</button>
            <button v-else class="card_back_btn-arrow">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.3999 6.96667L4.8999 3.5V6.3H13.2999V7.7H4.8999V10.5L1.3999 6.96667Z" fill="var(--color-text)"/>
                </svg>
            </button>
            <button :disabled="true" class="card_next_btn-active">{{size === 'desktop' ? 'Продолжить' : 'Далее'}}</button>
          </div>
        </div> 
      </div>

    </div> 
  </div>
</template>

<script>
export default {
  props: ['theme', 'MainColor', 'WidgetColor', 'BakcgroundColor', 'TextColor', 'Filials_ids', 'cover'],
  data() {
    return {
      size: 'mobile',

      Filials: [],
      uslugi: [],
      employees:[],


      Mark: false,
      currentPage: 'favor',
      selectedCountry: null,
      value: '7 ', // Начальное значение для InputMaskComponent
      countries: [
        { name: '🇷🇺', code: '+7' },
        { name: '🇧🇾', code: '+375' },
        { name: '🇰🇿', code: '+7' },
        { name: '🇺🇦', code: '+380' },
      ],
      images: [
        "" + '/media/service_covers/143976-abstraktnoe_iskusstvo-kraska-krasnyj_cvet-art-sinij-3840x2160_m2WC2Ts.jpg',
        "" + '/media/service_covers/kraska_razvody_rozovyj_136631_3840x2160_93Z7A8z.jpg',
      ],
      currentImageIndex: 0,

      activeFilial: {},
      activeUslugi: false,
      activePersonal: false,

      selectedUslugi: [],
      selectedEmployees: [],

      selectedDay: [],
      selectedTime: '',

      colortheme: '',
    };
  },
  created() {
    this.setSize(window.innerWidth);
    window.addEventListener('resize', this.onResize);
  },
  unmounted() {
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
    computedMask() {
      if (this.selectedCountry) {
        const countryCode = this.selectedCountry.code;
        if (countryCode === '+375' || countryCode === '+380') {
          return `${countryCode} (99) 999-99-99`;
        } else {
          return `${countryCode} (999) 999-99-99`;
        }
      } else {
        return '+7 (999) 999-99-99'; // Default mask
      }
    },
    imageUrl() {
      return this.images[this.currentImageIndex];
    },
    computedPlaceholder() {
      return this.selectedCountry ? this.selectedCountry.code + ' |' : '+7 |';
    },
    selectedDays() {
      if (!this.selectedEmployees[0] || !this.selectedEmployees[0].days) {
        return [];
      }
      return this.selectedEmployees[0].days.split(",");
    },
  },
  watch: {
    selectedCountry(newCountry) {
      if (newCountry) {
        this.value = newCountry.code + ' ' + this.value.replace(/^\s*\+\d\s*\|\s*/, '');
      }
    },
    theme(){
      this.updateColors(),
      this.filialsAddToArray()
    },
    MainColor(){
      this.updateColors(),
      this.filialsAddToArray()
    },
    WidgetColor(){
      this.updateColors(),
      this.filialsAddToArray()
    },
    BakcgroundColor(){
      this.updateColors(),
      this.filialsAddToArray()
    },
    TextColor(){
      this.updateColors(),
      this.filialsAddToArray()
    },
    Filials_ids(){
      this.filialsAddToArray()
    }
    // cover(){
    //   this.readCoverDataUrl(),
    //   this.filialsAddToArray()
    // }
  },
  mounted() {
    this.startImageSlider();
    this.updateColors();
    this.filialsAddToArray();
    this.get_uslugi();
  },
  methods: {
    async filialsAddToArray(){
      while (this.Filials.length > 0){
        this.Filials.pop(); 
      }
      if (this.Filials_ids) {
        for (let index of this.Filials_ids) {
          let f = await this.getfilial(index);
          this.Filials.push(f);
          console.log('Filails:' + this.Filials)
        }
      }
      
    },

    async getfilial(i) {
        try {
            const id = i;
            const response = await this.$axios.get(`/api/get_filialbyid/?variable=${id}`);
            console.log(response)
            return response.data;
        } catch (error) {
            console.error('Ошибка при получении данных о Филиале:', error);
            throw error; // throw error, чтобы предоставить возможность обработки ошибки вверх по стеку вызовов
        }
    },
    async get_uslugi(){
      try {
        const response = await this.$axios.get(`/api/uslugi/?variable=${this.$store.state.registrationData.user_id}&project=${this.$store.state.activeProjectId}`, {withCredentials: true});
        this.uslugi = response.data; // Присваиваем полученные данные массиву uslugi
      } catch (error) {
        console.error('Error fetching uslugi:', error);
      }
    },

    onResize(event) {
      this.setSize(event.target.innerWidth);
    },
    setSize(width) {
      if (width > 768) {
        this.size = 'mobile';
      } else {
        this.size = 'mobile';
      }
    },
    
    updateColors() {
      if (this.theme) {
        this.colortheme = 'darkmode';
      } else {
        this.colortheme = 'lightmode';
      }

      if (this.MainColor) {
        this.$el.style.setProperty('--cm', this.MainColor);

        const transparentColor = this.changeTransparency(this.MainColor, 0.2);
        this.$el.style.setProperty('--cmlight', transparentColor);
      }
      if (this.WidgetColor) {
        this.$el.style.setProperty('--cw', this.WidgetColor);
      }
      if (this.BakcgroundColor) {
        this.$el.style.setProperty('--cb', this.BakcgroundColor);
      }
      if (this.TextColor) {
        this.$el.style.setProperty('--ct', this.TextColor);
      }
    },
    changeTransparency(hex, alpha) {
      const isValidHex = /^#([0-9A-Fa-f]{3}){1,2}$/i.test(hex);
      if (!isValidHex) {
        console.error('Invalid HEX color:', hex);
        return hex;
      }

      hex = hex.replace(/^#/, '');
      const bigint = parseInt(hex, 16);

      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;

      return `rgba(${r},${g},${b},${alpha})`;
    },

    async activateFilial(filialData) {
        try {
            this.activeFilial = await this.getfilial(filialData.id);
            while (this.uslugi.length > 0){
              this.uslugi.pop(); 
            }
            this.uslugi = await this.getuslugi_by_specialist(0, filialData.id);

            while (this.employees.length > 0){
              this.employees.pop(); 
            }
            this.employees = await this.getspecialist_by_usluga(0, filialData.id);
            console.log(this.employees)
        } catch (error) {
            console.error('Ошибка при активации Филиала:', error);
        }
    },

    async UslugaSelected(data){
      while(this.selectedUslugi.length > 0) {
        this.selectedUslugi.pop();
      }
      this.selectedUslugi.push(data);
      while (this.employees.length > 0){
          this.employees.pop(); 
      }
      this.employees = await this.getspecialist_by_usluga(data.id, this.activeFilial.id);
      console.log(this.employees)
    },

    UslugaDeleting(data) {
      let indexToRemove = this.selectedUslugi.indexOf(data);
        if (indexToRemove !== -1) {
          this.selectedUslugi.splice(indexToRemove, 1);
        }
    },

    async EmployeeSelected(data){
      while(this.selectedEmployees.length > 0) {
        this.selectedEmployees.pop();
      }
      this.selectedEmployees.push(data);
      while (this.uslugi.length > 0){
        this.uslugi.pop(); 
      }
      this.uslugi = await this.getuslugi_by_specialist(data.id, this.activeFilial.id);
    },

    EmployeeDeleting(data) {
      let indexToRemove = this.selectedEmployees.indexOf(data);
        if (indexToRemove !== -1) {
          this.selectedEmployees.splice(indexToRemove, 1);
        }
    },
    
    handleInput() {
      const countryCode = this.selectedCountry ? this.selectedCountry.code : '';
      this.value = countryCode + ' ' + this.value.replace(/^\s*\+\d\s*\|\s*/, '');
    },
    showBranch() {
      this.currentPage = 'branch';
    },
    showFavor() {
      this.currentPage = 'favor';
    },
    showChoice() {
      if (this.activeFilial.name) {
        this.currentPage = 'choice';
      }
    },
    showEmployees() {
      this.currentPage = 'employees';
    },
    showCalendar() {
      console.log(this.selectedEmployees[0])
      this.currentPage = 'calendar';
    },
    showData() {
      if (this.selectedTime.length>0) {
        this.currentPage = 'data';
      }
    },
    showNotes() {
      if (this.clientFisrtName && this.clientSecondName && this.Mark && this.value.length > 6) {
        this.currentPage = 'notes';
      }
      
    },
    startImageSlider() {
      // Устанавливаем интервал для переключения изображений каждые 5 секунд
      setInterval(() => {
        this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
      }, 5000);
      console.log(this.imageUrl)
    },
    setActiveProject(projectId) {
      this.$store.commit('setActiveProject', projectId);
    },
    deactivateProject() {
      this.$store.commit('deactivateProject');
    },
    changeColor(index) {
      // Сброс выбора всех элементов
      this.isClicked = this.isClicked.map((value, i) => i === index);
    }
  }
};
</script>

<style scoped>
#lightmode {
  --color-main: var(--cw, white);
  --color-gray: var(--cb, #FAFAFA);
  --color-btnmain: var(--cmlight, #EBEDFF);
  --color-global: var(--cm, #6266EA);
  --color-text: var(--ct, #535C69);
  --color-shadow: rgb(216, 216, 216);
}

#darkmode{
  --color-main: var(--cw, #1A1B27);
  --color-gray: var(--cb, #222433);
  --color-btnmain: var(--cmlight, rgba(255, 194, 90, 0.1));
  --color-global: var(--cm, #FFCF7D);
  --color-text: var(--ct, #F5F5F5);
  --color-shadow: rgb(21, 20, 29);
}

.card_back_btn-arrow{
  border: 1px solid var(--color-text);
  background: var(--color-main);
  border-radius: 100px;
  width: 30px;
  height: 30px;
  padding: 0;
}

.card-avatar{
  width: 54px;
  height: 54px;
  object-fit: cover;
  border-radius: 5px;
}

.card_back_btn-arrow:hover{
  border: 1px solid var(--color-global);
}

.card_back_btn-arrow:hover svg path{
  fill: var(--color-global);
}


.choice-banner{
  display: none;
}

.p-dropdown{
  background: var(--color-gray);
}
.delete-btn{
    width: 30px;
    height: 30px;
    background-color: rgba(249, 144, 144, 0.1);
    border-radius: 50%;
    padding: 0;
  }

  .delete-btn:hover{
    cursor: pointer;
    width: 30px;
    height: 30px;
    background-color: rgba(241, 129, 129, 0.391);
    border-radius: 50%;
    padding: 0;
  }
.main{
  width: 600px;
  border-radius: 20px;
  background: transparent;
}
.choice{
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px 40px;
  border-radius: 25px;
  height: 427px;
  background: var(--color-main); 
}
.branch{
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 30px 40px;
  border-radius: 25px;
  height: 427px;
  background: var(--color-main);
}
.search input{
  background-color: var(--color-main);
  color:#D2D8DE;
  margin: 0;
  width: 100%;
  border: 1px solid #D2D8DE;
}

.search input:focus{
  outline: none;
  border: 1px solid var(--color-global);
}
.search input{
  background-image: url(../../static/img/search.svg);
  background-repeat: no-repeat;
  padding-left: 35px;
  background-position: 15px;
}
.card{
  background-color: var(--color-gray);
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}

.card:hover .card_name{
  color: var(--color-global);
}

.card:hover .card_address, .card:hover .card_time{
  color: var(--color-text);
}

svg path{
  transition: all .2s ease;
}

.card:hover svg path{
  fill: var(--color-text);
}

.card_container{
  width: 245px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.card_address_container{
  display: flex;
  align-items: center;
  gap: 5px;
}
.card_time_container{
  display: flex;
  align-items: center;
  gap: 5px;
}
.card_name{
  font-family: TT Norms Medium;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
  margin: 0;
  transition: all .2s ease;
}
.card_address{
  font-family: TT Norms Medium;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #AFB6C1;
  margin: 0;
  transition: all .2s ease;
}
.card_time{
  font-family: TT Norms Medium;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  color: #AFB6C1;
  margin: 0;
  transition: all .2s ease;
}
.card_next_container{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.card_next{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card_next_text{
  font-family: TT Norms Medium;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
  margin: 0;
}
.divider{
  width: 200px;
  height: 3px;
  border-radius: 15px;
  border: none;
  background: var(--color-gray);
  margin: 0;
}
.card_next_subtext{
  font-family: TT Norms Medium;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
  margin: 0;
}
.card_number{
  text-decoration: underline;
  color: var(--color-global);
}
.card_next_btn-disabled{
  border-radius: 3px;
  cursor: not-allowed;
  background: var(--color-btnmain);
  color: var(--color-global);
  filter: brightness(50%);
}

.card_next_btn-active{
  border-radius: 3px;
  cursor: pointer;
  background: var(--color-global);
  color: var(--color-main);
  filter: brightness(100%);
}

.card_next_btn-active:hover{
  cursor: pointer;
  background: var(--color-btnmain);
  color: var(--color-global);
}

.choice_overview{
  display: flex;
  justify-content: space-between;
  height: 90px;
  background: var(--color-gray);
  padding: 20px;
  border-radius: 15px;
}
.choice_rate_container{
  display: flex;
  align-items: center;
  gap: 15px;
}
.choice_rate{
  font-family: TT Norms Medium;
  font-size: 42px;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--color-text);
}
.choice_star_text{
  font-family: TT Norms Medium;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
  text-decoration: underline;
  cursor: pointer;
}
p{
  margin: 0;
}
.stars{
  display: flex;
  gap: 7px;
}
.choice_star_container{
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.choice_info_container{
  display: flex;
  align-items: center;
  gap: 10px;
}
.choice_info_text{
  font-family: TT Norms Medium;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
}
.service{
  display: flex;
  gap: 20px;
}
.service_container{
  position: relative;
  padding: 20px;
  width: 250px;
  height: 170px;
  background: var(--color-gray);
  border-radius: 15px;
  cursor: pointer;
}

.service_container:hover .service_container_text{
  color: var(--color-global);
}

.service_container_text{
  font-family: TT Norms Medium;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
  transition: all .2s ease;
}
.favor{
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 40px;
  height: 427px;
  background: var(--color-main);
  border-radius: 25px;
}
.favor_card{
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: var(--color-gray);
  border-radius: 10px;
  padding: 10px;
  width: 255px;
}
.favor_compo-wrapper{
  display: flex;
  align-items: center;
  gap: 10px;
}
.favor_text{
  text-align: left;
  font-family: TT Norms Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: var(--color-text);  
}
.compo-wrapper-tariff{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tariff{
  display: flex;
  align-items: center;
  gap: 5px;
}
.tariff_text{
  font-family: TT Norms Medium;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
}
.dot {
  display: flex;
  align-items: center;
  height: 2px;
  width: 2px;
  background: var(--color-global);
  border-radius: 5px;
}
.selectwidget{
  position: relative;
}
.selectwidget_dot {
  position: absolute;
  top: 3px;
  right: 3px;
  display: flex;
  align-items: center;
  height: 2px;
  width: 2px;
  background: #04C562;
  border-radius: 5px;
}
.btn-wrapper{
  border-radius: 100px;
  background: var(--color-btnmain);
  color: var(--color-global);
  font-weight: 500;
  height: 30px;
  font-family: TT Norms Medium; 
  padding: 2px 20px;
}

.divider_step_container{
  width: 200px;
  display: flex;
  gap: 5px;
}
.divider_step_one{
  width: 30px;
  height: 3px;
  border-radius: 100px;
  background: var(--color-global);
}
.divider_step_two{
  width: 166px;
  height: 3px;
  border-radius: 100px;
  background: var(--color-gray);
}

.card_btn{
  display: flex;
  align-items: center;
  gap: 5px;
}
.card_back_btn{
  font-family: TT Norms Medium;
  background: var(--color-main);
  color: var(--color-text);
}
.card_back_btn:hover{
  background: var(--color-main);
  color: var(--color-global);
}
.employees{
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 40px;
  height: 427px;
  background: var(--color-main);
  border-radius: 25px;
}
.employees_divider_step_one{
  width: 107px;
  height: 3px;
  border-radius: 100px;
  background: var(--color-global);
}
.employees_divider_step_two{
  width: 91px;
  height: 3px;
  border-radius: 100px;
  background: var(--color-gray);
}
.employees_card_container{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  width: 540px;
  height: 100%;
  overflow: scroll;
}
.employees_card{
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: var(--color-gray);
  border-radius: 10px;
  padding: 10px;
  width: 255px;
  height: fit-content;
}
.employees_compo-wrapper{
  display: flex;
  gap: 10px;
}
.employees_text{
  text-align: left;
  font-family: TT Norms Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: var(--color-text);  
}
.employees_subtext{
  font-family: TT Norms Medium;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
}
.employees_container{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.star_container{
  display: flex;
  gap: 5px;
}
.employees_rate{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.employees_rate_text{
  font-family: TT Norms Medium;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
  text-decoration: underline;
  cursor: pointer;
}
.user-alt{
  width: 70px;
  height: 54px;
  background: var(--color-main);
  display: flex;
  align-items: center;
  justify-content: center;
}
.alt_btn{
  display: flex;
  justify-content: end;
}
.btn-wrapper:hover{
  filter: brightness(70%);
}
.calendar{
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 40px;
  height: 315px;
  background: var(--color-main);
  border-radius: 25px;
}
.calendar_numbers_container{
  display: flex;
  gap: 15px;
}
.calendar_numbers{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 33px;
  border-radius: 3px;
  background: var(--color-gray);
  cursor: pointer;
}
.calendar_numbers_head{
  font-family: TT Norms Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
}
.calendar_numbers_sub{
  font-family: TT Norms light;
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
}
.weekend{
  color: #F97F7F;
}
.calendar_card_container{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}
.calendar_card{
  background: var(--color-gray);
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.calendar_compo-wrapper{
  display: flex;
  align-items: center;
  gap: 10px;
}
.calendar_text{
  text-align: left;
  font-family: TT Norms Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: var(--color-text);  
}
.calendar_container{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.calendar_wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.calendar_rate{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.calendar_rate_text{
  font-family: TT Norms Medium;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
  text-decoration: underline;
  cursor: pointer;
}
.calendar_time_container{
  display: flex;
  gap: 10px;
}
.calendar_time{
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-main);
  font-family: TT Norms Medium;
  font-size: 10px;
  font-weight: 300;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
  width: 45px;
  height: 24px;
}
.calendar_divider_step_one{
  width: 171px;
  height: 3px;
  border-radius: 100px;
  background: var(--color-global);
}
.calendar_divider_step_two{
  width: 28px;
  height: 3px;
  border-radius: 100px;
  background: #D8DDE3;
}
.data{
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 30px 40px;
  height: 427px;
  background: var(--color-main);
  border-radius: 25px;
}
.data_wrap{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.data_info{
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px 10px 0 0;
  width: 236px;
  background: var(--color-gray);
  padding: 25px 15px 12px 15px;
}
.data_info_card{
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  background: var(--color-main);
  padding: 10px;
  border-radius: 3px;
}
.data_img{
  width: 40px;
  height: 40px;
}
.data_info_container{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.data_info_head{
  font-family: TT Norms Medium;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
}
.data_info_sub{
  font-family: TT Norms Medium;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
  color: #AFB6C1;
}
.data_container{
  display: flex;
  gap: 25px;
}
.data_details_head{
  font-family: TT Norms Medium;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
}
.data_details{
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 260px;
}
.data_forma_text{
  font-family: TT Norms Medium;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
}
.data_forma{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.data_forma_star{
  font-family: TT Norms Medium;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #F97F7F;
}
.data_forma_container{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
input{
  margin: 0;
  background: var(--color-gray);
  color: var(--color-text);
}
.figures{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.figures_text{
  width: 51%;
  font-family: TT Norms Medium;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #D2D8DE;
}

.figures_text-mob{
  width: 100%;
  font-family: TT Norms Medium;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
  color: #D2D8DE;
}
.figures_container{
  width: 49%;
  display: flex;
  align-items: center;
  gap: 5px;
}
.mark{
  width: 16px;
  height: 16px;
  border-radius: 3px;
  border: 1px solid #C6CBD2;
  cursor: pointer;
  user-select:none;
}
.mark_active{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  border: 1px solid var(--color-global);
  cursor: pointer;
  background: var(--color-global);
  user-select:none;
}
.figures_personal{
  font-family: TT Norms Medium;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  color: #C6CBD2;
}
.personal_span{
  color: var(--color-global);
  cursor: pointer;
  text-decoration: underline;
}
.data_divider_step_one{
  width: 201px;
  height: 3px;
  border-radius: 100px;
  background: var(--color-global);
}
.notes{
  display: flex;
  flex-direction: column;
  padding: 30px 40px;
  height: 356px;
  background: var(--color-main);
  border-radius: 25px;
}
.notes_container{
  display: flex;
  align-items: center;
  gap: 15px;
}
.notes_info{
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px 10px 0 0;
  width: 236px;
  background: var(--color-gray);
  padding: 25px 15px 12px 15px;
}
.notes_confirm{
  width: 261px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.notes_confirm_container{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.notes_confirm_head{
  font-family: TT Norms Medium;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
}
.notes_confirm_sub{
  font-family: TT Norms light;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
}
.notes_button{
  font-family: TT Norms Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  gap: 5px;
  background: var(--color-global);
  color: var(--color-main);
}

.notes_button:hover{
  cursor: pointer;
  background: var(--color-btnmain);
  color: var(--color-global);
}

.notes_button:hover svg path{
  fill: var(--color-global);
}
.branch_card{
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 262px;
  overflow: scroll;
}
.compo-container{
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 10px;
  height: 230px;
  padding: 40px 40px 50px 40px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  margin-bottom: -30px;
  object-fit: cover;
  width: 100%;
  box-shadow: inset 0px -100px 30px rgba(0, 0, 0, 0.825);
}
.compo-wrap{
  display: flex;
  gap: 20px;
}
.compo-top{
  display: flex;
  align-items: center;
  gap: 10px;
}
.compo-text{
  color: white;
  font-family: TT Norms Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
}
.divider_container{
  justify-content: center;
  display: flex;
  gap: 3px;
}
.compo_divider{
  width: 100px;
  height: 5px;
  border-radius: 100px;
  background: #FFFFFF80;
}
.service_tools{
  position: absolute;
  bottom: -4px;
  right: 0;
}
.service_ppl{
  position: absolute;
  bottom: 0;
  right: -25px;
}
.checkmark{
  position: absolute;
  top: 10px;
  right: 10px;
  width: 18px;
  height: 18px;
  background: var(--color-main);
  border: 1px solid #F5F5F5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}
.checkmark:hover{
  background: #04C562;
  cursor: pointer;
}

.active-checkmark{
  position: absolute;
  top: 10px;
  right: 10px;
  width: 18px;
  height: 18px;
  background: #04C562;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease
}
.img_checkmark{
  position: absolute;
  top: -20px;
  left: 100px;
}
.notes_data{
  display: flex;
  flex-direction: column;
}
.notes_info_bottom{
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 0 0 10px 10px;
  width: 236px;
  background: var(--color-gray);
  padding: 8px 15px 25px 15px;
}
.data_info_bottom{
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 0 0 10px 10px;
  width: 236px;
  background: var(--color-gray);
  padding: 8px 15px 25px 15px;
}
.divider_dotted_container{
  position: relative;
  background: var(--color-gray);
  height: 12px;
  display: flex;
  align-items: center;
}
.divider_dotted{
  width: 100%;
  height: 2px;
  border-bottom: 2px dashed #AFB6C1;
}
.divider_dotted_container::before{
  position: absolute;
  top: 0;
  width: 12px;
  height: 12px;
  left: 0;
  content: '';
  border-radius: 50%;
  background: var(--color-main);
  transform: translateX(-50%)
}
.divider_dotted_container::after{
  position: absolute;
  top: 0;
  width: 12px;
  height: 12px;
  right: 0;
  content: '';
  border-radius: 50%;
  background: var(--color-main);
    transform: translateX(50%)
}

  .choice-banner{
    display: block;
    height: 163px;
    width: 100%;
    background-size: cover;
    border-radius: 15px;
  }
  .p-dropdown{
    background: var(--color-gray);
  }
  .delete-btn{
      width: 30px;
      height: 30px;
      background-color: rgba(249, 144, 144, 0.1);
      border-radius: 50%;
      padding: 0;
    }

    .delete-btn:hover{
      cursor: pointer;
      width: 30px;
      height: 30px;
      background-color: rgba(241, 129, 129, 0.391);
      border-radius: 50%;
      padding: 0;
    }
  .main{
    width: 315px;
    border-radius: 20px;
    background: transparent;
  }
  .choice{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 10px 15px 10px;
    border-radius: 0 0 25px 25px;
    height: 560px;
    background: var(--color-main);
  }
  .branch{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 10px 15px 10px;
    border-radius: 0 0 25px 25px;
    height: 560px;
    background: var(--color-main);
  }
  .search input{
    background-color: var(--color-main);
    color:#D2D8DE;
    margin: 0;
    width: 100%;
    border: 1px solid #D2D8DE;
  }

  .search input:focus{
    outline: none;
    border: 1px solid var(--color-global);
  }
  .search input{
    background-image: url(../../static/img/search.svg);
    background-repeat: no-repeat;
    padding-left: 35px;
    background-position: 15px;
  }
  .card{
    background-color: var(--color-gray);
    border-radius: 15px;
    display: block;
    align-items: center;
    cursor: pointer;
  }

  .card-image img{
    border-radius: 15px 15px 0 0;
    width: 100%;
    height: 80px;
    object-fit: cover;
  }
  .card:hover .card_name{
    color: var(--color-global);
  }

  .card:hover .card_address, .card:hover .card_time{
    color: var(--color-text);
  }

  svg path{
    transition: all .2s ease;
  }

  .card:hover svg path{
    fill: var(--color-text);
  }

  .card_container{
    background-color: var(--color-gray);
    width: 100%;
    padding: 20px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .card_address_container{
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .card_time_container{
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .card_name{
    font-family: TT Norms Medium;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text);
    margin: 0;
    transition: all .2s ease;
  }
  .card_address{
    font-family: TT Norms Medium;
    font-size: 10px;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #AFB6C1;
    margin: 0;
    transition: all .2s ease;
  }
  .card_time{
    font-family: TT Norms Medium;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #AFB6C1;
    margin: 0;
    transition: all .2s ease;
  }
  .card_next_container{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .card_next{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .card_next_text{
    font-family: TT Norms Medium;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text);
    margin: 0;
  }
  .divider{
    width: 130px;
    height: 3px;
    border-radius: 15px;
    border: none;
    background: var(--color-gray);
    margin: 0;
  }
  .card_next_subtext{
    font-family: TT Norms Medium;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text);
    margin: 0;
  }
  .card_number{
    text-decoration: underline;
    color: var(--color-global);
  }
  .card_next_btn-disabled{
    border-radius: 30px;
    cursor: not-allowed;
    background: var(--color-btnmain);
    color: var(--color-global);
    filter: brightness(50%);
  }

  .card_next_btn-active{
    border-radius: 30px;
    cursor: pointer;
    background: var(--color-global);
    color: var(--color-main);
    filter: brightness(100%);
  }

  .card_next_btn-active:hover{
    cursor: pointer;
    background: var(--color-btnmain);
    color: var(--color-global);
  }

  .choice>*:nth-child(3){
    margin-top: auto;
  }

  .choice_overview{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: fit-content;
    background: var(--color-gray);
    padding: 20px;
    border-radius: 15px;
  }
  .choice_rate_container{
    display: flex;
    align-items: center;
    gap: 15px;
  }
  .choice_rate{
    font-family: TT Norms Medium;
    font-size: 42px;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--color-text);
  }
  .choice_star_text{
    font-family: TT Norms Medium;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text);
    text-decoration: underline;
    cursor: pointer;
  }
  p{
    margin: 0;
  }
  .stars{
    display: flex;
    gap: 7px;
  }
  .choice_star_container{
    display: flex;
    flex-direction: column;
    gap: 7px;
  }
  .choice_info_container{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .choice_info_text{
    font-family: TT Norms Medium;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text);
  }
  .service{
    display: flex;
    justify-content: space-between;
    gap: 0;
  }
  .service_container{
    position: relative;
    padding: 20px;
    width: 48%;
    height: 170px;
    background: var(--color-gray);
    border-radius: 15px;
    cursor: pointer;
  }

  .service_container:hover .service_container_text{
    color: var(--color-global);
  }

  .service_container_text{
    font-family: TT Norms Medium;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text);
    transition: all .2s ease;
  }
  .favor{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 10px 15px 10px;
    border-radius: 0 0 25px 25px;
    height: 560px;
    background: var(--color-main);
  }
  .favor_card_container{
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    overflow: scroll;
  }
  .favor_card{
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: var(--color-gray);
    border-radius: 10px;
    padding: 10px;
    width: 100%;
  }
  .favor_compo-wrapper{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .favor_text{
    text-align: left;
    font-family: TT Norms Medium;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: var(--color-text);  
  }
  .compo-wrapper-tariff{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .tariff{
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .tariff_text{
    font-family: TT Norms Medium;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text);
  }
  .dot {
    display: flex;
    align-items: center;
    height: 2px;
    width: 2px;
    background: var(--color-global);
    border-radius: 5px;
  }
  .selectwidget{
    position: relative;
  }
  .selectwidget_dot {
    position: absolute;
    top: 3px;
    right: 3px;
    display: flex;
    align-items: center;
    height: 2px;
    width: 2px;
    background: #04C562;
    border-radius: 5px;
  }
  .btn-wrapper{
    border-radius: 100px;
    background: var(--color-btnmain);
    color: var(--color-global);
    font-weight: 500;
    height: 30px;
    font-family: TT Norms Medium; 
    padding: 2px 20px;
  }

  .divider_step_container{
    width: 125px;
    display: flex;
    gap: 5px;
  }
  .divider_step_one{
    width: 30px;
    height: 3px;
    border-radius: 100px;
    background: var(--color-global);
  }
  .divider_step_two{
    width: 166px;
    height: 3px;
    border-radius: 100px;
    background: var(--color-gray);
  }
  .card_btn_container{
    display: flex;
    gap: 10px;
  }
  .card_back_btn{
    font-family: TT Norms Medium;
    background: var(--color-main);
    color: var(--color-text);
  }
  .card_back_btn:hover{
    background: var(--color-main);
    color: var(--color-global);
  }
  .employees{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 10px 15px 10px;
    border-radius: 0 0 25px 25px;
    height: 560px;
    background: var(--color-main);
  }
  .employees_divider_step_one{
    width: 107px;
    height: 3px;
    border-radius: 100px;
    background: var(--color-global);
  }
  .employees_divider_step_two{
    width: 91px;
    height: 3px;
    border-radius: 100px;
    background: var(--color-gray);
  }
  .employees_card_container{
    height: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    width: 100%;
    overflow: scroll;
  }
  .employees_card{
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: var(--color-gray);
    border-radius: 10px;
    padding: 10px;
    width: 100%;
  }
  .employees_compo-wrapper{
    display: flex;
    gap: 10px;
  }
  .employees_text{
    text-align: left;
    font-family: TT Norms Medium;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: var(--color-text);  
  }
  .employees_subtext{
    font-family: TT Norms Medium;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text);
  }
  .employees_container{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .star_container{
    display: flex;
    gap: 5px;
  }
  .employees_rate{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .employees_rate_text{
    font-family: TT Norms Medium;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text);
    text-decoration: underline;
    cursor: pointer;
  }
  .user-alt{
    width: 70px;
    height: 54px;
    background: var(--color-main);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .alt_btn{
    display: flex;
    justify-content: end;
  }
  .btn-wrapper:hover{
    filter: brightness(70%);
  }
  .calendar{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 10px 15px 10px;
    border-radius: 0 0 25px 25px;
    height: 560px;
    background: var(--color-main);
  }
  .calendar_numbers_container{
    display: flex;
    gap: 10px;
    overflow-x: scroll;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }
  .calendar_numbers{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 45px;
    height: 33px;
    border-radius: 3px;
    background: var(--color-gray);
    cursor: pointer;
  }
  .calendar_numbers_head{
    font-family: TT Norms Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text);
  }
  .calendar_numbers_sub{
    font-family: TT Norms light;
    font-size: 10px;
    font-weight: 500;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text);
  }
  .weekend{
    color: #F97F7F;
  }
  .calendar_card_container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
  .calendar_card{
    background: var(--color-gray);
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 15px;
    flex-grow: 5;
  }
  .calendar_compo-wrapper{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .calendar_text{
    text-align: left;
    font-family: TT Norms Medium;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: var(--color-text);  
  }
  .calendar_container{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .calendar_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;
    align-items: start;
  }
  .calendar_rate{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .calendar_rate_text{
    font-family: TT Norms Medium;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text);
    text-decoration: underline;
    cursor: pointer;
  }
  .calendar_time_container{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .calendar_time{
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-main);
    font-family: TT Norms Medium;
    font-size: 10px;
    font-weight: 300;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text);
    width: 45px;
    height: 24px;
  }
  .calendar_divider_step_one{
    width: 171px;
    height: 3px;
    border-radius: 100px;
    background: var(--color-global);
  }
  .calendar_divider_step_two{
    width: 28px;
    height: 3px;
    border-radius: 100px;
    background: #D8DDE3;
  }
  .data{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 10px 15px 10px;
    border-radius: 0 0 25px 25px;
    height: 560px;
    background: var(--color-main);
  }
  .data_wrap{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .data_info{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 10px;
    width: 100%;
    background: var(--color-gray);
    padding: 10px;
  }
  .data_info_card{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    background: var(--color-main);
    padding: 10px;
    border-radius: 3px;
  }
  .data_img{
    width: 40px;
    height: 40px;
  }
  .data_info_container{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .data_info_head{
    font-family: TT Norms Medium;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text);
  }
  .data_info_sub{
    font-family: TT Norms Medium;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    color: #AFB6C1;
  }
  .data_container{
    display: block;
    gap: 25px;
  }
  .data_details_head{
    font-family: TT Norms Medium;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text);
  }
  .data_details{
    margin: 20px 30px 0 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: auto;
  }
  .data_forma_text{
    font-family: TT Norms Medium;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text);
  }
  .data_forma{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .data_forma_star{
    font-family: TT Norms Medium;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #F97F7F;
  }
  .data_forma_container{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  input{
    margin: 0;
    background: var(--color-gray);
    color: var(--color-text);
  }
  .figures{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .figures_text{
    width: 51%;
    font-family: TT Norms Medium;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #D2D8DE;
  }
  .figures_container{
    margin: 0 30px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .mark{
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: 1px solid #C6CBD2;
    cursor: pointer;
    user-select:none;
  }
  .mark_active{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: 1px solid var(--color-global);
    cursor: pointer;
    background: var(--color-global);
    user-select:none;
  }
  .figures_personal{
    font-family: TT Norms Medium;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #C6CBD2;
  }
  .personal_span{
    color: var(--color-global);
    cursor: pointer;
    text-decoration: underline;
  }
  .data_divider_step_one{
    width: 201px;
    height: 3px;
    border-radius: 100px;
    background: var(--color-global);
  }
  .notes{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 10px 15px 10px;
    border-radius: 0 0 25px 25px;
    height: 560px;
    background: var(--color-main);
  }
  .notes_container{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 15px;
  }
  .notes_info{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 10px 10px 0 0;
    width: 100%;
    background: var(--color-gray);
    padding: 25px 15px 12px 15px;
  }
  .notes_confirm{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 25px;
  }
  .notes_confirm_container{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .notes_confirm_head{
    font-family: TT Norms Medium;
    font-size: 28px;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--color-text);
  }
  .notes_confirm_sub{
    font-family: TT Norms light;
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--color-text);
  }
  .notes_button{
    font-family: TT Norms Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    gap: 5px;
    width: fit-content;
    border-radius: 100px;
    background: var(--color-global);
    color: var(--color-main);
  }

  .notes_button:hover{
    cursor: pointer;
    background: var(--color-btnmain);
    color: var(--color-global);
  }

  .notes_button:hover svg path{
    fill: var(--color-global);
  }
  .branch_card{
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    overflow: scroll;
  }
  .compo-container{
    display: flex;
    flex-direction: column;
    justify-content: end;
    gap: 10px;
    height: 120px;
    padding: 10px 10px 0px 10px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-bottom: 0;
    object-fit: cover;
    width: 100%;
    box-shadow: inset 0px -50px 20px rgba(0, 0, 0, 0.779);
  }
  .compo-wrap{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .compo-top{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .compo-text{
    color: white;
    font-family: TT Norms Medium;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
  }
  .divider_container{
    justify-content: center;
    display: flex;
    gap: 3px;
  }
  .compo_divider{
    width: 0;
    height: 0;
    border-radius: 100px;
    background: #FFFFFF80;
  }
  .service_tools{
    position: absolute;
    bottom: -4px;
    right: 0;
  }
  .service_ppl{
    position: absolute;
    bottom: 0;
    right: -25px;
  }
  .checkmark{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 18px;
    height: 18px;
    background: var(--color-main);
    border: 1px solid #F5F5F5;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
  }
  .checkmark:hover{
    background: #04C562;
    cursor: pointer;
  }

  .active-checkmark{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 18px;
    height: 18px;
    background: #04C562;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease
  }
  .img_checkmark{
    position: absolute;
    top: -20px;
    left: 44%;
  }
  .notes_data{
    width: 100%;
    margin: 40px;
    display: flex;
    flex-direction: column;
  }
  .notes_info_bottom{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 0 0 10px 10px;
    width: 100%;
    background: var(--color-gray);
    padding: 8px 15px 25px 15px;
  }
  .data_info_bottom{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 0 0 10px 10px;
    width: 100%;
    background: var(--color-gray);
    padding: 8px 15px 25px 15px;
  }
  .divider_dotted_container{
    display: flex;
    position: relative;
    background: var(--color-gray);
    height: 12px;
    align-items: center;
  }
  .divider_dotted{
    width: 100%;
    height: 2px;
    border-bottom: 2px dashed #AFB6C1;
  }
  .divider_dotted_container::before{
    position: absolute;
    top: 0;
    width: 12px;
    height: 12px;
    left: 0;
    content: '';
    border-radius: 50%;
    background: var(--color-main);
    transform: translateX(-50%)
  }
  .divider_dotted_container::after{
    position: absolute;
    top: 0;
    width: 12px;
    height: 12px;
    right: 0;
    content: '';
    border-radius: 50%;
    background: var(--color-main);
      transform: translateX(50%)
  }
</style>