<template>
  <div style="height: 100%;">
    <div class="service_card">
      <div class="card-container">
        <div class="main_container">
          <div class="main_wrapp">
            <div class="card_img" v-if="isActive">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_6004_10668)">
              <path d="M15.8139 7.45276C15.5707 7.06067 15.141 6.82629 14.6644 6.82629H4.0531C3.5271 6.82629 3.04871 7.1228 2.83399 7.58191L0.0273438 13.4666C0.133301 13.8975 0.535767 14.2194 1.01514 14.2194H12.3048C12.7875 14.2194 13.2286 13.9464 13.4442 13.5145L15.8701 8.65222C16.0617 8.26746 16.0404 7.81909 15.8139 7.45276Z" fill="white"/>
              <path d="M2.26257 6.67676C2.47705 6.21778 2.95557 5.92114 3.48169 5.92114H13.474V4.64307C13.474 4.11621 13.0295 3.6875 12.4833 3.6875H6.65686C6.64832 3.6875 6.64246 3.6853 6.64026 3.68372L5.59742 2.22559C5.41272 1.96692 5.10767 1.8125 4.78174 1.8125H0.990967C0.444458 1.8125 0 2.24121 0 2.76807V11.4204L2.26257 6.67676Z" fill="white"/>
              </g>
              <defs>
              <clipPath id="clip0_6004_10668">
              <rect width="16" height="16" fill="white"/>
              </clipPath>
              </defs>
              </svg>
            </div>
            <div v-else class="avatar" :style="{ 'background-color': ProjectData.colour }">
              <p class="avatar_text">{{ formatText(ProjectData.name) }}</p>
            </div>
            <div class="main_text">
              <p class="main_header">{{ ProjectData.name || 'NaN' }}</p>
              <p class="main_subheader">ID {{ ProjectData.id || 'NaN' }}</p>
            </div>
          </div>
          <div class="checkmark" v-if="!isActive" @click="toggleModal(ProjectData)">
            <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2556 6.15492L9.05226 14.4665L4.29285 9.7071L5.70706 8.29289L8.94764 11.5335L14.7443 4.84506L16.2556 6.15492Z" fill="white"/>
            </svg>
          </div>
          <div class="active-checkmark" v-else @click="deactivateProject">
            <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2556 6.15492L9.05226 14.4665L4.29285 9.7071L5.70706 8.29289L8.94764 11.5335L14.7443 4.84506L16.2556 6.15492Z" fill="white"/>
            </svg>
          </div>
        </div>
        <div class="cards">
          <div class="text-container">
            <p class="text-header">{{ ProjectData.services || '0' }}</p>
            <p class="text-subheader">Количество услуг</p>
          </div>
          <div class="cards">
            <div class="text-container">
              <p class="text-header">{{ ProjectData.filials || '0' }}</p>
              <p class="text-subheader">Количество филиалов</p>
            </div>
          </div>
        </div>
        <div class="cards">
          <div class="text-container">
            <p class="text-header">{{ ProjectData.employees || '0' }}</p>
            <p class="text-subheader">Сотрудников</p>
          </div>
          <div class="cards">
            <div class="text-container">
              <p class="text-header">{{ role || 'NaN' }}</p>
              <p class="text-subheader">Ваша роль</p>
            </div>
          </div>
        </div>  
        <div class="bottom">
          <p class="text-subheader" v-show="false">Последнее изменение: {{ ProjectData.editDate }} в {{ ProjectData.editTime }}</p>
          <div class="bottom_btns" v-if="role == 'Владелец'">
            <router-link :to="'/dashboard/project/edit/' + ProjectData.id">
              <button class="functions">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0,0,256,256" width="20" height="20"><g fill="#535c69" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(10.66667,10.66667)"><path d="M18,2l-2.41406,2.41406l4,4l2.41406,-2.41406zM14.07617,5.92383l-11.07617,11.07617v4h4l11.07617,-11.07617z"></path></g></g></svg>
                Редактировать</button>
            </router-link>
            <router-link :to="'/dashboard/project/accesses/' + ProjectData.id" style="width:65%">
              <button class="functions">
                <svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.7 3.15001C7.7 4.50311 8.7969 5.60001 10.15 5.60001C11.5031 5.60001 12.6 4.50311 12.6 3.15001C12.6 1.79691 11.5031 0.700012 10.15 0.700012C8.7969 0.700012 7.7 1.79691 7.7 3.15001Z" fill="#535C69"/>
                <path d="M2.1 2.80001H3.5V4.90001H5.6V6.30001H3.5V8.40001H2.1V6.30001H0V4.90001H2.1V2.80001Z" fill="#535C69"/>
                <path d="M13.3 9.10001C13.3 8.32681 12.6732 7.70001 11.9 7.70001H8.4C6.0804 7.70001 4.2 9.58042 4.2 11.9V12.6H13.3V9.10001Z" fill="#535C69"/>
              </svg>
                Доступы</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div :class="{'overlay-show' : showModal, 'overlay-hide' : !showModal}"></div>
    <div :class="{'modal-show' : showModal, 'modal-hide' : !showModal}">
          <div class="modal-content">
            <p class="text-header">Смена проекта</p>
            <p class="modal-subtext">Вы действительно хотите сменить <br> проект на <span>"{{ selectedProjectToChange.name }}"</span>?</p>
            <div class="btn_container">
              <button class="delete" @click="setActiveProject(selectedProjectToChange.id)" v-if="step==1">Да, сменить</button>
              <button class="delete" v-if="step==2">Смена проекта...</button>
              <button class="exit" v-if="step==1" @click="toggleModal">Отмена</button>
            </div>
          </div>
    </div>
      <MessageAlert :message="alertMessage" :color="alertColor"/>
  </div>
  </template>
  
  <script>
  import MessageAlert from "../components/MessageAlert.vue";
  import { mapMutations } from 'vuex';
  export default {
    components: {MessageAlert},
    props:['ProjectData'],
    data() {
      return {
        alertMessage: null,
        alertColor: '',
        selectedProjectToChange: '',

        showModal: false,
        step: 1,
        role: '',
      };
    },
    mounted(){
      this.fetchUserRole();
    },
    computed: {
      isActive() {
        const a = this.$store.state.activeProjectId;
        if (a === this.ProjectData.id) {
          return true;
        } else{
          return false;
        }
      },
    },
    methods: {
      ...mapMutations(['setUpdateSidebar']),
      rerenderSidebar() {
        this.setUpdateSidebar();
      },
      setActiveProject(projectId) {
        this.alertMessage = null;
        this.step = 2
        setTimeout(() => {
          

          let projectCurrency;
          switch (this.selectedProjectToChange.currency) {
            case 'RUB — Российский рубль':
              projectCurrency = '₽'
              break;
            case 'BYN — Белорусский рубль':
              projectCurrency = 'Br'
              break;
            case 'USD — Доллар США':
              projectCurrency = '$'
              break;
            case 'EUR — Евро':
              projectCurrency = '€'
              break;
            case 'KZT — Казахстанский тенге':
              projectCurrency = '₸'
              break;
            case 'UAH — Украинская гривна':
              projectCurrency = '₴'
              break;
            case 'CNY — Китайский юань':
              projectCurrency = '¥'
              break;
            default:
              break;
          }

          this.$store.commit('setActiveProject', projectId);
          this.$store.commit('setActiveProjectCurrency', projectCurrency)
          this.$store.commit('setActiveProjectName', this.ProjectData.name);
          
          this.alertMessage = 'Проект успешно изменен';
          this.alertColor = '#0BB6A1';
        }, 100);
        setTimeout(() => {
          this.showModal = !this.showModal;
          this.rerenderSidebar();
        }, 1000);
        
      },
      deactivateProject() {
        this.$store.commit('deactivateProject');
      },

      toggleModal(project) {
        this.step = 1;
        this.selectedProjectToChange = project;
        this.showModal = !this.showModal;
      },

      formatText(text) {
          return text
            .split(' ')
            .map(word => (word.length > 1 ? word.charAt(0).toUpperCase() : word.toUpperCase()))
            .join('');
      },
      async fetchUserRole() {
        const projectId = this.ProjectData.id
        try {
          const response = await this.$axios.get(`/api/project/${projectId}/user/${this.$store.state.registrationData.user_id}/role/`);

          this.role = response.data.role;
        } catch (error) {
            console.error('Error fetching role:', error);
            return null;
        }
      },
    }
  };
  </script>
  
  
  <style scoped>
  .modal-subtext{
    font-family: TT Norms Medium;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #AFB6C1;
    margin: 0;
    margin-top: 10px;
  }
  .btn_container{
    margin-top: 30px;
    display: flex;
    gap: 10px;
  }
  span{
    color: #7D838C;
  }
  .delete{
    color: #6266EA;
    background-color: #EFEFFF;
  }
  .delete:hover{
    background: #6266EA;
    color: #FFFFFF;
  }
  .exit{
    color: #535C69;
    border: 1px solid #DDE1E5;
    background: #FFFFFF;  
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  }
  .exit:hover{
    border: 1px solid #AFB6C1;
    background: #F5F5F5;
  }
  .modal-show{
    width: auto;
    height: auto;
    position: absolute;
    padding: 40px;
    border-radius: 10px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: white;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    transition: all .1s ease;
  }
  .modal-hide{
    width: auto;
    height: auto;
    position: absolute;
    padding: 40px;
    border-radius: 10px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: white;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    transition: all .1s ease;
  }
  .overlay-show {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Задний фон с прозрачностью 60% */
    z-index: 98;
    opacity: 1;
    visibility: visible;
    transition: all .1s ease;
  }
  .overlay-hide {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Задний фон с прозрачностью 60% */
    z-index: 98;
    opacity: 0;
    visibility: hidden;
    transition: all .1s ease;
  }
  .avatar{
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
  }
  .avatar_text{
    margin: -10px;
    font-family: 'TT Norms Medium';
    width: auto;
    height: auto;
    color: #FFFFFF;
    font-size: 14px;
  }
    .text-header{
      font-family: 'TT Norms Medium';
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0em;
      color: #535C69;
      margin: 0;
      text-align: left;
    }
    .text-subheader{
      font-family: 'TT Norms Medium';
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0em;
      color: #AFB6C1;
      margin: 0;
      text-align: left;
    }
    .service_card{
      width: 100%;
      height: 100%;
      background-color: #FFF;
      border-radius: 5px;
      transition: all .2s ease;
    }

    .service_card:hover{
      filter: drop-shadow(0 0 10px rgb(228, 228, 228));
    }
    .card-container{
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      height: 100%;
    }
    p{
      margin: 0;
    }
    .cards{
      display: flex;
      gap: 10px;
      width: 100%;
    }
    .text-container{
      width: 50%;
    }
    img{
      border-radius: 5px;
    }
    .card_img{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      width: 32px;
      border-radius: 30px;
      background: #F97F7F;
    }
    .main_container{
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }
    .main_header{
      font-family: 'TT Norms Medium';
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
      color: #535C69;
    }
    .main_subheader{
      font-family: 'TT Norms Medium';
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.03em;
      text-align: left;
      color: #AFB6C1;
    }
    .bottom{
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .bottom_btns{
      display: flex;
      justify-content: space-between;
      gap: 20px;
    }
    .functions{
      padding: 20px 0;
      border: 1px solid #DDE1E5;
      background: #FFFFFF ;
      font-family: 'TT Norms Medium';
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: center;
      color: #535C69;
      width: 100%;
      transition: all .2s ease;
    }

    .functions svg path{
      fill: #535C69;
      transition: all .2s ease;
    }

    .functions:hover{
      border: 1px solid #6266EA;
      color: #FFFFFF;
      background: #6266EA;
    }

    .functions:hover svg path{
      fill: white;
    }


    a{
      text-decoration: none;
      width: 100%;
    }
    .main_wrapp{
      display: flex;
      gap: 10px;
    }
    .checkmark{
      width: 18px;
      height: 18px;
      background: #F3F5F6;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease
    }
    .checkmark:hover{
      background: #04C562;
      cursor: pointer;
    }

    .active-checkmark{
      width: 18px;
      height: 18px;
      background: #04C562;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.2s ease
    }
  </style>  