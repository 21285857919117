<template>
    <div class="main">
      <div class="transition">
        <router-link to="/dashboard/project" class="eidt-link">Мои проекты</router-link>
        <div class="arrow-container">
          <img src="../../static/img/arrow-right.png" alt="Стрелка вправо" class="arrow-icon">
        </div>
        <router-link to="/dashboard/project" class="eidt-link">{{ companyName }}</router-link>
        <div class="arrow-container">
          <img src="../../static/img/arrow-right.png" alt="Стрелка вправо" class="arrow-icon">
        </div>
        <router-link :to="'/dashboard/project/accesses/' + project" class="eidt-link">Доступы</router-link>
        <div class="arrow-container">
          <img src="../../static/img/arrow-right.png" alt="Стрелка вправо" class="arrow-icon">
        </div>  
        <p class="creation_text">{{ worker.profile }}</p>
      </div>
      <div class="edit">
        <p class="edit_header">Добавление нового пользователя для проекта:</p>
        <div class="main_container">
          <div class="card_img">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_6004_10668)">
              <path d="M15.8139 7.45276C15.5707 7.06067 15.141 6.82629 14.6644 6.82629H4.0531C3.5271 6.82629 3.04871 7.1228 2.83399 7.58191L0.0273438 13.4666C0.133301 13.8975 0.535767 14.2194 1.01514 14.2194H12.3048C12.7875 14.2194 13.2286 13.9464 13.4442 13.5145L15.8701 8.65222C16.0617 8.26746 16.0404 7.81909 15.8139 7.45276Z" fill="white"/>
              <path d="M2.26257 6.67676C2.47705 6.21778 2.95557 5.92114 3.48169 5.92114H13.474V4.64307C13.474 4.11621 13.0295 3.6875 12.4833 3.6875H6.65686C6.64832 3.6875 6.64246 3.6853 6.64026 3.68372L5.59742 2.22559C5.41272 1.96692 5.10767 1.8125 4.78174 1.8125H0.990967C0.444458 1.8125 0 2.24121 0 2.76807V11.4204L2.26257 6.67676Z" fill="white"/>
              </g>
              <defs>
              <clipPath id="clip0_6004_10668">
              <rect width="16" height="16" fill="white"/>
              </clipPath>
              </defs>
            </svg>
          </div>
          <div class="main_text">
            <p class="main_header">{{ companyName }}</p>
            <p class="main_subheader">ID {{ project }}</p>
          </div>
        </div>
        <div class="divider"></div>
        <div class="form">
          <label for="">Введите Email адрес пользователя</label>
          <input type="email" placeholder="Email" v-model="emailToSend">
          <p class="small_text">На этот адрес будет отправлено приглашение</p>
          <p class="small_text" style="color: #F97F7F;" v-if="error != 'Письмо успешно отправлено'">{{ error }}</p>
          <p class="small_text" style="color: #0BB6A1;" v-else>{{ error }}</p>
        </div>
        <div class="choice">
          <p class="choice_text">Выберите права для пользователя</p>
          <div class="choice_container">
            <div class="choice_pick" @click="role = 'Владелец'">
              <div class="circle">
                <div v-if="role === 'Владелец'" class="second_circle"></div>
              </div>
              <p class="pick_text">Владелец</p>
              <Tip :Tip="'Владелец — полная передача прав на проект'"/>
            </div>
            <div class="choice_pick" @click="role = 'Администратор'">
              <div class="circle">
                <div v-if="role === 'Администратор'" class="second_circle"></div>
              </div>
              <p class="pick_text">Администратор</p>
              <Tip :Tip="'Администратор — есть доступ ко всем разделам \n и может редактировать любые разделы'"/>
            </div>
          </div>
          <div class="choice_container">
            <div class="choice_pick" @click="role = 'Менеджер'">
              <div class="circle">
                <div v-if="role === 'Менеджер'" class="second_circle"></div>
              </div>
              <p class="pick_text">Менеджер</p>
              <Tip :Tip="'Менеджер — доступ к заявкам, календарю, \n статистики, клиентам'"/>
            </div>
            <div class="choice_pick" @click="role = 'Сотрудник'">
              <div class="circle">
                <div v-if="role === 'Сотрудник'" class="second_circle"></div>
              </div>
              <p class="pick_text">Сотрудник</p>
              <Tip :Tip="'Сотрудник — доступ к календарю и своему расписанию'"/>
            </div>
          </div>
        </div>
        <div class="dropdown-item" v-if="false">
          <p class="normal-text">Выберите сотрудника</p>
          <SelectPage
          :options="['123','123']"
          :placeholderdata="'Выберите сотрудников'"
          @input="option => selectedEmployees = option"
          :class="{ 'select-error': selectedEmployeesError }"
          :searchable="true"
          />
        </div>
        <div class="send">
          <button class="send_btn" @click="createProjectAccess">Отправить приглашение</button>
        </div>
      </div>
      <MessageAlert :message="alertMessage" :color="alertColor"/>
    </div>
  </template>
  
  <script>
  import SelectPage from './SelectPage.vue';
  import Tip from './TipComponent.vue';
  import MessageAlert from "./MessageAlert.vue";
  export default { 
    components: { SelectPage, Tip, MessageAlert },
    props: ['human', 'project'],
    data() {
      return {
        worker:Object,
        companyName: '',

        selectedRole: null,

        role: '',

        alertMessage: null,
        alertColor: '',
        emailToSend: '',
        error: '',
      };
    },
    methods: {
      async getAccessesByProfileId() {
        try {
          const response = await this.$axios.get(`/api/get_accessbyid/${this.human}/${this.project}`, {
            params: {
              human_id: this.human
            }
          });
          this.worker = response.data;
        } catch (error) {
          console.error('Ошибка при получении доступа по id:', error);
        }
      },
      async fetchProject() {
        try {
          const response = await this.$axios.get('/api/get_projectbyid/', {
            params: {
              variable: this.project
            }
          });
          this.companyName = response.data.name
        } catch (error) {
          console.error('Error fetching project:', error);
        }
      },
    },
    mounted(){
      this.getAccessesByProfileId();
      this.fetchProject()
    }
  };
  </script>
  
  <style scoped>
  .transition {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 20px;
  }
  .eidt-link {
    font-family: TT Norms Medium;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    text-decoration: none;
    color: #AFB6C1;
  }
  .creation_text {
    color: #535C69;
    font-family: TT Norms Medium;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
  }
  .arrow-container {
    display: flex;
    align-items: center;
  }
  .arrow-icon {
    height: 50%;
  }
  .edit {
    width: 40%;
    height: auto;
    background-color: #FFFFFF;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .card_img{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    border-radius: 30px;
    background: #F97F7F;
  }
  .main_container{
    display: flex;
    gap: 10px;
  }
  .main_header{
    font-family: TT Norms Medium;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #535C69;
  }
  .main_subheader{
    font-family: TT Norms Medium;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.03em;
    text-align: left;
    color: #AFB6C1;
  }
  p{
    margin: 0;
  }
  .edit_header{
    font-family: TT Norms Medium;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #535C69;    
  }
  .divider {
    border-bottom: 1px solid rgba(50, 56, 74, 0.1); 
    width: auto;
    margin: 0;
  }
  .choice_container{
    display: flex;
    justify-content: space-between;
  }
  .choice_text{
    font-family: TT Norms Medium;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #535C69;
  }
  .choice{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .choice_pick{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .circle{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    border: 1px solid #C6CBD2;
    background: #F3F5F6;
    border-radius: 15px;
    cursor: pointer;
  }
  .pick_text{
    font-family: TT Norms Medium;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #535C69;    
    cursor: pointer;
  }
  .small_text{
    font-family: TT Norms Medium;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #AFB6C1;
  }
  input{
    margin: 0;
  }
  .second_circle{
    background: #6266EA;
    width: 10px;
    height: 10px;
    border-radius: 25px;
  }
  .send_btn{
    color: #6266EA;
    background: #EFEFFF;
  }
  .send_btn:hover{
    background: #6266EA;
    color: #FFFFFF;
  }
  .send{
    display: flex;
    justify-content: end;
  }
  label{
    margin: 0;
  }
  .form{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .dropdown-item{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .normal-text {
    color:#535C69;
    font-family: TT Norms Medium;
    font-size: 13px;
    font-style: normal;
    line-height: normal;
    text-align: left;
  }
  </style>