<template>
    <div class="date-picker">
        <div class="choose-block">
          <div class="date-input" @click="showPicker = !showPicker">
            {{ formattedDate }}
          </div>
        </div>

        <div class="date-block" v-if="showPicker">

            <div class="calendar_picker">
            <div class="calendar_picker_header">
                <button type="button" class="calendar_btn" @click="toggleMonthPicker">
                {{ monthMainNames[selectedMonth] }}
                </button>
                <button type="button" class="calendar_btn" @click="toggleYearPicker">
                {{ selectedYear }}
                </button>
            </div>
            <div class="divider"></div>
            
            <div v-if="showMonthPicker" class="p-monthpicker">
                <span v-for="(name, index) in monthNames" :key="index" 
                    :class="{'month': true, 'selected': selectedMonth === index}" 
                    @click="selectMonth(index)">
                {{ name }}
                </span>
            </div>
            
            <div v-if="showYearPicker" class="p-yearpicker">
                <span v-for="year in years" :key="year" 
                    :class="{'year': true, 'selected': selectedYear === year}" 
                    @click="selectYear(year)">
                {{ year }}
                </span>
            </div>
            
            <table v-if="!showMonthPicker && !showYearPicker" class="p-datepicker-calendar" role="grid">
                <thead>
                <tr>
                    <th scope="col" abbr="Mo"><span class="days_span">Пн</span></th>
                    <th scope="col" abbr="Tu"><span class="days_span">Вт</span></th>
                    <th scope="col" abbr="We"><span class="days_span">Ср</span></th>
                    <th scope="col" abbr="Th"><span class="days_span">Чт</span></th>
                    <th scope="col" abbr="Fr"><span class="days_span">Пт</span></th>
                    <th scope="col" abbr="Sa"><span class="days_span">Сб</span></th>
                    <th scope="col" abbr="Su"><span class="days_span">Вс</span></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(week, weekIndex) in weeks" :key="weekIndex">
                    <td v-for="(day, dayIndex) in week" :key="dayIndex" aria-label="day">
                    <span :class="{
                        'span_table': true,
                        'selected': !isDisabled(day) && isSelected(day.day),
                        'p-datepicker-today': isToday(day.day),
                        'p-disabled': isDisabled(day)
                    }"
                    v-if="day !== null"
                    @click="day && selectDate(day.day)">
                        {{ day.day || '' }}
                    </span>
                    <span v-else class="p-disabled">{{ '' }}</span>
                    </td>
                </tr>
                </tbody>
            </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            showPicker: false,

            showMonthPicker: false,
            showYearPicker: false,
            selectedMonth: new Date().getMonth(),
            selectedYear: new Date().getFullYear(),
            selectedDate: new Date().getDate(),
            monthNames: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
            monthMainNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябярь', 'Декабрь'],
            years: Array.from({ length: 10 }, (_, i) => 2020 + i),
            today: {
                date: new Date().getDate(),
                month: new Date().getMonth(),
                year: new Date().getFullYear(),
            },

            formattedDate: '',
        }
    },
    computed:{
        weeks() {
            const daysInMonth = new Date(this.selectedYear, this.selectedMonth + 1, 0).getDate();
            const startDay = new Date(this.selectedYear, this.selectedMonth, 1).getDay();
            const weeks = [];
            let week = new Array(7).fill(null);
            const dayNames = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];

            // Преобразуем startDay так, чтобы неделя начиналась с понедельника
            const firstDayOfWeek = startDay === 0 ? 6 : startDay - 1;

            // Заполняем дни предыдущего месяца
            const daysInPreviousMonth = new Date(this.selectedYear, this.selectedMonth, 0).getDate();
            for (let i = firstDayOfWeek; i >= 0; i--) {
                week[i] = { 
                day: daysInPreviousMonth - firstDayOfWeek + i, 
                isCurrentMonth: false, 
                dayName: dayNames[i]
                };
            }

            // Заполняем дни текущего месяца
            for (let day = 1; day <= daysInMonth; day++) {
                const dayOfWeek = (firstDayOfWeek + day - 1) % 7;
                week[dayOfWeek] = { 
                day: day, 
                isCurrentMonth: true, 
                dayName: dayNames[dayOfWeek]
                };

                if (dayOfWeek === 6) {
                weeks.push(week);
                week = new Array(7).fill(null);
                }
            }

            // Заполняем дни следующего месяца, если последняя неделя неполная
            const lastDayOfWeek = (firstDayOfWeek + daysInMonth - 1) % 7;
            for (let i = lastDayOfWeek + 1; i < 7; i++) {
                week[i] = { 
                day: i - lastDayOfWeek, 
                isCurrentMonth: false, 
                dayName: dayNames[i]
                };
            }

            if (week.some(day => day !== null)) {
                weeks.push(week);
            }

            return weeks;
        }
    },
    methods:{
        selectMonth(month) {
            this.selectedMonth = month;
            this.showMonthPicker = false;
            this.showYearPicker = false;
        },
        toggleMonthPicker() {
            this.showMonthPicker = !this.showMonthPicker;
            this.showYearPicker = false;
        },
        toggleYearPicker() {
            this.showYearPicker = !this.showYearPicker;
            this.showMonthPicker = false;
        },
        selectDate(date) {
            this.selectedDate = parseInt(date);
            let selectedDate = new Date(this.selectedYear, this.selectedMonth, this.selectedDate);

            // Получаем день недели выбранной даты (0 для воскресенья, 6 для субботы)
            let dayOfWeek = selectedDate.getDay();

            // Вычисляем разницу в днях до начала недели (предполагаем, что неделя начинается с понедельника)
            let startOfWeek = new Date(selectedDate);
            startOfWeek.setDate(selectedDate.getDate() - ((dayOfWeek + 6) % 7));

            // Вычисляем конец недели (6 дней после начала недели)
            let endOfWeek = new Date(startOfWeek);
            endOfWeek.setDate(startOfWeek.getDate() + 6);

            // Форматируем даты
            let formatDate = (date) => `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;

            this.formattedDate = `${formatDate(startOfWeek)} - ${formatDate(endOfWeek)}`;

            this.$emit('picked', this.formattedDate)
            this.showPicker = false;
        },
        selectYear(year) {
            this.selectedYear = year;
            this.showYearPicker = false;
        },
        isSelected(date) {
            return parseInt(date) === this.selectedDate;
        },
        isToday(date) {
            return (
                parseInt(date) === this.today.date &&
                this.selectedMonth === this.today.month &&
                this.selectedYear === this.today.year
            );
        },
        isDisabled(day) {
            return day && !day.isCurrentMonth;
        }
    },
    created() {
        this.selectedMonth = new Date().getMonth();
        this.selectedYear = new Date().getFullYear();

        let selectedDate = new Date();

        let dayOfWeek = selectedDate.getDay();

        let startOfWeek = new Date(selectedDate);
        startOfWeek.setDate(selectedDate.getDate() - ((dayOfWeek + 6) % 7));

        let endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);

        let formatDate = (date) => `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;

        this.formattedDate = `${formatDate(startOfWeek)} - ${formatDate(endOfWeek)}`;

        this.$emit('picked', this.formattedDate)
    },
}
</script>

<style scoped>
.date-picker{
    position: relative;
    z-index: 30;
}
.date-block{
    position: absolute;
    top: 46px;
    filter: drop-shadow(0 0 10px rgb(231, 231, 231));
}
.choose-block{
  width: 200px;
  height: 36px;
}
.calendar_picker{
  width: 320px;

  padding: 0.75rem;
  background: #ffffff;
  color: #334155;
  border: 1px solid #cbd5e1;
  border-radius: 6px;
}
.calendar_picker_header{
  display: flex;
  align-items: center;
  justify-content: center;
}
.calendar_btn{
  background: transparent;
  color: #535C69;
  font-size: 16px;
}
.divider {
  border-bottom: 1px solid rgba(50, 56, 74, 0.1); 
  width: auto;
  margin: 10px 0;
}
table td {
  padding: 0.25rem;
  width: 40px;
  height: 40px;
}
.span_table{
  display: inline-block;
  line-height: 2rem;
  width: 2rem;
  height: 2rem;
  border-radius: 43%;
  transition: all 0.2s ease;
  cursor: pointer;
}
.span_table:hover {
  background: #e2e8f0;
}
.days_span{
  font-family: TT Norms Medium; 
  font-weight: normal;
}
.p-datepicker-today .span_table {
  background-color: #e2e8f0;
}
.month{
  padding: 0.5rem 0 0.5rem 0;
  width: 33.3%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  font-size: 16px;
  transition: all 0.2s ease;
  border-radius: 6px;
}
.month:hover{
  background: #e9eff7;
}
.year{
  width: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  padding: 0.5rem 0 0.5rem 0;
  border-radius: 6px;
  transition: all 0.2s ease;
}
.year:hover{
  background: #e9eff7;
}
.selected{
  color: #FFFFFF !important;
  background: #FFB525 !important;
}

.p-datepicker-today{
  color: #B7B7B7;
  background: #F5F6F8;
}
.p-disabled {
  color: #ccc;
}

.date-input{
  position: relative;
  background-color: #F3F5F6;
  border-radius: 3px;
  padding-left: .8em;
  cursor: pointer;
  height: 36px;
  text-align: left;
  line-height: 36px;
  font-family: TT Norms Medium;
  font-size: 13px;
  color: rgb(210, 216, 222);
}
.date-input::after {
  position: absolute;
  content: url('../../static/img/calendar-gray.svg');
  color: #535C69;
  top: 2px;
  right: 1em;
}

</style>