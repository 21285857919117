<template>
  
  <div class="main">
    <div v-if="first">
      <ModalBranchPage/>
    </div>
    <div class="transition">
      <router-link to="/dashboard/branch" class="employesss-link">Филиалы</router-link>
      <div class="arrow-container">
        <img src="../../static/img/arrow-right.png" alt="Стрелка вправо" class="arrow-icon">
      </div>
      <p class="employesss-link">{{ selectedName }}</p>
      <div class="arrow-container">
        <img src="../../static/img/arrow-right.png" alt="Стрелка вправо" class="arrow-icon">
      </div>
      <p class="creation_text">Редактирование</p>
    </div>
    <div class="main_group" >
      <div class="create_branch">
        <form class="branch-form"  v-if="step==1" enctype="multipart/form-data">
          <div class="one-group">
            <div class="form-group">
              <label for="country">Страна</label>
              <SelectPage
              :options="['Россия', 'Казахстан','Украина','Таджикистан','Кыргызстан']"
              class="select"
              @input="option => selectedCountry = option"
              :placeholderdata="'Выберите страну'"
              :class="{ 'select-error': v$.selectedCountry.$error }"
              :searchable=true
              :value="selectedCountry"
              />
            </div>
  
            <div class="form-group">
              <label for="city">Город</label>
              <input type="text" v-model="selectedCity" placeholder="Добавьте город"
              :class="{ 'input-error': v$.selectedCity.$error }">
            </div>
          </div>
  
          <div class="one-group">
            <div class="form-group">
              <label for="address">Адрес</label>
              <input type="text" id="address" v-model="selectedAdress" placeholder="Введите точный адрес"
              :class="{ 'input-error': v$.selectedAdress.$error }">
            </div>
            <div class="form-group" style="flex-direction: row; gap: 5px;">      
                  <div class="card flex justify-content-center">
                    <label>Регион</label>
                    <SelectWithFlag
                    :options="countries"
                    class="select"
                    @input="option => selectedCountryPhone = option"
                    :placeholderdata="'🇷🇺'"
                    style="width: 70px;"
                    :value="selectedCountryPhone"
                    />
                  </div>
                  <div class="form-group">
                    <label>Телефон</label>
                    <InputMaskComponent :class="{'PhoneError' : v$.value.$error}" id="basic" v-model="value" :mask="computedMask" :placeholder="computedPlaceholder"/>
                  </div>
            </div>
          </div>
          
  
          <div class="form-group">
            <label for="branchName">Название филиала</label>
            <input type="text" id="branchName" v-model="selectedName" placeholder="Введите название" maxlength="25"
            :class="{ 'input-error': v$.selectedName.$error }">
            <p class="character-limit">Название должно содержать не более 25 знаков</p>
          </div>
  
          <div class="form-group">
            <div class="usluga-head">
              <label for="upload-image ">Фото филиала</label>
              <Tip :Tip="'Мы рекомендуем загрузить наиболее удачные фотографии. \n Первая фотография будет размещена в шапке виджета, остальные \n фотографии будут видны в виджете вашим клиентам'"/>
            </div>
            <label class="custom-file-upload">
              <input type="file" accept="image/*" id="upload-image" @change="handleImageUpload" multiple> Нажмите, чтобы добавить
            </label>
            <p class="photo-info">до 5 МБ, PNG, JPG, JPEG. Для замены удалите миниатюру и загрузите заново</p>
          </div>
  
          <div class="upload">
            <div class="upload_img" v-for="(image, index) in uploadedImages" :key="index">
              <img @click="delete_img(index)" class="upl_img" :src="image.url" :alt="image.name" >
            </div>
          </div>
  
          <div class="form-group graffic">
            <div class="usluga-head">
              <label>График работы</label>
              <Tip :Tip="'График работы это график работы, логично \n (нет описания на фигме)'"/>
            </div>
            <div class="days">
                <button class="btn_day" :class="{ 'active': isBtnActive('Пн'),'button-days-error' : v$.selectedDays.$error}" @click="toggleBtn('Пн')">Пн</button>
                <button class="btn_day" :class="{ 'active': isBtnActive('Вт'),'button-days-error' : v$.selectedDays.$error}" @click="toggleBtn('Вт')">Вт</button>
                <button class="btn_day" :class="{ 'active': isBtnActive('Ср'),'button-days-error' : v$.selectedDays.$error}" @click="toggleBtn('Ср')">Ср</button>
                <button class="btn_day" :class="{ 'active': isBtnActive('Чт'),'button-days-error' : v$.selectedDays.$error}" @click="toggleBtn('Чт')">Чт</button>
                <button class="btn_day" :class="{ 'active': isBtnActive('Пт'),'button-days-error' : v$.selectedDays.$error}" @click="toggleBtn('Пт')">Пт</button>
                <button class="btn_day" :class="{ 'active': isBtnActive('Сб'),'button-days-error' : v$.selectedDays.$error}" @click="toggleBtn('Сб')">Сб</button>
                <button class="btn_day" :class="{ 'active': isBtnActive('Вс'),'button-days-error' : v$.selectedDays.$error}" @click="toggleBtn('Вс')">Вс</button>
            </div>
          </div>
          <div class="form-btn">  
            <div class="continue-button-container">
              <div class="steps-progress">
                <div class="divider"></div>
                <div class="divider-two"></div>
              </div>
              <p class="steps-text">Шаг 1 из 2</p>
            </div>

            <button class="btn" @click.prevent="onContinueButtonClick()">Продолжить</button>
          </div>
        </form>
        <div class="next-page" v-if="step==2">
          <div class="form-container">
            <div class="one-group">
              <div class="form-group">
                <label for="country">Рабочие часы</label>
                <SelectPage
                :customValue = true
                :options="['9:00 — 19:00', '9:00 — 20:00', '9:00 — 21:00', '10:00 — 18:00','10:00 — 19:00','10:00 — 20:00', '10:00 — 22:00']"
                class="select"
                @input="option => selectedWorkHours  = option"
                :placeholderdata="'Выберите время'"
                :class="{ 'select-error': v$.selectedWorkHours.$error }"
                :value="selectedWorkHours"
                />
              </div>
    
              <div class="form-group">
                <div class="usluga-head">
                  <label for="city">Перерыв</label>
                </div>
                <SelectPage
                :customValue = true
                :options="['Без перерыва']"
                class="select"
                @input="option => selectedTimeout = option"
                :placeholderdata="'Выберите время'"
                :class="{ 'select-error': v$.selectedTimeout.$error }"
                :value="selectedTimeout"
                />
              </div>
            </div>
            <div class="types-container">
              <label style="margin-bottom:10px">Выберите тип бизнеса</label>
              <div class="types">
                <div v-for="t in businessTypes" :key="t.name" class="choice" @click="selectedBusType = t.id">
                  <div class="circle" :style="{ borderColor: selectedBusType == t.id ? '#6266EA' : '' }">
                    <div class="second_circle" v-if="selectedBusType == t.id"></div>
                  </div>
                  <p class="choice_text" :style="{ color: selectedBusType == t.id ? '#535C69' : '#AFB6C1' }">{{ t.name }}</p>
                </div>
              </div>
            </div>
            <div class="dropdown-container">
              <label for="service">Сфера бизнеса</label>
              <SelectPage
              :options="this.spheres"
              @input="option => selectedBussphere = option"
              :placeholderdata="'Выберите сферу бизнеса'"
              :searchable=true
              :value="Number.isInteger(this.selectedBussphere) ? (spheres.find(sphere => sphere.id === selectedBussphere)) : selectedBussphere"
              />
            </div>
            <div class="dropdown-container">

              <!-- Дебажил сотрудников -->

              <p>{{ this.employees.map(item => ({
                name: item.firstname + ' ' + item.secondname,
                id: item.id,
              })) }}</p>
              
              <p>{{ filteredChips }}</p>

              <p>{{ chips }}</p>

              <!-- Конец -->

              <label for="service">Выберите сотрудников для этого филиала</label>
              <SelectPage
              :options="this.employees.map(item => ({
                          name: item.firstname + ' ' + item.secondname,
                          id: item.id,
                        }))"
              class="select"
              @input="handleSelectInput"
              :placeholderdata="'Выберите сотрудников'"
              :class="{ 'select-error': v$.filteredChips.$error }"
              :searchable=true
              :value="filteredChips.map(chip => chip.name).join(', ')"
              />
            </div>
            <div class="chips-block">
              <div class="chip" v-for="chip in filteredChips" :key="chip.id">
                <svg @click="deleteChip(chip)" width="8" height="8" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.29294 3.00003L0.146484 5.14648L0.853591 5.85359L3.00004 3.70714L5.1465 5.85359L5.85361 5.14648L3.70715 3.00003L5.85359 0.853591L5.14648 0.146484L3.00004 2.29292L0.853605 0.146484L0.146499 0.853591L2.29294 3.00003Z" fill="white"/>
                </svg>
                <p>{{ chip.name }}</p>
              </div>
  
            </div>
            <div class="steps">
              <div class="second-steps-container">
                <div class="steps-progress">
                  <div class="second-divider"></div>
                  <div class="second-divider-two"></div>
                </div>
                <p class="steps-text">Шаг 2 из 2</p>
              </div>
              <div class="btn-container">
                <button class="back" @click="onBackClick">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.3999 6.96667L4.8999 3.5V6.3H13.2999V7.7H4.8999V10.5L1.3999 6.96667Z" fill="#535C69"/>
                  </svg> 
                  Вернуться
                </button>
                <button class="btn" @click="Finish">Завершить</button>
              </div>
            </div>
          </div>  
        </div>
      </div>
      <div class="adaptive_window">
        <div v-if="selectedName">
          <p class="header">{{selectedName}}</p>
          <p class="descr">Формат оплаты</p>
        </div>
        <div v-else class="second">
          <div class="stripe" style="width: 109px;"></div>
          <div class="stripe" style="width: 63px;"></div>
        </div>
        <img v-if="uploadedImages.length>0" :src="uploadedImages[0].url" alt="Branch Photo" style="width:auto;height:200px;border-radius:2px;">
        <img v-else-if="cover" :src="cover" alt="Branch Photo" style="width:auto;height:200px;border-radius:2px; object-fit: cover;">
        <div class="img_container" v-else>
          <img class="img_window"  src="../../static/img/service.svg" alt="">
        </div>
        <div v-if="selectedAdress">
          <p class="header">{{selectedAdress}}</p>
          <p class="descr">Адрес</p>
        </div>
        <div v-else class="first">
          <div class="stripe" style="width: 143px;"></div>
          <div class="stripe" style="width: 97px;"></div>
        </div>
        <div v-if="value.length > 0" >
          <p class="header">{{value}}</p>
          <p class="descr">Телефон</p>
        </div>
        <div v-else class="first">
          <div class="stripe" style="width: 143px;"></div>
          <div class="stripe" style="width: 97px;"></div>
        </div>
        <div v-if="selectedWorkHours">
          <p class="header">{{selectedWorkHours}}</p>
          <p class="descr">Рабочие часы</p>
        </div>
        <div v-else class="first">
          <div class="stripe" style="width: 143px;"></div>
          <div class="stripe" style="width: 97px;"></div>
        </div>
        <div v-if="selectedDays.length>0">
          <p class="header">{{sortedDays()}}</p>
          <p class="descr">График работы</p>
        </div>
        <div v-else class="first">
          <div class="stripe" style="width: 143px;"></div>
          <div class="stripe" style="width: 97px;"></div>
        </div>
      </div>
    </div>
    <MessageAlert :message="alertMessage" :color="alertColor"/>
  </div>
</template>

<script>
import SelectPage from '../components/SelectPage.vue';
import MessageAlert from "../components/MessageAlert.vue";
import Tip from '../components/TipComponent.vue';
import ModalBranchPage from '../components/ModalBranchPage.vue';
import SelectWithFlag from '../components/SelectWithFlag.vue';


import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  components: { SelectPage, MessageAlert, Tip, ModalBranchPage, SelectWithFlag},
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      alertMessage: null,
      alertColor: '',
      selectedDays: [],

      showContinueButtonClicked: false,
      businessTypes: [],
      spheres: [],
      uploadedImages: [],
      selectedEmployeeId: [],
      chips: [],

      first: false,
      selectedCountryPhone: { name: ' ', code: '+7', flag: require('@/images/flag/russia.png')},
      value: '', // Начальное значение для InputMaskComponent
      countries: [
        { name: ' ', code: '+7', flag: require('@/images/flag/russia.png') },
        { name: ' ', code: '+375', flag: require('@/images/flag/belarusi.png') },
        { name: ' ', code: '+7', flag: require('@/images/flag/kazahstan.png') },
        { name: ' ', code: '+380', flag: require('@/images/flag/ukraina.png') },
      ],

      fileNameVariable: '',
      selectedCity: '',
      selectedCountry: '',
      selectedAdress: '',
      selectedName: '',
      selectedWorkHours: '',
      selectedTimeout: '', 
      selectedBusType: null,
      selectedBussphere: null,

      step: 1,

      imageURL: null,
    }
  },
  validations () {
    return {
      selectedName: { required },
      selectedCity: { required },
      selectedCountry: { required },
      selectedAdress: { required },
      selectedDays: { required },
      value: { required },

      selectedWorkHours: { required },
      selectedTimeout: { required },
      filteredChips: { required },
    }
  },
  mounted(){
    this.get_workers()
    this.get_buisnessTypes()
    this.get_buisnesssphere()
    this.getfilial(this.$route.params.branchToEditId)
  },
  computed: {
    computedMask() {
      if (this.selectedCountryPhone) {
        const countryCode = this.selectedCountryPhone.code;
        if (countryCode === '+375' || countryCode === '+380') {
          return `${countryCode} (99) 999-99-99`;
        } else {
          return `${countryCode} (999) 999-99-99`;
        }
      } else {
        return '+7 (999) 999-99-99'; // Default mask
      }
    },
    computedPlaceholder() {
      return this.selectedCountryPhone ? this.selectedCountryPhone.code + ' |' : '+7 |';
    },

    filteredChips() {
      // Начинаем с индекса 1 (второй элемент) и возвращаем оставшиеся элементы
      // return this.chips.slice(0, -1);
      return this.chips;
    }
  },
  methods: {
    
    async  editBranch(branchId, updatedData) 
    {
      try 
      {
        const response = await this.$axios.patch(`/api/branch/${branchId}/edit/`, updatedData, {withCredentials: true});
        console.log(response.data);
        this.alertMessage = 'Настройки успешно сохранены'
        this.alertColor = '#0BB6A1'
        setTimeout(() => {
          this.$router.go(-1);
        }, 2000)
      }
      catch (error) 
      {
        this.alertMessage = 'Произошла ошибка при редактировании филиала'
        this.alertColor = '#F97F7F'
        setTimeout(() => {
          this.$router.go(-1);
        }, 2000)
        console.error('Error editing branch:', error);
        throw error;
      }
    },

    async getfilial(i) {
      try {
          const id = i;
          const response = await this.$axios.get(`/api/get_filialbyid/?variable=${id}`);
          console.log(response.data)

          this.selectedCountry = response.data.country
          this.selectedCity = response.data.city
          this.selectedAdress = response.data.address
          this.value = response.data.phone
          this.selectedName = response.data.name
          this.selectedCountry = response.data.country
          this.cover = response.data.images[0].image
          this.selectedBusType = response.data.bus_type
          this.selectedBussphere = response.data.bus_sphere

          const daysArray = response.data.active_days.split(',').map(day => day.trim());
          daysArray.forEach(day => {
            this.selectedDays.push(day);
          });

          let employeesID = response.data.employees_id.split(',').map(Number)
          console.log(employeesID)
          for (let index = 0; index < employeesID.length; index++) {
            let e = await this.getemployee(employeesID[index])
            console.log(e)
            this.chips.push({ name: e.firstname + ' ' + e.secondname, id: e.id })
            console.log(this.chips)
          }

          this.selectedWorkHours = response.data.work_hours
          this.selectedTimeout = response.data.timeout

          this.selectedWorkHours = response.data.work_hours

      } catch (error) {
          console.error('Ошибка при получении данных о Филиале:', error);
          throw error; // throw error, чтобы предоставить возможность обработки ошибки вверх по стеку вызовов
      }
  },
  async getemployee(i) {
        try {
            const id = i;
            const response = await this.$axios.get(`/api/get_employeebyid/?variable=${id}`);
            console.log(response)
            return response.data;
        } catch (error) {
            console.error('Ошибка при получении данных о Сотруднике:', error);
            throw error; // throw error, чтобы предоставить возможность обработки ошибки вверх по стеку вызовов
        }
    },
    sortedDays() {
          const order = {
            "Пн": 1,
            "Вт": 2,
            "Ср": 3,
            "Чт": 4,
            "Пт": 5,
            "Сб": 6,
            "Вс": 7
          };

          const sorted = this.selectedDays.sort((a, b) => order[a] - order[b]);
          return sorted.join(", ");
        },

    deleteChip(chip){
      let indexToRemove = this.chips.indexOf(chip);
      if (indexToRemove !== -1) {
        this.chips.splice(indexToRemove, 1);
      }
    },
    delete_img(index){
      this.uploadedImages.splice(index, 1)
    },

    handleImageUpload(event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const imageURL = e.target.result;
          const fileName = files[i].name;
          const fileFormat = fileName.split('.').pop(); // Получаем расширение файла
          this.uploadedImages.push({ name: fileName, format: fileFormat, url: imageURL });
        };
        reader.readAsDataURL(files[i]);
      }
    },

    get_workers(){
      this.$axios.post('/api/getworkers/', { user_id:  this.$store.state.registrationData.user_id, project: this.$store.state.activeProjectId}, {withCredentials: true})
      .then(response => {
        console.log(response.data,'222222222222222222222222222222222222222222')
        this.employees = response.data;
      })
      .catch(error => {
        // Ошибка при получении данных
        console.error('Ошибка при получении данных о get_workers:', error);
      });
    },
    get_buisnessTypes(){
      this.$axios.get('/api/get_buisnessTypes/')
      .then(response => {
        console.log(response.data)
        this.businessTypes = response.data
      })
      .catch(error => {
        // Ошибка при получении данных
        console.error('Ошибка при получении данных о get_buisnessTypes:', error);
      });
    },

    get_buisnesssphere(){
      this.$axios.get('/api/get_buisnessSphere/')
      .then(response => {
        console.log(response.data)
        this.spheres = response.data
      })
      .catch(error => {
        // Ошибка при получении данных
        console.error('Ошибка при получении данных о get_buisnesssphere:', error);
      });
    },

    handleSelectInput(selected) {
      const existingChip = this.chips.find(chip => chip.name === selected.name && chip.id === selected.id);
      if (!existingChip && selected.name) {
        this.chips.push({ name: selected.name, id: selected.id });
      }
    },


    isBtnActive(day) {
      return this.selectedDays.includes(day);
    },
    toggleBtn(day) {
      event.preventDefault();
      if (this.isBtnActive(day)) {
        this.selectedDays = this.selectedDays.filter(activeDay => activeDay !== day);
      } else {
        this.selectedDays.push(day);
      }
    },
    onContinueButtonClick() {

      const fieldsToValidate = [
        'selectedName',
        'selectedCity',
        'selectedCountry',
        'selectedAdress',
        'selectedDays',
        'value'
      ];

      fieldsToValidate.forEach(field => {
        this.v$[field].$touch();
      });

      const hasErrors = fieldsToValidate.some(field => this.v$[field].$error);

      if (hasErrors) {
        this.step = 1;
        // 'Пожалуйста, заполните выделенные поля'
        this.alertMessage = 'Пожалуйста, заполните выделенные поля';
        this.alertColor = '#F97F7F';
        setTimeout(() => {
          this.alertMessage = '';
        }, 100);
      } else {
        this.step = 2;
      }
    },
    onBackClick() {
      this.step = 1;
    },

    Finish() {
      this.v$.$validate()

      if (this.v$.$error) {
        // 'Пожалуйста, заполните выделенные поля'
        this.alertMessage = 'Пожалуйста, заполните выделенные поля';
        this.alertColor = '#F97F7F';
        setTimeout(() => {
          this.alertMessage = '';
        }, 100);
      } else {
        const formData = new FormData();
    
        formData.append('country', this.selectedCountry);
        formData.append('city', this.selectedCity);
        formData.append('address', this.selectedAdress);
        formData.append('name', this.selectedName);
        formData.append('active_days', this.selectedDays);
        formData.append('work_hours', this.selectedWorkHours);
        formData.append('timeout', this.selectedTimeout);
        formData.append('bus_type', this.selectedBusType);


        formData.append('bus_sphere', this.selectedBussphere.id);


        formData.append('user_id', this.$store.state.registrationData.user_id)
        formData.append('phone', this.value)
        formData.append('project', this.$store.state.activeProjectId)
        
        if (this.uploadedImages) {
          for (let i = 0; i < this.uploadedImages.length; i++) {
            // Преобразовываем изображение в объект типа File
            const file = this.dataURItoBlob(this.uploadedImages[i].url);
            formData.append('images[]', file, this.uploadedImages[i].name); // Передаем файл, его имя и формат
          }
        }
        


        let idString = "";
        this.filteredChips.forEach(chip => {
            idString += chip.id + ",";
        });
        idString = idString.slice(0, -1);

        formData.append('employees', idString)
        // Добавляем каждое изображение в FormData
      
        console.log(formData);
        // Отправка данных на сервер для создания филиала
        
        this.editBranch(this.$route.params.branchToEditId, formData)
      }
},

// Метод для преобразования Data URI в Blob объект
dataURItoBlob(dataURI) {
  const byteString = atob(dataURI.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/jpeg' }); // Укажите нужный MIME тип, если изображения не JPEG
},
  }
}
</script>

<style scoped>
.adaptive_window{
  background-color: #FFFFFF;
  width: 400px;
  height: auto;
  padding: 20px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.img_container{
  align-items: center;
  max-width: 365px;
  height: 200px;
  display: flex;
  justify-content: center;
  background: linear-gradient(90deg, #F6F6F6 0%, #F1F4F9 100%);
  border-radius: 5px;
}
.img_window{
  width:58px;
  height:58px;
  border-radius:2px;
}
.main_group{
  display: flex;
  gap: 40px;
}
.header{
  font-family: 'TT Norms Medium';
  font-size: 22px;
  line-height: 22px;
  text-align: left;
  color: #535C69;
  margin: 0;
}
.first{
  width: 220px;
  height: 50px;
  border-radius: 2px;
  background: linear-gradient(90deg, #F6F6F6 0%, #F1F4F9 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 10px;
}
.stripe {
  height: 10px;
  background: linear-gradient(90deg, #EBEBEB 0%, #DAE2EE 100%);
  border-radius: 2px;
}
.second{
  width: 155px;
  height: 50px;
  border-radius: 2px;
  background: linear-gradient(90deg, #F6F6F6 0%, #F1F4F9 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 10px;
}
.third{
  width: 92px;
  height: 36px;
  border-radius: 2px;
  background: linear-gradient(90deg, #F6F6F6 0%, #F1F4F9 100%);
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 10px;
}
.descr{
  color: #AFB6C1;
  font-family: 'TT Norms Medium';
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  margin: 5px 0 0 0;
}
.usluga-head{
  display: flex;
  justify-content: start;
  gap: 10px;
  align-items: center;
  margin-bottom: 5px;
}

.usluga-head label{
  margin: 0;
}
.PhoneError{
  border: 1px solid #F97F7F !important;
  border-radius: 3px;
}
.button-days-error{
  border: 1px solid #F97F7F !important;
  border-radius: 3px;
}
.select-error >>> .selected{
  border: solid 1px #F97F7F !important;
}
.input-error {
  border: 1px solid #F97F7F !important;
}
.chip svg:hover path{
    fill: rgb(250, 148, 148);
  }
  .chips-block{
    width: 100%;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
  }

  .chip{
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: start;
    background-color: #6266EA;
    height: 20px;
    padding: 0 15px;
    border-radius: 10px;
    transition: all .2s ease;
  }

  .chip:hover{
    background-color: #5357c7;
    cursor: pointer;
  }

  .chip p{
    margin: 0;
    color: white;
    font-family: 'TT Norms';
    font-size: 12px;
    margin-top: -1.5px;
  }
  .create_branch {
    width: 600px;
    height: fit-content;
    background-color: #FFFFFF;
    padding: 20px;
    border-radius: 5px;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
  }

  input {
    padding: 8px 10px;
    font-family: TT Norms Medium;
    font-size: 14px;
    line-height: 20px;
    color: #535C69;
    border: none;
    background-color: #F3F5F6;
    margin-bottom: 10px;
    height: 36px;
    margin-bottom: 5px;
  }

  select{
    border-radius: 3px;
  }

  p {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #D2D8DE;
    margin: 0;
  }

  .schedule-buttons {
    display: flex;
    gap: 10px;
    
  }

  .schedule-buttons button {
    background-color: #F3F5F6;
    color: #D2D8DE;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }

  .schedule-buttons button:hover {
    background-color: #6266EA;
    color: #EFEFFF;
  }

  .schedule-buttons button.active {
    background-color: #6266EA;
    color: #EFEFFF;
  }

  .one-group {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .btn {
    background-color: #EFEFFF;
    color: #6266EA;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }

  .btn:hover {
    background-color: #6266EA;
    color: #EFEFFF;
  }

  input::placeholder {
    font-family: "TT Norms Medium";
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0em;
    color: #D2D8DE;
  }
  .custom-file-upload {
    width: 100%;
    height: 36px;
    display: flex;
    padding: 8px 10px;
    cursor: pointer;
    background-color: #F3F5F6;
    color: #D2D8DE;
    align-items: center;
    background-image: url(../../static/img/paperclip.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 15px) center;
  }
  
  .custom-file-upload input[type="file"] {
    display: none;
  }
  .photo-info{
    color: #AFB6C1;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0em;
  }
  .transition {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .employesss-link {
    font-family: TT Norms Medium;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    text-decoration: none;
    color: #AFB6C1;
  }
  
  .creation_text {
    color: #535C69;
    font-family: TT Norms Medium;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
  }
  
  .arrow-container {
    display: flex;
    align-items: center;
  }
  
  .arrow-icon {
    height: 50%;
  }
  .divider {
    border-bottom: 3px solid #6266EA; 
    width: 80px;
    border-radius: 100px;
  }
  .divider-two {
    border-bottom: 3px solid #D8DDE3;
    width: 50px;
    border-radius: 100px;
    margin: 10px 0;
  }
  .steps-progress{
    display: flex;
    gap: 5px;
  }
  .steps-text{
    text-align: left;
    font-family: TT Norms Medium;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0em;
    color: #535C69;
    margin-bottom: 0;
  }
  .continue-button-container {
    display: flex;
    flex-direction: column;
  }
  .branch-form{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .form-btn{
    display: flex;
    justify-content: space-between;
  }
  .second-divider {
    border-bottom: 3px solid #6266EA; 
    width: 122px;
    border-radius: 100px;
  }
  .second-divider-two {
    border-bottom: 3px solid #D8DDE3;
    width: 10px;
    border-radius: 100px;
  }
  .back{
    padding: 10px, 14px, 10px, 14px;
    border-radius: 3px;
    border: 1px solid #DDE1E5;
    gap: 5px;
    color: #535C69;
    background-color: #FFFFFF;
  }
  .btn-container{
    display: flex;
    gap: 10px;
    margin-left: auto;
  }
  .steps{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    box-sizing: border-box;
  }
  select {
    padding: 10px;
    font-family: TT Norms Medium;
    font-size: 14px;
    line-height: 20px;
    color: #535C69;
    border: none;
    background-color: #F3F5F6;
    border-radius: 3px;
  }
  
  select option {
    font-family: TT Norms Medium;
    font-size: 16px;
    line-height: 20px;
    color: #535C69;
  }
  select#service {
    width: 100%;
    padding: 10px;
    font-family: TT Norms Medium;
    font-size: 14px;
    line-height: 20px;
    color: #535C69;
    border: none;
    background-color: #F3F5F6;
  }
  .types{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .choice{
    display: flex;
    gap: 5px;
  }
  .circle{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    border: 1px solid #C6CBD2;
    background: #F3F5F6;
    border-radius: 15px;
    cursor: pointer;
  }
  .choice_text{
    font-family: TT Norms Medium;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    color: #AFB6C1;
    cursor: pointer;
  }
  .back{
    display: flex;
    gap: 5px;
    color: #D2D8DE;
    background: #FFFFFF;
    border: 1px solid #DDE1E5;
  }
  .back:hover{
    color: #6266EA;
  }
  .second_circle{
    background: #6266EA;
    width: 10px;
    height: 10px;
    border-radius: 25px;
  }

  .upload{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  .upload_img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
  }
  .upl_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .upl_img:hover {
    cursor: pointer;
    filter: brightness(50%);
  }

  .graffic_label{
    margin: 0;
  }
  .graffic{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .btn_day{
    font-family: TT Norms Medium;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    box-sizing: border-box;
    background-color: #FFFFFF;
    color: #535C69;
    border-radius: 3px;
    border: 1.5px solid #DDE1E5;
    transition: all .2s ease;
  }

  .btn_day:hover{
    border: 1.5px solid #535C69;
  }
  .days{
    display: flex;
    gap: 10px;
  }
  .btn_day.active {
    box-sizing: border-box;
    background-color: #FFFFFF;
    color: #535C69;
    border-radius: 3px;
    border: 1.5px solid #6266EA;
    background-color: rgba(98, 102, 234, 0.1);
  }
  input:focus {
    outline: none;
    border: 1px solid #6266EA;
  }
  .second-steps-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  @media (max-width: 1340px){
    .adaptive_window{
      display: none;
    }
  }
  @media (max-width: 1280px){
    .create_branch{
      width: 100%;
    }
  }
  @media (max-width: 768px){
    .main{
      margin: 20px;
    }
    .days{
      flex-wrap: wrap;
    }
  }
  @media (max-width: 568px){
    .main{
      height: 89vh;
      overflow: scroll;
    }
    .employesss-link{
      font-size: 16px;
    }
    .creation_text{
      font-size: 16px;
    }
    .one-group{
      flex-direction: column;
    }
    .form-btn{
      flex-direction: column;
      gap: 10px;
    }
    .steps-progress{
      justify-content: flex-start;
    }
    .divider{
      margin: 10px 0;
    }
    .btn{
      width: 100%;
    }
    .steps{
      flex-direction: column;
      gap: 10px;
    }
    .btn-container{
      flex-direction: column;
      margin: 0;
      width: 100%;
    }
  }
</style>