<template>
  <div class="main">
    <div class="transition">
      <router-link to="/dashboard/project" class="eidt-link">Мои проекты</router-link>
      <div class="arrow-container">
        <img src="../../static/img/arrow-right.png" alt="Стрелка вправо" class="arrow-icon">
      </div>
      <p class="creation_text">Новый проект</p>
    </div>
    <div class="edit">
      <div class="head_container">
        <p class="header_text">Создание проекта</p>
        <p class="subheader_text">Название проекта не должно превышать 25 знаков</p>
      </div>
      <div class="form">
        <div class="form_container">
          <label for="">Название проекта</label>
          <input maxlength="25" type="text" placeholder="Введите название проекта" v-model="companyName">
          <p class="small-text">Для ввода доступно — <span class="remaining-characters">{{ remainingCharacters }}</span>/25</p>
        </div>
        <div class="form_container">
          <label for="service">Часовой пояс</label>
          <SelectPage
          :options="['0 — Лондон, Дублин', '+1 — Париж, Рим', '+2 — Афины, Каир', '+3 — Москва, Стамбул', '+4 — Дубай, Новосибирск', '+5 — Астана, Ташкент', '+6 — Омск, Бишкек', '+7 — Бангкок, Джакарта', '+8 — Гонконг, Сингапур', '-8 — Лос-Анджелес, Ванкувер', '-7 — Денвер, Эдмонтон', '-6 — Чикаго, Мехико', '-5 — Нью-Йорк, Монреаль', '-4 — Галифакс, Каракас', '-3 — Рио-де-Жанейро, Буэнос-Айрес']"
          @input="option => this.timezone = option"
          />
        </div>
        <div class="form_container">
          <label for="service">Валюта</label>
          <SelectPage
          :options="['RUB — Российский рубль', 'BYN — Белорусский рубль', 'USD — Доллар США', 'EUR — Евро', 'KZT — Казахстанский тенге', 'UAH — Украинская гривна', 'CNY — Китайский юань']"
          @input="option => this.currency = option"
          />
        </div>
      </div>
      <div class="picker">
        <p class="color_text">Цвет иконки проекта</p>
        <div class="color_container">
          <div class="color" @click="color = '#F3F5F6'" style="background:#F3F5F6"><img v-if="color == '#F3F5F6'" src="../../static/img/checkbox.png" alt=""></div>
          <div class="color" @click="color = '#7DCD37'" style="background:#7DCD37"><img v-if="color == '#7DCD37'" src="../../static/img/checkbox.png" alt=""></div>
          <div class="color" @click="color = '#28CCF0'" style="background:#28CCF0"><img v-if="color == '#28CCF0'" src="../../static/img/checkbox.png" alt=""></div>
          <div class="color" @click="color = '#9D8FF1'" style="background:#9D8FF1"><img v-if="color == '#9D8FF1'" src="../../static/img/checkbox.png" alt=""></div>
          <div class="color" @click="color = '#FB88C0'" style="background:#FB88C0"><img v-if="color == '#FB88C0'" src="../../static/img/checkbox.png" alt=""></div>
          <div class="color" @click="color = '#F97F7F'" style="background:#F97F7F"><img v-if="color == '#F97F7F'" src="../../static/img/checkbox.png" alt=""></div>
          <div class="color" @click="color = '#F7D37D'" style="background:#F7D37D"><img v-if="color == '#F7D37D'" src="../../static/img/checkbox.png" alt=""></div>
        </div>
      </div>

      <div class="bottom">
        <div class="bottom_container">
          <button class="save" @click="save">Сохранить</button>
          <button class="back" @click="handleCancel">Вернуться</button>
        </div>
      </div>
    </div>
    <VerifPage
    v-if="showModal"
    @confirm="toggleModal"
    @cancel="cancel"
    />
  </div>
</template>

<script>
import SelectPage from '../components/SelectPage.vue';
import VerifPage from '../components/VerifPage.vue';
import { mapMutations } from 'vuex';

export default {
  components: { SelectPage, VerifPage},
  data() {
    return {
      companyName: '',
      showModal: false,
      currency: '',
      timezone: '',
      color: '',
    };
  },
  computed: {
    remainingCharacters() {
      const maxLength = 25;
      return maxLength - this.companyName.length;
    },
  },
  methods: {
    handleCancel() {
        if (
          this.companyName !== ''
        ) {
          this.toggleModal();
        } else {
          this.cancel();
        }
      },
    cancel() {
      this.$router.go(-1);
    },
    ...mapMutations(['setUpdateSidebar']),
      rerenderSidebar() {
        this.setUpdateSidebar();
      },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    save(){
      const data = {
        name: this.companyName,
        currency: this.currency,
        timezone: this.timezone,
        user_id: this.$store.state.registrationData.user_id,
        colour: this.color
      };

      let projectCurrency;
      switch (this.currency) {
        case 'RUB — Российский рубль':
          projectCurrency = '₽'
          break;
        case 'BYN — Белорусский рубль':
          projectCurrency = 'Br'
          break;
        case 'USD — Доллар США':
          projectCurrency = '$'
          break;
        case 'EUR — Евро':
          projectCurrency = '€'
          break;
        case 'KZT — Казахстанский тенге':
          projectCurrency = '₸'
          break;
        case 'UAH — Украинская гривна':
          projectCurrency = '₴'
          break;
        case 'CNY — Китайский юань':
          projectCurrency = '¥'
          break;
        default:
          break;
      }

      // Выполняем POST запрос с использованием this.$axios
      this.$axios.post('/api/create_project/', data, {withCredentials: true})
        .then(response => {
          console.log('Успешно отправлено на сервер:', response.data);
          this.$store.commit('setActiveProjectCurrency', projectCurrency);
          this.$store.commit('setActiveProject', response.data.id);
          this.$router.push('/dashboard/project')
          this.rerenderSidebar();
        })
        .catch(error => {
          console.error('Ошибка при отправке на сервер:', error);
        });
    },
  },
};
</script>

<style scoped>
.transition {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
}
.eidt-link {
  font-family: TT Norms Medium;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  text-decoration: none;
  color: #AFB6C1;
}
.creation_text {
  color: #535C69;
  font-family: TT Norms Medium;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
}
.arrow-container {
  display: flex;
  align-items: center;
}
.arrow-icon {
  height: 50%;
}
.edit {
  width: 50%;
  height: auto;
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: 0px 2px 4px 0px #7D879033;
}
.form{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.head_container{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.form_container{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.header_text{
  font-family: TT Norms Medium;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #535C69;
  margin: 0;
}
.subheader_text{
  font-family: TT Norms Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #AFB6C1;
  margin: 0;
}
select {
  padding: 10px;
  font-family: TT Norms Medium;
  font-size: 14px;
  line-height: 20px;
  color: #D2D8DE;
  border: none;
  background-color: #F3F5F6;
  border-radius: 3px;
}
input{
  margin: 0;
}
select option {
  font-family: TT Norms Medium;
  font-size: 14px;
  line-height: 20px;
  color: #535C69;
}
select#service {
  width: 100%;
  padding: 10px;
  font-family: TT Norms Medium;
  font-size: 14px;
  line-height: 20px;
  color: #D2D8DE;
  border: none;
  background-color: #F3F5F6;
}
label{
  margin: 0;
}
.color_text{
  font-family: TT Norms Medium;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #52565F;
}
.color_container{
  display: flex;
  gap: 5px;
}
.color{
  width: 24px;
  height: 24px;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
}
.color img{
  position: absolute;
  top: 25%;
  left: 25%;
}
.bottom_container{
  display: flex;
  gap: 10px;
  width: 50%;
}
.bottom{
  display: flex;
  justify-content: space-between;
}
.save{
  background: #EFEFFF;
  color: #6266EA;
}
.back{
  color: #535C69;
  background: #FFFFFF;
  border: 1px solid #DDE1E5;
}
.save:hover{
  color: #FFFFFF;
  background: #6266EA;
}
.delete{
  color: #F97F7F;
  background: #F97F7F33;
}
.delete:hover{
  background: #F97F7F;
  color: #FFFFFF;
}
.small-text {
  color:#D2D8DE;
  font-family: "TT Norms Medium";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  margin: 0;
}
.small-text .remaining-characters {
  color: #6266EA;
}
.delete_edit{
  color: #F97F7F;
  background-color: rgba(249, 127, 127, 0.2);
}
.delete:hover{
  background: #F97F7F;
  color: #FFFFFF;
}
.exit{
  color: #535C69;
  border: 1px solid #DDE1E5;
  background: #FFFFFF;  
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}
.exit:hover{
  border: 1px solid #AFB6C1;
  background: #F5F5F5;
}
.btn_container{
  margin-top: 30px;
  display: flex;
  gap: 10px;
}
.text-header{
  font-family: TT Norms Medium;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0em;
  color: #535C69;
  margin: 0;
  text-align: left;
}
.back:hover{
  color: #6266EA;
}
</style>