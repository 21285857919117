<template>
  <label class="container">
    <input
      class="input"
      type="checkbox"
      :checked="flag"
      @change="toggle"
    />
    <span class="switch"></span>
  </label>
</template>

<script>
export default {
  name: "CustomSwitchPage",
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    flag: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    }
  },
  methods: {
    toggle() {
      this.flag = !this.flag;
    }
  }
};
</script>
  
  <style scoped>
  .container {
    cursor: pointer;
    display: flex;
    align-items: center;  
  }
  .label {
    margin-left: 12px;
    color: #1a202c;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .switch {
    --switch-container-width: 40px;
    --switch-size: calc(var(--switch-container-width) / 2);
    --light-gray: #DDE1E5;
    --gray: #cbd5e0;
    --dark-gray: #a0aec0;
    --teal: #6266EA;
    --dark-teal: #319795;
    display: flex;
    align-items: center;
    position: relative;
    height: var(--switch-size);
    flex-basis: var(--switch-container-width);
    border-radius: var(--switch-size);
    background-color: var(--light-gray);
    flex-shrink: 0;
    transition: background-color 0.25s ease-in-out;
  }
  .switch::before {
    content: "";
    position: absolute;
    left: 3px;
    height: calc(var(--switch-size) - 5px);
    width: calc(var(--switch-size) - 5px);
    border-radius: 9999px;
    background-color: white;
    transition: transform 0.2s ease-in-out;
  }
  .input:checked + .switch {
    background-color: var(--teal);
  }
  .input:checked + .switch::before {
    transform: translateX(
      calc(var(--switch-container-width) - var(--switch-size))
    );
  }
  .input:focus + .switch::before {
    border-color: var(--gray);
  }
  .input:focus:checked + .switch::before {
    border-color: var(--dark-teal);
  }
  .input:disabled + .switch {
    background-color: var(--gray);
  }
  .input:disabled + .switch::before {
    background-color: var(--dark-gray);
    border-color: var(--dark-gray);
  }
  </style>