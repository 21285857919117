<template>
  <div style="display: flex; overflow-y: scroll; height: 86vh;">
    <div class="main">
      <div class="transition">
        <router-link to="/dashboard/widgets" class="employesss-link">Виджеты</router-link>
        <div class="arrow-container">
          <img src="../../static/img/arrow-right.png" alt="Стрелка вправо" class="arrow-icon">
        </div>
        <p class="creation_text">Создание виджета</p>

      </div>
      <div class="card">
        <div>
          <div class="navigation">
            <div class="settings">
                <div class="forms">
                  <div class="name-container">
                    <div class="input-content">
                      <label for="widgetName">Название виджета</label>
                      <input :class="{ 'input-error': v$.widgetName.$error }" type="text" id="widgetName" placeholder="Мой виджет" v-model="widgetName">
                    </div>
        
                    <div class="input-content">
                      <label for="widgetLanguage">Язык виджета</label>
                      <SelectPage
                      :options="['Русский', 'Белорусский']"
                      class="select" 
                      @input="option => selectedLanguage = option"
                      :placeholderdata="'Выберите язык'"
                      :class="{ 'select-error': v$.selectedLanguage.$error }"
                    />
                    </div>
                  </div>
                  <div class="filial_container">
                    <div class="label_container">
                      <label for="widgetbranch">Филиалы</label>
                      <Tip :Tip="'Придумать текст.</span>'"/>
                    </div>
                    <SelectPage
                        :options="filials"
                        class="select" @input="handleSelectInput"
                        :placeholderdata="'Выберите филиалы'"
                        :class="{ 'select-error': v$.chips.$error }"
                        :value="filteredChips.map(chip => chip.name).join(', ')"
                        />
                        <div class="chips-block">
                          <div class="chip" v-for="chip in filteredChips" :key="chip.id">
                            <svg @click="deleteChip(chip)" width="8" height="8" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2.29294 3.00003L0.146484 5.14648L0.853591 5.85359L3.00004 3.70714L5.1465 5.85359L5.85361 5.14648L3.70715 3.00003L5.85359 0.853591L5.14648 0.146484L3.00004 2.29292L0.853605 0.146484L0.146499 0.853591L2.29294 3.00003Z" fill="white"/>
                            </svg>
                            <p>{{ chip.name }}</p>
                          </div>
                        </div>
                  </div>
              </div>
              <div class="img_container">
                <div class="text_container">
                  <p class="header">Обложка виджета</p>
                </div>
                <label class="custom-file-upload"  :class="{ 'input-error': v$.selectedImage.$error }" v-if="!fileNameVariable">
                  <input type="file" accept="image/*" @change="handleFileUpload($event)"/>Прикрепите фото 
                </label>
                <label style="color: #535C69;" class="custom-file-upload"  :class="{ 'input-error': v$.selectedImage.$error }" v-else>
                  <input type="file" accept="image/*" @change="handleFileUpload($event)"/>{{ fileNameVariable }}
                </label>
                <p class="descr">до 5 МБ, PNG, JPG, JPEG. Для замены - загрузите заново</p>
              </div>
              <div class="color_container">
                <div class="color">
                  <p class="header">Цветовая схема</p>
                  <div class="switch_container">
                    <p :class="{'light' : !theme, 'dark' : theme}">Светлая</p>
                    <CustomSwitchPage v-model="theme" style="margin-top: 2px; width: 40px"/>
                    <p :class="{'light' : theme, 'dark' : !theme}">Тёмная</p>
                  </div>
                </div>
                <div class="color">
                  <div class="color-text-container">
                    <p class="header">Основной</p>
                    <Tip :Tip="'Цвет кнопок и декоративных элементов'"/>
                  </div>
                  <div class="rgb" @click="toggleSelection(1)">
                    <p class="rgb_color">{{ widget.Main }}</p>
                    <div class="rgb_choise" :style="{ backgroundColor: widget.Main }"></div>
                  </div>
                  <PalitraPage v-if="isCircleShown1" class="show" @close="closeall" @updateColor="color => handleColorUpdate(color, 1)"></PalitraPage>
                </div>
                <div class="color">
                  <div class="color-text-container">
                    <p class="header">Фон виджета</p>
                    <Tip :Tip="'Цвет всех плашек в виджете: главная, специалисты, услуги и др.'"/>
                  </div>
                  <div class="rgb" @click="toggleSelection(2)">
                    <p class="rgb_color">{{ widget.Back }}</p>
                    <div class="rgb_choise" :style="{ backgroundColor: widget.Back }"></div>
                  </div>
                  <PalitraPage v-if="isCircleShown2" class="show" @close="closeall" @updateColor="color => handleColorUpdate(color, 2)"></PalitraPage>
                </div>
                <div class="color">
                  <div class="color-text-container">
                    <p class="header">Фон плашка</p>
                    <Tip :Tip="'Цвет фона плашки у сотрудников, филиалов и т.д.'"/>
                  </div>
                  <div class="rgb" @click="toggleSelection(3)">
                    <p class="rgb_color">{{ widget.Plashka }}</p>
                    <div class="rgb_choise" :style="{ backgroundColor: widget.Plashka }"></div>
                  </div>
                  <PalitraPage v-if="isCircleShown3" class="show" @close="closeall" @updateColor="color => handleColorUpdate(color, 3)"></PalitraPage>
                </div>
                <div class="color">
                  <div class="color-text-container">
                    <p class="header">Цвет текста</p>
                    <Tip :Tip="'Цвет текста на плашках в вашем виджете'"/>
                  </div>
                  <div class="rgb" @click="toggleSelection(4)">
                    <p class="rgb_color">{{ widget.Text }}</p>
                    <div class="rgb_choise" :style="{ backgroundColor: widget.Text }"></div>
                  </div>
                  <PalitraPage v-if="isCircleShown4" class="show" @close="closeall" @updateColor="color => handleColorUpdate(color, 4)"></PalitraPage>
                </div>
              </div>
              <div class="window_wrapper">
                <p class="header">Выберите дизайн виджета</p>
                <div class="wrapper">
                  <div class="window_container"  @click="widgetDesign = 'WidgetSite'">
                    <div class="window">
                      <div class="square"></div>
                      <div class="stripe"></div>
                      <div class="stripe"></div>
                      <div class="stripe"></div>
                      <div class="rectangle"></div>
                    </div>
                    <div class="choice">
                      <div class="circle_bottom">
                        <div v-if="widgetDesign === 'WidgetSite'" class="second_circle"></div>
                      </div>
                      <p class="">Виджет в виде сайта</p>
                    </div>
                  </div>
                  <div class="window_container"  @click="widgetDesign = 'IntegratedWidgetSite'">
                    <div class="window">
                      <div class="stripe"></div>
                      <div class="stripe" style="width:80%"></div>
                      <div class="stripe" style="width:60%"></div>
                      <div class="rectangle"></div>
                    </div>
                    <div class="choice">
                      <div class="circle_bottom">
                        <div v-if="widgetDesign === 'IntegratedWidgetSite'" class="second_circle"></div>
                      </div>
                      <p>Встроенный виджет для вашего сайта</p>
                    </div>
                  </div>
                  <div class="window_container" @click="widgetDesign = 'WidgetFloatButton'">
                    <div class="window">
                      <div class="lane_container">
                        <div class="lane" style="width:32%"></div>
                        <div class="lane" style="width:62%"></div>
                      </div>
                      <div class="stripe" style="width:80%"></div>
                      <div class="stripe" style="width:60%"></div>
                      <div class="stripe" style="width:40%"></div>
                      <div class="lane_container">
                        <div class="lane" style="width:55%"></div>
                        <div class="circle"></div>
                      </div>
                    </div>
                    <div class="choice">
                      <div class="circle_bottom">
                        <div v-if="widgetDesign === 'WidgetFloatButton'" class="second_circle"></div>
                      </div>
                      <p>Плавающая кнопка на вашем сайте</p>
                    </div>
                  </div>
                </div>
              </div>
<!--               <div class="switch_wrapper">
                <div class="switch">
                  <p class="switch-text">Показывать отзывы?</p>
                  <CustomSwitchPage v-model="showRating"/>
                </div>
                <div class="switch">
                  <p class="switch-text">Показывать раздел «О нас»?</p>
                  <CustomSwitchPage v-model="showAbout"/>
                </div>
              </div> -->
              <div class="widget-link">
                <label for="widgetLink">Прямая ссылка на виджет</label>
                <div style="display: flex; gap: 5px; justify-content: start; align-items: center;">
                  <div style=" background-color: #F3F5F6; padding: 0 10px 0 1em; display: flex; justify-content: start; align-items: center; width: 100%;  user-select: none;">
                    <p class="baseLink">{{ baseLink }}</p>
                    <input :class="{ 'input-error-link': v$.newValue.$error, 'widgetLink': !v$.newValue.$error }" maxlength="25" type="text" v-model="newValue" @input="updateLink">
                    <div class="svg_delete" style="height: 14px;">
                      <svg width="14" height="14" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" @click="newValue = ''">
                        <path d="M8.58578 10L4.29288 14.2929L5.70709 15.7071L10 11.4142L14.2929 15.7071L15.7071 14.2929L11.4142 10L15.7071 5.70712L14.2929 4.29291L10 8.58579L5.70712 4.29291L4.29291 5.70712L8.58578 10Z" fill="#D2D8DE"/>
                      </svg>
                    </div>
                  </div>
                </div>
                <div class="button-container">
                  <button @click="save" class="save-button">Создать виджет</button>
                  <button @click="handleCancel" class="cancel-button">Отмена</button>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 45px;">
      <WidgetConstructor :theme="theme" :MainColor="widget.Main" :WidgetColor="widget.Back" :BakcgroundColor="widget.Plashka" :TextColor="widget.Text" :Filials_ids="this.chips.map(chip => chip.id)" :cover="coverDataUrl"/>  
    </div>
    <MessageAlert :message="alertMessage" :color="alertColor"/>
    <VerifPage
    v-if="showModal"
    @confirm="toggleModal"
    @cancel="cancel"
  />
  </div>
</template>
  
<script>
import WidgetConstructor from './WidgetConstructor.vue';
import PalitraPage from './PalitraPage.vue';
import SelectPage from '../components/SelectPage.vue';
import CustomSwitchPage from '../components/CustomSwitchPage.vue';
import Tip from '../components/TipComponent.vue';
import MessageAlert from "../components/MessageAlert.vue";
import VerifPage from '../components/VerifPage.vue';

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  components: { WidgetConstructor , PalitraPage, SelectPage, Tip, MessageAlert, CustomSwitchPage, VerifPage} ,
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      initialSelectedLanguage: '',
      initialServiceName: '',
      showModal: false,
      alertMessage: null,
      alertColor: '',

      widgetDesign: 'WidgetSite',
      selectedImage: '',
      coverDataUrl: '',
      selectedTab: 'general',
      selectedChoice: null, 
      isCircleShown1: false,
      isCircleShown2: false, 
      isCircleShown3: false, 
      isCircleShown4: false,

      widgetName: '',
      selectedLanguage: '',

      baseLink: 'https://sked.online/widget/user' + this.$store.state.registrationData.user_id+ '/p' + this.$store.state.activeProjectId + '/',
      newValue: '',

      widget:{
        Main: '#6266EA',
        Back: '#FFFFFF',
        Plashka: '#FAFAFA',
        Text: '#535C69',
      },
      chips: [],
      filials: [],
      fileNameVariable: '',

      theme: 0,
    };
  },
  validations () {
    return {
      widgetName: { required },
      selectedLanguage: { required },
      chips: { required },
      widgetDesign: { required },
      selectedImage: { required },
      newValue: { required },
    }
  },
  watch:{
    theme(){
      this.themechange()
    }
  },
  computed: {
    formattedLink() {
      return this.baseLink + this.newValue;
    },
    filteredChips() {
      // Начинаем с индекса 1 (второй элемент) и возвращаем оставшиеся элементы
      this.chips.forEach(element => {
        console.log(element)
      });
      
      return this.chips.slice(0);
    }
  },
  mounted(){
    this.initialSelectedLanguage = this.selectedLanguage;
    this.getfilials()
  },
  methods: {
    handleCancel() {
        if (
          this.widgetName !== '' ||
          this.selectedLanguage !== this.initialSelectedLanguage || 
          this.selectedImage !== '' ||
          this.newValue !== '' ||
          this.filteredChips.length > 0
        ) {
          this.toggleModal();
        } else {
          this.cancel();
        }
      },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    cancel() {
      this.$router.go(-1);
    },
    updateLink() {
      this.formattedLink = this.baseLink + this.newValue
    },
    handleFileUpload(event) {
      this.selectedImage = event.target.files[0];
      const file = event.target.files[0];
      this.serviceCover = file; // сохраняем весь объект файла
      const fileName = file.name; // извлекаем название файла
      if (fileName.length > 100) {
        this.fileNameVariable = fileName.slice(0, 100) + '...' + fileName.slice(-4);
      }else{
        this.fileNameVariable = fileName.slice(0, 100);
      }
      this.readCoverDataUrl();  
    },
    readCoverDataUrl() {
      if (!this.selectedImage) return;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.coverDataUrl = e.target.result;
      };
      reader.readAsDataURL(this.serviceCover);
    },

    getfilials(){
        this.$axios.get(`/api/get_branch/?variable=${this.$store.state.registrationData.user_id}&project=${this.$store.state.activeProjectId}`)
        .then(response => {
            this.filials = response.data;
            this.filials.reverse();
            console.log(this.filials);
            this.branchLoaded = true;
            this.rerenderSidebar();
        })
        .catch(error => {
            console.error('Ошибка при получении данных о пользователе:', error);
        });
    },

    deleteChip(chip){
      let indexToRemove = this.chips.indexOf(chip);
      if (indexToRemove !== -1) {
        this.chips.splice(indexToRemove, 1);
      }
    },

    handleSelectInput(selected) {
      const existingChip = this.chips.find(chip => chip.name === selected.name && chip.id === selected.id);
      if (!existingChip) {
        this.chips.push({ name: selected.name, id: selected.id });
      }
    },

    async handleColorUpdate(color, additionalArgument) {
      switch (additionalArgument) {
        case 1: { this.widget.Main = color; break; }
        case 2: { this.widget.Back = color; break; }
        case 3: { this.widget.Plashka = color; break; }
        case 4: { this.widget.Text = color; break; }
      }
      setTimeout(() => {
        this.closeall();
      }, 0);
    },

    save(){
      this.v$.$validate()

      if (!this.v$.$error){
        const formData = new FormData();
        formData.append('language', this.selectedLanguage);
        formData.append('design', this.widgetDesign);
        formData.append('cover', this.selectedImage);
        formData.append('customLink', this.newValue); 
        formData.append('fullLink', this.formattedLink); 
        
        formData.append('text', this.widget.Text);
        formData.append('plashka', this.widget.Plashka);
        formData.append('back', this.widget.Back);
        formData.append('main', this.widget.Main);
        formData.append('theme', this.theme);

        formData.append('branches',  Object.values(this.chips).map(item => item.id).join(','));
        formData.append('name', this.widgetName);
        formData.append('user', this.$store.state.registrationData.user_id);
        formData.append('project', this.$store.state.activeProjectId)      

        this.$axios.post('/api/widget_create/', formData, {withCredentials: true})
        .then(response => {
          this.alertMessage = 'Настройки успешно сохранены'
          this.alertColor = '#0BB6A1'
          setTimeout(() => {
            this.$router.push('/dashboard/widgets/')
          }, 2000)
          console.log(response);
        })
        .catch(error => {
          this.alertMessage = error;
          this.alertColor = '#F97F7F';
          setTimeout(() => {
            this.alertMessage = '';
          }, 100);
          console.error('Error while posting formData:', error);
        });
      }else{
        this.alertMessage = 'Пожалуйста, заполните выделенные поля';
        this.alertColor = '#F97F7F';
        setTimeout(() => {
          this.alertMessage = '';
        }, 100);
      }

      
    },

    themechange(){
      if (this.theme) {
        this.widget.Main = '#FFC25A'
        this.widget.Back = '#222433'
        this.widget.Plashka = '#1A1B27'
        this.widget.Text = '#F5F5F5'
      }else{
        this.widget.Main = '#6266EA'
        this.widget.Back = '#FFFFFF'
        this.widget.Plashka = '#FAFAFA'
        this.widget.Text = '#535C69'
      }
    },

    toggleSelection(num) {
      switch(num){
        case 1:{ this.isCircleShown1=true;this.isCircleShown2=false;this.isCircleShown3=false;this.isCircleShown4=false;break}
        case 2:{ this.isCircleShown2=true;this.isCircleShown1=false;this.isCircleShown3=false;this.isCircleShown4=false;break}
        case 3:{ this.isCircleShown3=true;this.isCircleShown2=false;this.isCircleShown1=false;this.isCircleShown4=false;break}
        case 4:{ this.isCircleShown4=true;this.isCircleShown2=false;this.isCircleShown3=false;this.isCircleShown1=false;break}
      }
    },
    closeall(){
      this.isCircleShown1=false;
      this.isCircleShown2=false;
      this.isCircleShown3=false;
      this.isCircleShown4=false;
    },
  },
  
};
</script>
  
<style scoped>
.svg_delete{
  cursor: pointer;
  transition: all 0.2s ease;
}
.svg_delete svg path{
  cursor: pointer;
  transition: all 0.2s ease;
}
.svg_delete:hover svg path{
  fill: #F97F7F;
}
.baseLink{
  font-family: TT Norms Medium;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #D2D8DE;
  width: fit-content;
}
.widgetLink{
  width: 100%;
  margin: 0;
  color: #535C69;
  border: none;
  padding: 0;
  padding-left: 1px;
}
.widgetLink:focus{
  outline: none;
  border: none;
}
.input-error-link{
  width: 100%;
  margin: 0;
  border: solid 1px #F97F7F !important;
}
.select-error >>> .selected{
  border: solid 1px #F97F7F !important;
}
.clean-link{
  background-color: #F97F7F33;
  color: #F97F7F;
}
.clean-link:hover{
  background-color: #F97F7F;
  color: white;
}
.widget-link{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.custom-file-upload {
  width: 100%;
  height: 36px;
  display: flex;
  padding: 8px 10px;
  cursor: pointer;
  background-color: #F3F5F6;
  color: #D2D8DE;
  align-items: center;
  background-image: url(../../static/img/paperclip.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center;
}
.custom-file-upload input[type="file"] {
  display: none;
}
.color-text-container{
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 5px;
}
.label_container{
  display: flex;
  gap: 5px;
}
.window_wrapper{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.img_container{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.switch_wrapper{
  display: flex;
  gap: 50px;
}
.switch_text{
  font-family: TT Norms Medium;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #535C69;
}
.switch{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.input-content{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.filial_container{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.forms{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.chip svg:hover path{
  fill: rgb(250, 148, 148);
}
.chips-block{
  width: 100%;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  margin-top: 10px;
}

.chip{
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: start;
  background-color: #6266EA;
  height: 20px;
  padding: 0 15px;
  border-radius: 10px;
  transition: all .2s ease;
}

.chip:hover{
  background-color: #5357c7;
  cursor: pointer;
}

.chip p{
  margin: 0;
  color: white;
  font-family: 'TT Norms';
  font-size: 12px;
  margin-top: -1.5px;
}
.descr{
  font-family: TT Norms Medium;
  font-size: 8px;
  font-weight: 500;
  line-height: 9.75px;
  letter-spacing: 0em;
  text-align: left;
  color: #AFB6C1;
  width: 100%;
}
.subheader{
  margin-top: 0;
}
.header, .subheader{
  width: fit-content;
}
.name-container{
  display: grid;
  grid-template-columns: 5fr 4fr;
  grid-column-gap: 20px;
  grid-row-gap: 0px; 
}
.apps{
  width: 100%;
}
.app-group{
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
}
.navigation {
  display: flex;
  background-color:#FAFAFA;
  gap: 40px;
  margin-right: 20px;
}

.settings {
  width: 100%;
  text-align: center;
  height: auto;
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

label {
  margin-right: 10px;
  margin-bottom: 10px;
  font-family: TT Norms Medium;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0em;
  margin: 0;
}

input {
  margin-bottom: 0;
  margin-right: 10px;
}

p{
  text-align: left;
  font-family: TT Norms Medium;
  font-size: 14px;
  line-height: 17px;
  color:#535C69;
  margin: 0;
}

input::placeholder {
  font-family: "TT Norms Medium";
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  color: #D2D8DE;
}

select {
  width: 100%;
  padding: 8px 10px;
  font-family: TT Norms Medium;
  font-size: 14px;
  line-height: 20px;
  color: #D2D8DE;
  border: none;
  background-color: #F3F5F6;
  margin-bottom: 10px;
  border-radius: 3px;
  height: 36px;
}

select option {
  font-family: TT Norms Medium;
  font-size: 14px;
  line-height: 20px;
  color: #535C69;
}
.apps-container{
  display: grid;
  grid-template-columns: 5fr 4fr;
  grid-column-gap: 20px;
  grid-row-gap: 0px;
  margin-bottom: 10px;
}
.subheader{
  font-family: TT Norms Medium;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  color: #AFB6C1;
}
.footer{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px; 
}
.footer-apps{
  display: flex;
  flex-direction: column;
}
.transition {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
}

.employesss-link {
  font-family: TT Norms Medium;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  text-decoration: none;
  color: #AFB6C1;
}

.creation_text {
  color: #535C69;
  font-family: TT Norms Medium;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  width: 100%;
}

.arrow-container {
  display: flex;
  align-items: center;
}

.arrow-icon {
  height: 50%;
}
.plus_container{
  display: flex;
  gap: 10px;
}
.img_plus {
  width: 200px;
  height: 100px;
  border-radius: 5px;
  border: 1px solid #D2D8DE;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: url(../../static/img/plus.svg) center center/cover no-repeat;
  background-size: 10%;
  transition: all .2s ease;
}

.img_plus:hover{
  cursor: pointer;
  background-color: rgb(248, 248, 248);
}

.img_plus img {
  max-width: 100%;
  max-height: 100%;
}
.color_container{
  display: flex;
  gap: 10px;
}
.color{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
}
.rgb{
  height: auto;
  width: 120px;
  border-radius: 3px;
  padding: 5px 10px;
  border: 1px solid #D2D8DE;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.rgb:hover{
  cursor: pointer;
  background-color: rgb(248, 248, 248);
}
.rgb_color{
  margin: 0;
}
.rgb_choise{
  width: 12px;
  height: 12px;
  border: 1px solid #D2D8DE;
  border-radius: 25px;
  margin: 0;
}
.switch_container{
  display: flex;
  align-items: center;
  gap: 10px;
}
.light{
  font-family: TT Norms Medium;
  font-size: 12px;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
  color: #535C69;
  margin: 0;
}
.dark{
  font-family: TT Norms Medium;
  font-size: 12px;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
  color: #AFB6C1;    
  margin: 0;
}
.window{
  width: 100%;
  height: 120px;
  border-radius: 2px;
  padding: 15px;
  background: #F6F6F6;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.stripe {
  background: linear-gradient(90deg, #EBEBEB 0%, #DAE2EE 100%);
  border-radius: 2px;
  width: 100%;
  height: 20px;
}
.square{
  width: 20px;
  height: 45px;
  background: linear-gradient(90deg, #EBEBEB 0%, #DAE2EE 100%);
  border-radius: 2px;
}
.rectangle{
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #9497FF 0%, #8B8FFF 100%);
  border-radius: 2px;
}
.wrapper{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
}
.button-container {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 20px;
}
.save-button {
  background-color: #EFEFFF;
  color: #6266EA;
  transition: background-color 0.3s, color 0.3s;
}

.save-button:hover {
  background-color: #6266EA;
  color: #EFEFFF;
}
.cancel-button {
  background-color: #FFFFFF;
  color: #535C69;
  border-radius: 3px;
  border: 1px solid #DDE1E5;
  transition: all 0.2s ease;
}
.cancel-button:hover{
  color: #5357c7;
}
.lane_container{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.lane{
  height: 15px;
  background: linear-gradient(90deg, #EBEBEB 0%, #DAE2EE 100%);
  border-radius: 2px;
}
.circle{
  width: 30px;
  height: 30px;
  background: linear-gradient(90deg, #9497FF 0%, #8B8FFF 100%);
  border-radius: 100px;
}
.card{
  display: flex;
  justify-content: start;
  background: #FAFAFA;
  margin: 10px 0;
}
.tab{
  position: relative;
  border-bottom: 1px solid rgba(50, 56, 74, 0.1); 
  width: 68%;
  display: flex;
  gap: 15px;
  margin-top: 30px;
  padding: 0;
}
.tab-link {
  position: relative;
  font-family: TT Norms Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #AFB6C1;
  cursor: pointer;
  padding-bottom: 10px;
}
.tab-link:hover {
  color: #6266EA;
}
.tab-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: transparent;
  transition: background-color 0.3s;
}

.tab-link:hover::after {
  background-color: #6266EA;
}
.window_container{
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.active-tab {
  color: #6266EA;
}
.active-tab::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: transparent;
  transition: background-color 0.3s;
  background:#6266EA;
}
.choice{
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  height: 55px;
}
.circle_bottom{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border: 1px solid #C6CBD2;
  background: #F3F5F6;
  border-radius: 15px;
  cursor: pointer;
}
.second_circle{
  background: #6266EA;
  width: 10px;
  height: 10px;
  border-radius: 25px;
}
::v-deep .tooltip-show {
  width: 240px !important;
  text-align: justify;
}
::v-deep .tooltip-hide {
  width: 240px !important;
  text-align: justify;
}
@media (max-width: 768px){
  .main{
    padding: 20px;
  }
  #lightmode{
    display: none;
  }
  .window{
    width: 100%;
  }
}
</style>