<template>
  <div class="login">
    <div class="container1">
      <div class="left-part">
        <div class="Forma">
          <div class="registration-form" v-if="step == 'login' || step == 'register'">
            <h2>Пользователь {{ FromUserName }} предлагает вам доступ к проекту:</h2>
            <div style="display: flex; align-items: center; gap: 10px;">
              <div class="teg_svg" :style="{'backgroundColor'  : project.colour}">
                <svg width="14" height="14" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 0C0.895431 0 0 0.895431 0 2V15C0 16.1046 0.89543 17 2 17H16C17.1046 17 18 16.1046 18 15V4C18 2.89543 17.1046 2 16 2H7C7 0.895431 6.10457 0 5 0H2Z" fill="white"/>
                </svg>
              </div>
              <div class="wrapper_name">
                <p class="wrapper_descr" style="color: #535C69;">{{ project.name }}</p>
                <p class="wrapper_subhead">ID {{ project.id }}</p>
              </div>
            </div>
            <form @submit.prevent="loginUser" v-if="step == 'login'">
              <div class="form-group">
                <label for="username">Почта</label>
                <input v-model="username" id="username" name="username" placeholder="Usermail@gmail.com" required type="email">
              </div>
              <div class="form-group">
                <label for="password">Пароль</label>
                <PasswordComponent v-model="password" placeholder="*************" toggleMask :feedback="false"/>
              </div>
              <div class="reset">
                <button type="submit" @click="loginUser()">Войти</button>
                <p class="ResetPassword" @click="step = 'register'">Нет аккаунта?</p>
              </div>
              <div v-if="error" id="error">{{ error }}</div>   
            </form>
            <form @submit.prevent="loginUser" v-if="step == 'register'">
              <div class="form-group">
                <label for="username">Почта</label>
                <input v-model="email" id="email" name="email" placeholder="Usermail@gmail.com" required type="email">
              </div>
              <div class="form-group-phone" style="display: flex;">      
                <div class="card flex justify-content-center">
                  <DropdownComponent v-model="selectedCountry" :options="countries" optionLabel="name" placeholder="🇷🇺" class="w-full md:w-14rem">
                    <template #value="slotProps">
                      <div v-if="slotProps.value" class="flex align-items-center">
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="flex align-items-center">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </DropdownComponent>
                </div>
                <InputMaskComponent autocomplete="new-password" @input="handleInput" id="basic" v-model="value" :mask="computedMask" :placeholder="computedPlaceholder" />
              </div>
              <div class="form-group">
                <label for="password">Пароль</label>
                <PasswordComponent v-model="password" placeholder="*************" toggleMask :feedback="false"/>
              </div>
              <div class="reset">
                <button type="submit" @click="registerUser()">Создать аккаунт</button>
                <p class="ResetPassword" @click="step = 'login'">Уже есть аккаунт?</p>
              </div>
              <div v-if="error" id="error">{{ error }}</div>  
            </form>
            <p class="personal-data">Используя SKED, я соглашаюсь с обработкой персональных данных и договором публичной оферты</p>
            <div class="social-icons">
              <div class="google">
                <img class="logo_auth" src="../../static/img/google.svg" alt="Google">
                <p class="google_text">Войти с помощью Google</p>
              </div>
              <div class="yandex">
                <img class="logo_auth" src="../../static/img/yandex.svg" alt="Twitter">
              </div>
              <div class="mailru">
                <img class="logo_auth" src="../../static/img/mail.svg" alt="Mail.ru">
              </div>
            </div>
          </div>
          <div class="accept-form" v-if="step == 'choice'">
            <h2>Пользователь {{ FromUserName }} предлагает вам доступ к проекту:</h2>
            <div style="display: flex; align-items: center; gap: 10px;">
              <div class="teg_svg" :style="{'backgroundColor'  : project.colour}">
                <svg width="14" height="14" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 0C0.895431 0 0 0.895431 0 2V15C0 16.1046 0.89543 17 2 17H16C17.1046 17 18 16.1046 18 15V4C18 2.89543 17.1046 2 16 2H7C7 0.895431 6.10457 0 5 0H2Z" fill="white"/>
                </svg>
              </div>
              <div class="wrapper_name">
                <p class="wrapper_descr" style="color: #535C69;">{{ project.name }}</p>
                <p class="wrapper_subhead">ID {{ project.id }}</p>
              </div>
            </div>
            <div class="buttons-container">
              <div class="accept" @click="step = 'login'">
                <img src="../../static/img/accept.png" alt="accept">
                <p style="font-size: 14px;">Принять приглашение</p>
              </div>
              <div class="decline" @click="declineAccess()">
                <img src="../../static/img/decline.png" alt="decline">
                <p style="font-size: 14px;">Отказаться</p>
              </div>
            </div>
          </div>

          <div class="accept-form" v-if="step == 'declined'">
            <h2>Вы отказались от доступа к проекту!</h2>
          </div>

          <div class="accept-form" v-if="step == 'used'">
            <h2>Ссылка не действительна!</h2>
          </div>
        </div>
      </div>
    </div>
    <div :class="{'overlay-show' : showModal, 'overlay-hide' : !showModal}"></div>
    <div :class="{'modal-show' : showModal, 'modal-hide' : !showModal}">
          <div class="modal-content">
            <p class="text-header">Доступ к проекту уже есть</p>
            <p class="modal-subtext">У данного пользователя уже есть доступ в проект <br> <span>"{{ project.name }}"</span></p>
            <div class="btn_container">
              <button class="delete" @click="this.$router.push('/login')">Продолжить</button>
              <button class="exit" @click="toggleModal">Войти в другой аккаунт</button>
            </div>
          </div>
    </div>
  </div>
</template>

<script>

export default {
    data() {
        return {
        message: '',
        projectAccess: '',
        project: Object,

        username: '',
        password: '',
        FromUserName: '',

        step:'choice',
        
        error: '',
        showModal: false,

        countries: [
          { name: '🇷🇺', code: '+7' },
          { name: '🇧🇾', code: '+375' },
          { name: '🇰🇿', code: '+7' },
          { name: '🇺🇦', code: '+380' },
        ],
        };
    },
    props: {
        uniqCode: {
          type: String,
          required: true
        },
    },
    computed: {
      computedMask() {
        if (this.selectedCountry) {
          const countryCode = this.selectedCountry.code;
          if (countryCode === '+375' || countryCode === '+380') {
            return `${countryCode} (99) 999-99-99`;
          } else {
            return `${countryCode} (999) 999-99-99`;
          }
        } else {
          return '+7 (999) 999-99-99'; // Default mask
        }
      },
      computedPlaceholder() {
        return this.selectedCountry ? this.selectedCountry.code + ' |' : '+7 |';
      },
    },
    methods: {
      toggleModal() {
        this.showModal = !this.showModal;
      },
      async loginUser() {
        try {
          const response = await this.$axios.post('/api/login/', {
            username_or_email: this.username,
            password: this.password,
            uniqCode: this.uniqCode,
          }, {withCredentials: true});

          // Сохраняем данные пользователя в хранилище Vuex
          this.$store.dispatch('saveRegistrationData', response.data);

          // Очищаем ошибку в случае успешной авторизации
          this.error = '';

          // Переход на другую страницу (например, после успешной авторизации)
          this.$router.push('/dashboard');
        } catch (error) {
          console.error('Ошибка входа', error);

          if (error.response && error.response.data && error.response.data.error) {
            // Если есть информация об ошибке в ответе сервера, устанавливаем её
            if (error.response.data.error=="У данного пользователя уже есть доступ к проекту") {
              this.showModal = true;
            }else if(error.response.data.error=="'NoneType' object has no attribute 'username'"){
              this.error = 'Ошибка входа: "Такой почтовый ящик у нас не зарегистрирован"';
            }else{
            this.error = 'Ошибка входа: ' + '"'+error.response.data.error+'"';
            }
          } else {
            // Если нет информации об ошибке, устанавливаем общее сообщение
            this.error = 'Ошибка входа: произошла непредвиденная ошибка';
          }
        }
      },
        async registerUser() {
          try {
              const response = await this.$axios.post('/api/reg/', {
                password: this.password,
                phone: this.value,
                email: this.email,
                uniqCode: this.uniqCode,
              }, {withCredentials: true});

              const formData = new FormData();
              formData.append('accessStatus', 'Принят');

              this.$axios.patch(`/api/project-access/${this.projectAccess.id}/set/`, formData, {withCredentials: true})
                .then(response => {
                  this.step = 'declined'
                  return response.data
                })
                .catch(error => {
                  console.error('Error declinin access:', error);
                });
                
                this.$store.dispatch('saveRegistrationData', response.data);
                this.$router.push('/dashboard');
            } catch (error) {
              console.error('Ошибка регистрации', error.response);

              if (error.response.data && typeof error.response.data === 'object') {
                console.error('Детали ошибки:', JSON.stringify(error.response.data, null, 2));
                // Устанавливаем значение свойства error для отображения в div
                this.error = error.response.data.error;
              } else {
                console.error('Детали ошибки:', error.response.data);
                // Устанавливаем значение свойства error для отображения в div
                this.error = error.response.data;
              }
            }
        },
        async getProjectAccessByUniqCode() {
          try {
            const response = await this.$axios.get(`/api/project-access/${this.uniqCode}/`);
            if (response.data.accessStatus == 'Принят' || response.data.accessStatus == 'Отклонен') {
              this.step = 'used'
            } else{
              this.projectAccess = response.data;
              this.fetchProject();
              this.get_profile()
            }
          } catch (error) {
            console.error('Error response:', error);
          }
        },
        get_profile(){
          this.$axios.post('/api/getprofile/', { user_id:  this.projectAccess.fromUserId}, {withCredentials: true})
          .then(response => {
            this.avatar = "" + response.data.profile.avatar
            this.FromUserName = response.data.profile.name

          })
          .catch(error => {

            console.error('Ошибка при получении данных о пользователе:', error);
          });
        },
        async fetchProject() {
          try {
            const response = await this.$axios.get('/api/get_projectbyid/', {
              params: {
                variable: this.projectAccess.project
              }
            });
            this.project = response.data
          } catch (error) {
            console.error('Error fetching project:', error);
          }
        },
        declineAccess(){
          
          const formData = new FormData();
          formData.append('accessStatus', 'Отклонен');

          this.$axios.patch(`/api/project-access/${this.projectAccess.id}/set/`, formData, {withCredentials: true})
            .then(response => {
              this.step = 'declined'
              return response.data
            })
            .catch(error => {
              console.error('Error declinin access:', error);
            });
        },
        acceptAccess(){const formData = new FormData();
          formData.append('accessStatus', 'Принят');

          this.$axios.patch(`/api/project-access/${this.projectAccess.id}/set/`, formData, {withCredentials: true})
            .then(response => {
              return response.data
            })
            .catch(error => {
              console.error('Error declinin access:', error);
            });
          
        },
        
    },
    mounted(){
      this.getProjectAccessByUniqCode()
    }
};
</script>
<style scoped>
.text-header{
      font-family: 'TT Norms Medium';
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0em;
      color: #535C69;
      margin: 0;
      text-align: left;
    }
    .text-subheader{
      font-family: 'TT Norms Medium';
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0em;
      color: #AFB6C1;
      margin: 0;
      text-align: left;
    }
    .btn_container{
    margin-top: 30px;
    display: flex;
    gap: 10px;
  }
  span{
    color: #7D838C;
  }
  .delete{
    color: #6266EA;
    background-color: #EFEFFF;
  }
  .delete:hover{
    background: #DBEAFF;
    color: #6266EA;
  }
  .exit{
    color: #535C69;
    border: 1px solid #DDE1E5;
    background: #FFFFFF;  
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  }
  .exit:hover{
    border: 1px solid #AFB6C1;
    background: #F5F5F5;
  }
    .modal-subtext{
      font-family: TT Norms Medium;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: #AFB6C1;
      margin: 0;
      margin-top: 10px;
    }
.modal-show{
    width: auto;
    height: auto;
    position: absolute;
    padding: 40px;
    border-radius: 10px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: white;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    transition: all .1s ease;
  }
  .modal-hide{
    width: auto;
    height: auto;
    position: absolute;
    padding: 40px;
    border-radius: 10px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: white;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    transition: all .1s ease;
  }
  .overlay-show {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Задний фон с прозрачностью 60% */
    z-index: 98;
    opacity: 1;
    visibility: visible;
    transition: all .1s ease;
  }
  .overlay-hide {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Задний фон с прозрачностью 60% */
    z-index: 98;
    opacity: 0;
    visibility: hidden;
    transition: all .1s ease;
  }
.personal-data{
  color: #AFB6C1;
  font-size: 14px;
  text-align: start;
}
.buttons-container{
  display: flex;
  gap: 10px;
}

.accept, .decline{
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 5px;
  background-color: white;
  border: solid 1px #DDE1E5;
  padding: 10px 15px;
  border-radius: 5px;
  transition: all .2s ease;
}
.accept:hover, .decline:hover{
  border: solid 1px #AFB6C1;
  cursor: pointer;
}
.teg{
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
}

.teg:hover{
  background-color: #FAFAFA;
}
.teg_svg{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F97F7F;
  border-radius: 30px;
  height: 36px;
  width: 36px;
}
.wrapper_descr{
  font-family: 'TT Norms Medium';
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #535C69;
}
.wrapper_head{
  font-family: 'TT Norms Medium';
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #6266EA;
}
.wrapper_subhead{
  font-family: 'TT Norms Medium';
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #AFB6C1;
}
p{
  margin: 0;
}
.avatar{
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
}
.avatar_text{
  margin: -10px;
  font-family: 'TT Norms Medium';
  width: auto;
  height: auto;
  color: #FFFFFF;
  font-size: 14px;
}
.form-show{
    opacity: 1;
    transform: translateX(0);
    transition: all .8s ease;
  }

  .form-hide{
    transform: translateX(-20px);
    opacity: 0;
    transition: all .8s ease;
  }

  .left-part{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .container1{ 
    font-family: 'TT Norms Medium';
    height: 100vh;
    padding: 0 0 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .login-prompt {
    padding: 0 80px 0 0;
    text-align: left;
    width: 340px;
    color: var(--cold-text-ghost-500, #DDE1E5);
    font-family: TT Norms Medium;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 10px;
  }
  
  .login-link {
    color: #04C562;
    cursor: pointer;
    transition: all .2s ease;
  }

  .login-link:hover{
    color: #02a04e;
  }
  
  h2 {
    color: #535C69;
    font-family: TT Norms Medium;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%;
    text-align: left;
  }
  
  .registration-form {
    display: flex;
    flex-direction: column;
    gap: 25px;
    background: #fff;
    width: 500px;
    height: auto;
    margin: 0 auto;
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
  }
  .accept-form {
    display: flex;
    flex-direction: column;
    gap: 25px;
    background: #fff;
    width: 500px;
    height: auto;
    margin: 0 auto;
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .p-dropdown{
      background: #f3f5f6;
      border: none;
      transition: all .2s ease;
      border-radius: 6px;
      outline-color: transparent;
  }
  .required-field {
    color: var(--required-field-color, #F97F7F);
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  #username::placeholder {
    color: var(--cold-text-title-200, #535C69);
    font-family: TT Norms Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .input-container {
    position: relative;
  }
  
  .input-container input {
    width: 260px;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
    color: var(--cold-text-title-200, #535C69);
    font-family: TT Norms Medium;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    height: 36px;
    padding-right: 40px;
    align-items: center;
    align-self: stretch;
    border-radius: 3px;
    background: var(--cold-text-soft-600, #F3F5F6);
  }
  
  .input-container{
    color: #535C69;
    position: absolute;
    right: 10px;
    top: 69%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  
  /* button {
    color: var(--ffffff, #FFF);
    background:#6266EA;
    border: none;
    cursor: pointer;
    display: flex;
    height: 36px;
    padding: 10px 14px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 3px;
    transition: all .2s ease;
  } */

  button:hover {
    background-color: #464AD9;
    color: #FFF;
  }

  .reset {
    display: flex;
    align-items: center;
  }

  .ResetPassword{
    color: var(--cold-text-subtitle-300, #7D838C);
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 15px;
    text-decoration: none;
    transition: color 0.3s;
    cursor: pointer;
  }

  .ResetPassword:hover {
    color: #464AD9;
  }

  .man{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background:#6266EA;
    height: 100vh;
    width: 40%;
  }

  .man img{
    margin: 2vw;
    width: 40%;
  }

  .man_head {
    text-align: center;
    color: #FAFAFA;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .man_header{
    color:#FAFAFA;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .man_subheader{
    color:#FFF;
    width: 300px;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    margin: 0 auto;
    font-family: 'TT Norms light'
  }

  .man_subheader span{
    font-family: 'TT Norms Bold'
  }
  .man_button {
    border: 1px solid #FAFAFA;
    border-radius: 5px;
    padding: 15px 50px;
    cursor: pointer;
    transition: all .2s ease;
    text-decoration: none;
    color: white;
  }

  .man_button:hover {
    background-color: #FFF;
    color: #535C69;
  }

  .man_button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px; 
  }
  .divider {
    border-bottom: 1px solid rgba(255, 255, 255, 1); 
    width: 30%;
    margin: 10px auto;
  }
  input::placeholder {
    font-family: 'TT Norms Medium';
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0em;
    color: #D2D8DE;
  }
  #error{
    font-family: TT Norms Medium;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #F97F7F;
    margin-top: 5px;
  }
  .input-error{
    border: 1px solid #F97F7F; /* устанавливаем стиль границы для случая, когда пароль не верный */
  }
  @media (max-width: 991px){
    .man{
      display: none;
    }
  }
   @media (max-width: 768px){
    .container1{
      padding: 0 0 0 0vw;
    }
  }
  @media (max-width: 576px){
    .container1{
      padding: 0 0 0 0vw;
    }
  }
</style>