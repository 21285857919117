<template>
  <div class="modal">
    <div class="modal-content">
      <p>Изменение Email</p>
      <div class="change-password-modal">
        <div class="input_container">
          <label for="currentMail">Новый Email</label>
          <input v-model="currentMail" type="email" id="currentMail">
          <div v-if="compareEmails" class="error-message">
            <p>{{ alreadyExists }}</p>
          </div>
        </div>
        <div class="input_container">
          <label for="newMail">Повторно введите Email</label>
          <input v-model="newMail" type="email" id="newMail">
          <div v-if="compareEmails" class="error-message">
            <p :style="{ color: compareEmails === 'Email не совпадает' ? '#F97F7F' : compareEmails === 'Email совпадает' ? '#AFB6C1' : 'defaultColor' }">{{ compareEmails }}</p>
          </div>
        </div>
        <div class="input_container">
          <label for="confirmMail">Текущий пароль</label>
          <input v-model="confirmMail" type="password" id="confirmMail">
        </div>
        <div class="button-container">
          <button v-if="showChangeButton" @click="change_email()" class="button-change_hover">Сменить Email</button>
          <button v-else class="button-change">Сменить Email</button>
          <button @click="this.$parent.showMail = false" class="button-exit">Отмена</button>
        </div>
        <div v-if="error" class="error-message">
          <p>{{ error }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script> 
export default {
  data() {
    return {
      currentMail: '',
      newMail: '',
      confirmMail: '',
      showChangeButton: false,

      alreadyExists: '',
      compareEmails: '',
      error: '',
    };
  },
  watch: {
    currentMail(value) {
      this.showChangeButton = this.newMail !== '' && this.confirmMail !== '' && value !== '';
      this.error = '';
      this.alreadyExists = ''
    },
    newMail(value) {
      this.showChangeButton = this.currentMail !== '' && this.confirmMail !== '' && value !== '';
      this.error = '';
      this.alreadyExists = '';
      if (this.currentMail == this.newMail) {
        this.compareEmails = 'Email совпадает'
      }else{
        this.compareEmails = 'Email не совпадает'
      }
    },
    confirmMail(value) {
      this.showChangeButton = this.currentMail !== '' && this.newMail !== '' && value !== '';
      this.error = '';
      this.alreadyExists = ''
    },
  },
  methods:{
    async change_email() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (emailRegex.test(this.currentMail) && emailRegex.test(this.newMail)) {
        try {
          this.showChangeButton = false;
          const response = await this.$axios.post('/api/change_mail/', {
            old_mail: this.currentMail,
            new_mail: this.newMail,
            password: this.confirmMail,
            user_id: this.$store.state.registrationData.user_id
          }, {withCredentials: true});
          console.log(response.data)
          this.$parent.get_profile();
          this.$parent.emailChanged();
        } catch (error) {
          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const message = error.response.data.error;
            if (status === 400) {
              this.error = 'Отсутствуют обязательные поля';
            } else if (status === 404) {
              this.error = 'Пользователь не существует';
            } else if (status === 409 && message === 'Invalid credentials') {
              this.error = 'Неверные учетные данные';
            } else if (status === 409 && message === 'Email already in use by another user') {
              this.alreadyExists = 'Email уже зарегистрирован в системе';
            } else if (status === 409 && message === 'Email must not match the current one') {
              this.alreadyExists = 'Email не должен совпадать с текущим';
            } else {
              this.error = 'Произошла ошибка при изменении email';
            }
          } else {
            this.error = 'Произошла ошибка при соединении с сервером';
          }
          this.showChangeButton = true;
        }
      } else {
        this.error = 'Проверьте правильность заполненных полей';
      }
    }

  }
};
</script>

<style scoped>
.input_container{
  margin-bottom: 20px;
}
.error-message p{
  font-size: 12px;
  color: #F97F7F;
  margin: 0;
}
input{
  margin: 0;
}
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.modal-content {
  width: 360px;
  height: fit-content;
  background-color: #FFFFFF;
  padding: 40px;
  border-radius: 8px;
}
.button-container{
  display: flex;
  gap: 10px;
}
label{
  color: #535C69;
  font-family: TT Norms Medium;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
}
p{
  font-family: TT Norms Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #535C69;
  margin: 0;
  margin-bottom: 20px;
}
.button-change{
  background: #4C5D6E33;
}
.button-exit{
  border: 1px solid #4C5D6E33;
  background: #FFFFFF;
  color: #7D838C;
}
.button-change_hover{
  background-color:#EFEFFF;
  color: #6266EA;
}
.button-change_hover:hover {
  background-color: #6266EA;
  color: #FFFFFF;
}
.button-exit:hover{
  color: #6266EA;
}
</style>