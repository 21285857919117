<template>
    <div class="card" @click="this.$parent.activateFilial(data)">
        <div class="card-image">
          <img v-if="images.length>0" 
              :src="images[0]" 
              alt="Filial Image">
          <img v-else src="../../static/img/map.png" alt="Default Image">
          </div>
          <div class="card_container">
          <p class="card_name" :style="activeFilial.name === data.name ? { color: 'var(--color-global)' } : {}">{{data.name}}</p>
          <div class="card_address_container">
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path :style="activeFilial.name === data.name ? { 'fill': 'var(--color-text)' } : {}" fill-rule="evenodd" clip-rule="evenodd" d="M1.2002 4.78875C1.2002 2.11449 3.31968 0 6.0002 0C8.61838 0 10.8002 2.17669 10.8002 4.78875C10.8002 8.00081 6.0002 12 6.0002 12C6.0002 12 1.2002 8.063 1.2002 4.78875ZM6.0002 6.6C6.99431 6.6 7.8002 5.79411 7.8002 4.8C7.8002 3.80589 6.99431 3 6.0002 3C5.00608 3 4.2002 3.80589 4.2002 4.8C4.2002 5.79411 5.00608 6.6 6.0002 6.6Z" fill="#AFB6C1"/>
              </svg>
              <p class="card_address" :style="activeFilial.name === data.name ? { 'color': 'var(--color-text)' } : {}">{{data.address}}</p>
          </div>
          <div class="card_time_container">
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path :style="activeFilial.name === data.name ? { 'fill': 'var(--color-text)' } : {}" fill-rule="evenodd" clip-rule="evenodd" d="M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM6.3 2.7H5.1V5.94853L8.27574 9.12426L9.12426 8.27574L6.3 5.45147V2.7Z" fill="#AFB6C1"/>
              </svg>
              <p class="card_time" :style="activeFilial.name === data.name ? { 'color': 'var(--color-text)' } : {}">{{data.work_hours}}</p>
          </div>
        </div>
        <div class="checkmark" v-if="activeFilial.name !== data.name">
          <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2556 6.15492L9.05226 14.4665L4.29285 9.7071L5.70706 8.29289L8.94764 11.5335L14.7443 4.84506L16.2556 6.15492Z" fill="#F5F5F5"/>
          </svg>
        </div>
        <div class="active-checkmark" v-else >
          <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2556 6.15492L9.05226 14.4665L4.29285 9.7071L5.70706 8.29289L8.94764 11.5335L14.7443 4.84506L16.2556 6.15492Z" fill="#F5F5F5"/>
          </svg>
        </div>
    </div>
</template>

<script>
export default {
    props: ['data', 'activeFilial'],
    data() {
        return{
          images: [],
        }
    },
    methods:{
      get_image(){
        this.$axios.get(`/api/get_image/?id=${this.data.id}`)
          .then(response => {
            for (let index = 0; index < response.data.length; index++) {
              this.images.push('' + response.data[index].image)
            }
            
          })
          .catch(error => {
            console.error('Error fetching photos:', error);
          });
      },
    },
    mounted(){
      this.get_image()
    }
    
}
</script>

<style scoped>
.checkmark{
  position: absolute;
  top: 10px;
  right: 10px;
  width: 18px;
  height: 18px;
  background: var(--color-main);
  border: 1px solid #F5F5F5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}
.checkmark:hover{
  background: #04C562;
  cursor: pointer;
}

.active-checkmark{
  position: absolute;
  top: 10px;
  right: 10px;
  width: 18px;
  height: 18px;
  background: #04C562;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease
}
.img_checkmark{
  position: absolute;
  top: -20px;
  left: 100px;
}
.card_container{
  background-color: var(--color-gray);
  width: 100%;
  padding: 20px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.card_address_container{
  display: flex;
  align-items: center;
  gap: 5px;
}
.card_time_container{
  display: flex;
  align-items: center;
  gap: 5px;
}
.card_name{
  font-family: TT Norms Medium;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text);
  margin: 0;
  transition: all .2s ease;
}
.card_address{
  font-family: TT Norms Medium;
  font-size: 10px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #AFB6C1;
  margin: 0;
  transition: all .2s ease;
}
.card_time{
  font-family: TT Norms Medium;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  color: #AFB6C1;
  margin: 0;
  transition: all .2s ease;
}
.card-image img{
  border-radius: 10px 0 0 10px;
  width: 220px;
  height: 120px;
  object-fit: cover;
}
.card{
  background-color: var(--color-gray);
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  position: relative;
}

.card:hover .card_name{
  color: var(--color-global);
}

.card:hover .card_address, .card:hover .card_time{
  color: var(--color-text);
}

@media (max-width: 768px){
  .card-image img{
    border-radius: 10px 10px 0 0 ;
    width: 100%;
    height: 120px;
    object-fit: cover;
  }
}


</style>