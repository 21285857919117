<template>
  <div class="custom-select" tabindex="1" @blur="handleBlur">
    <div class="selected" :class="{ open: open }" :style="{ color: (selected && selected.name) ? '#535C69' : '#D2D8DE' }" @click="toggleOpen()">
      <img v-if="selected && selected.flag" :src="selected.flag" :alt="selected.name" style="width: 20px; height: 14px; margin-right: 5px;" />
      {{ selected && selected.name ? selected.name : this.placeholderdata || options[0].name }}
    </div>
    

    <div :class="{ selectHide: !open, items: open }" ref="dropdown" :style="dropdownUp ? { bottom: '120%' } : {}">
      <div class="search" v-if="searchable" @click="open = true" @blur="handleBlur">
        <input type="search" placeholder="Поиск" v-model="searchTerm">
      </div>
      <div class="customInput" v-if="showCustomInput" @blur="handleBlur">
        <InputMaskComponent @click="open = true"
          id="basic"
          v-model="valueCustomInput"
          :mask="'99:99 - 99:99'"
          :placeholder="'00:00 - 00:00'"
        />
        <div @click="handleOptionClick(valueCustomInput)" class="accept-button">
          <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.37884 1.3085L3.33647 7.12662L0.00488281 3.79503L0.994832 2.80508L3.26324 5.07349L7.32088 0.391602L8.37884 1.3085Z" fill="#464AD9"/>
          </svg>
        </div>
      </div>
      <div v-if="customValue && !showCustomInput" @click="showCustomInput = true" class="custom-value-button">
        Указать свое значение
      </div>
      <div v-if="!filteredOptions.length > 0">Здесь пока ничего нет</div>
      <div @click="handleOptionClick('Все')" v-if="selectAll">
        Все
      </div>
      <div
        v-for="option in filteredOptions"
        :key="getOptionKey(option)"
        @click="handleOptionClick(option)"
        style="display: flex; align-items: center;"
      >
        <img :src="option.flag" :alt="option.name" style="width: 18px; height: 14px; margin: 10px 30px 10px 0;" />
        <span>{{ option.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      required: true,
    },
    placeholderdata: {
      type: String,
    },
    value: {
      default: null,
      required: false,
    },
    searchable:{
      type: Boolean,
    },
    customValue:{
      type: Boolean,
    },
    selectAll:{
      type: Boolean,
    },
    MainColor:{
      type: String,
      default: '#6266EA',
      required: false,
    },
    WidgetColor:{
      type: String,
      default: '#FFFFFF',
      required: false,
    },
    BakcgroundColor:{
      type: String,
      default: '#FAFAFA',
      required: false,
    },
    TextColor:{
      type: String,
      default: '#535C69',
      required: false,
    }
  },
  data() {
    return {
      selected: this.value || {},
      open: false,
      searchTerm: '',
      dropdownUp: false,
      valueCustomInput: null,
      showCustomInput: false,
    };
  },
  computed: {
    optionsList() {
      if (Array.isArray(this.options)) {
        return this.options;
      } else if (typeof this.options === 'object') {
        return Object.keys(this.options).map(key => ({
          id: key,
          name: this.options[key]
        }));
      }
      return [];
    },
    filteredOptions() {
      const term = this.searchTerm.toLowerCase();
      return this.optionsList.filter(option => this.getOptionName(option).toLowerCase().includes(term));
    }
  },
  watch: {
    value(newValue) {
      this.selected = newValue;
    },
  },
  methods: {
    toggleOpen(){
      if (this.open) {
        this.open = false;
        setTimeout(() => {
          this.dropdownUp = false;
        }, 200);
      } else {
        this.checkDropdownDirection();
        this.open = true;
      }
      
    },
    handleBlur() {
      setTimeout(() => {
        if (!this.$refs.dropdown.contains(document.activeElement)) {
          this.open = false;
          setTimeout(() => {
            this.dropdownUp = false;
          }, 200);
        }
      }, 100);
    },
    checkDropdownDirection() {

      const dropdown = this.$refs.dropdown;
      const dropdownRect = dropdown.getBoundingClientRect();
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;
      const dropdownHeight = dropdownRect.height;

      if (dropdownRect.bottom + dropdownHeight > windowHeight) {
        this.dropdownUp = true;
      } else {
        this.dropdownUp = false;
      }
    },
    handleOptionClick(option) {
      this.selected = option;
      this.open = false;
      this.$emit('input', option);
    },
    getOptionKey(option) {
      return option.id || option;
    },
    getOptionName(option) {
      return option && option.name !== undefined ? option.name : option;
    },
    highlightMatch(optionName) {
      const term = this.searchTerm.toLowerCase();
      const index = optionName.toLowerCase().indexOf(term);
      if (index > -1) {
        const highlightedPart = '<span style="color: #6266EA;">' + optionName.substr(index, term.length) + '</span>';
        return optionName.substr(0, index) + highlightedPart + optionName.substr(index + term.length);
      }
      return optionName;
    }
  },
  mounted() {
    if (this.optionsList.length > 0) {
      this.$emit("input", this.selected);
    }
    if (this.MainColor) {
      this.$el.style.setProperty('--cm', this.MainColor);
    }
    if (this.WidgetColor) {
      this.$el.style.setProperty('--cw', this.WidgetColor);
    }
    if (this.BakcgroundColor) {
      this.$el.style.setProperty('--cb', this.BakcgroundColor);
    }
    if (this.TextColor) {
      this.$el.style.setProperty('--ct', this.TextColor);
    }
  },
};
</script>




<style scoped>
.custom-select{
  --color-main: var(--cw, white);
  --color-gray: var(--cb, #FAFAFA);
  --color-btnmain: var(--cmlight, #EBEDFF);
  --color-global: var(--cm, #6266EA);
  --color-text: var(--ct, #535C69);
  --color-shadow: rgb(216, 216, 216);
}

.custom-value-button {
  cursor: pointer;
  margin: 5px 0;
}
.items.dropdownUp {
  z-index: 9999;
}

.search input{
  background-image: url(../../static/img/search.svg);
  background-repeat: no-repeat;
  padding-left: 20px;
  background-position: 0;
}

.search input:focus{
    outline: none;
    border: none;
}
.search{
  background-color: white;
  border-bottom: solid 1px #C6CBD2;
}
.customInput{
  background: var(--color-gray);
  color: var(--color-text);
  border-bottom: solid 1px #C6CBD2;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-left: -0.8em;
  margin-right: -0.8em;
}
.customInput:hover{
  background-color: white !important;
  border-bottom: solid 1px #C6CBD2;
}
.customInput input{
  margin: 0;
  color: #535C69;
  background-color: white;
}

.customInput input:focus{
  outline: none;
  border: none;
}

.search input{
  margin: 0;
  color: #535C69;
  background-color: white;
}

.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 36px;
  line-height: 36px;
  font-family: TT Norms Medium;
  font-size: 13px;
}

.selected {
  background: var(--color-gray);
  color: var(--color-text);
  border-radius: 3px;
  padding-left: .8em;
  cursor: pointer;
  user-select: none;
  height: 36px;
}

.selected.open {
  border-radius: 3px 3px 3px 3px;
}

.selected:after {
  position: absolute;
  content: "";
  top: 16px;
  right: 1em;
  width: 0;
  height: 0;
  border: 3px solid transparent;
  border-color: #535C69 transparent transparent transparent;

  rotate: 0deg;
  transition: .3s ease all;
}

.selected.open:after{
  top: 13px;
  rotate: 180deg;
  transition: .3s ease all;
}

.items {
  opacity: 1;
  max-height: 170px;
  overflow: hidden;
  overflow-y: scroll;
  margin-top: 10px;
  color: #535C69;
  border-radius: 3px 3px 3px 3px;
  position: absolute;
  background: var(--color-main);
  color: var(--color-text);
  left: 0;
  right: 0;
  z-index: 1;
  transition: all .2s ease;
}

.items div {
  color: #535C69;
  padding-left: 1em;
  padding-right: .8em;
  cursor: pointer;
  user-select: none;
}

.items div:hover {
  opacity: .5;
}

.items .search:hover {
  background-color: white;
}


.selectHide div{
  padding-left: 1em;
  padding-right: 1em;
}

.selectHide {
  opacity: 0;
  max-height: 170px;
  overflow-y: scroll;
  margin-top: 10px;
  color: #535C69;
  border-radius: 3px 3px 3px 3px;
  position: absolute;
  background: var(--color-gray);
  color: var(--color-text);
  left: 0;
  right: 0;
  z-index: 1;
  transition: all .2s ease;
  visibility: hidden;
}
</style>
