<template>
  <div class="main">
    <div class="transition">
      <router-link to="/dashboard/clients" class="employesss-link">Клиенты</router-link>
      <div class="arrow-container">
        <img src="../../static/img/arrow-right.png" alt="Стрелка вправо" class="arrow-icon">
      </div>
      <p class="creation_text">{{ firstname + ' ' + secondname }}</p>
    </div>

    <div class="settings">
      <div class="forms">
        <div class="name">
          <div class="name-container">
            <label for="userName">Имя</label>
            <div class="input_container_top">
              <input type="text" placeholder="Введите имя" v-model="firstname" :class="{ 'input-error': v$.firstname.$error }">
            </div>
          </div>
        </div>
        <div class="second_name">
          <div class="name-container">
            <label for="">Фамилия</label>
            <div class="input_container_top">
              <input type="text" placeholder="Введите фамилию" v-model="secondname" :class="{ 'input-error': v$.secondname.$error }">
            </div>
          </div>
        </div>
        <div class="divider"></div>
        <div class="photo">
          <div class="photo-container">
            <label for="userPhoto">Фотография</label>
            <div class="input_container">
              <label class="custom-file-upload" :class="{'custom-file-upload-error' : v$.avatar.$error}" v-if="!fileNameVariable">
                <input type="file" accept="image/*" @change="handleFileUpload($event)" :class="{ 'custom-file-upload-error': v$.fileNameVariable.$error }"/>Нажмите, чтобы добавить 
              </label>
              <label style="color: #535C69;" class="custom-file-upload" :class="{'custom-file-upload-error' : v$.avatar.$error}" v-else>
                <input type="file" accept="image/*" @change="handleFileUpload($event)"  :class="{ 'custom-file-upload-error': v$.fileNameVariable.$error }"/>{{ fileNameVariable }}
              </label>
            </div>
            <p class="photo-info">до 5 МБ, PNG, JPG, JPEG. Для замены - загрузите заново</p>
          </div>
        </div>
        <div class="divider"></div>
        <div class="email">
          <div class="email-container">
            <label for="userMail">Email</label>
            <div class="input_wrapper">
              <input type="email" placeholder="client@mail.ru" class="input_warning" v-model="email" :class="{ 'input-error': v$.email.$error }">
              <div class="svg_warning_wrapper">
                <img src="../../static/img/warning.svg" alt="" class="svg_warning">
              </div>
            </div>
            <p class="email-header">Клиент еще не подтвердил свою почту</p>
          </div>
        </div>
        <div class="divider"></div>
        <div class="phone">
          <div class="phone-container">
            <label for="userPhone">Телефон</label>
            <div class="input_wrapper">
              <input id="userphone" placeholder="+7" v-model="value" :class="{ 'input-error': v$.value.$error }">
              <div class="svg_warning_wrapper">
                <img src="../../static/img/warning.svg" alt="" class="svg_warning">
              </div>
            </div>
            <p class="email-header">Клиент еще не подтвердил свой номер телефона</p>
          </div>
        </div>
        <div class="divider"></div>
        <div class="change">
          <button type="button" class="button-change" @click="create_client()">Сохранить</button>
          <button type="button" class="button-back" @click="this.$router.push('/dashboard/clients')">Вернуться назад</button>
        </div>
      </div>
    </div>
    <MessageAlert :message="alertMessage" :color="alertColor"/>
  </div>
</template>


<script>

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import MessageAlert from "../components/MessageAlert.vue";
export default {
  setup () {
    return { v$: useVuelidate() }
  },
  components: { MessageAlert },
  data() {
    return {
      clientID: '',
      firstname: '',
      secondname: '',
      email: '',
      value: '',
      avatar: '',
      fileNameVariable:'',
      alertMessage: '',
      alertColor: '',
      coverDataUrl: '',
    }
  },
  validations () {
    return {
      firstname: { required },
      secondname: { required },
      email: { required },
      value: { required },
      fileNameVariable: { required },
      avatar: { required },
    }
  },
  methods:{
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.avatar = file;
      const fileName = file.name;
      if (fileName.length > 20) {
        this.fileNameVariable = fileName.slice(0, 20) + '...' + fileName.slice(-4);
      }else{
        this.fileNameVariable = fileName.slice(0, 20);
      }

      this.readCoverDataUrl(); 
    },

    readCoverDataUrl() {
      if (!this.avatar) return;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.coverDataUrl = e.target.result;
      };
      reader.readAsDataURL(this.avatar);
    },



    async create_client() {
      const fieldsToValidate = [
        'firstname',
        'secondname',
        'email',
        'avatar',
        'value',
      ];

      fieldsToValidate.forEach(field => {
        this.v$[field].$touch();
      });

      const hasErrors = fieldsToValidate.some(field => this.v$[field].$error);
      if (hasErrors){
        this.alertMessage = 'Пожалуйста, заполните выделенные поля';
        this.alertColor = '#F97F7F';
      }else{
        const formData = new FormData();
        formData.append('firstname', this.firstname);
        formData.append('secondname', this.secondname);
        formData.append('mail', this.email);
        formData.append('phone', this.value);
        
        if (this.coverDataUrl) {
          formData.append('avatar', this.avatar);
        }
        

        console.log(formData)
        try {
          const response = await this.$axios.patch(`/api/clients/${this.clientID}/`, formData, {withCredentials: true});
          console.log('Client updated successfully:', response.data);
          this.alertMessage = 'Клиент успешно изменен';
          this.alertColor = '#0BB6A1';
          setTimeout(() => {
            this.$router.push('/dashboard/clients')
          }, 500);
        } catch (error) {
          console.error('Error updating client:', error.response.data);
          this.alertMessage = 'Ошибка редактирования клиента';
          this.alertColor = '#F97F7F';
        }
      }
    },



    getObjectById() {
      for (let obj of this.clients) {
        console.log(obj)
          if (obj.id === parseInt(this.$route.params.clientId)) {
            this.clientID = obj.id
            this.firstname = obj.firstname
            this.secondname = obj.secondname
            this.email = obj.mail
            this.value = obj.phone
            this.avatar = obj.avatar
          }
      }
      return null;
    }
  },
  computed:{
    clients(){
      return this.$store.state.clients;
    }
  },
  mounted(){
    this.getObjectById();
  }
}
</script>

<style scoped>
.main{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.transition {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.employesss-link {
  font-family: 'TT Norms Medium';
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  text-decoration: none;
  color: #AFB6C1;
}
.creation_text {
  color: #535C69;
  font-family: 'TT Norms Medium';
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
}
.arrow-container {
  display: flex;
  align-items: center;
}

.arrow-icon {
  height: 50%;
}
.inputFile-empty{
  font-family: TT Norms;
  color: #D2D8DE;
}

.inputFile-file{
  font-family: TT Norms;
  color: #535C69;
}
.settings {
  text-align: center;
  width: fit-content;
  height: auto;
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 5px;
  width: 490px;
}

.name-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

label {
  margin-bottom: 5px;
  font-family: TT Norms Medium;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0em;
}

.input_container {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.input_container_mail {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.input_container_phone {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.input_container_top {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

input {
  margin-bottom: 0;
  width: 100%;
}

.email-btn{
  display: flex;
  flex-direction: row;
  gap: 10px;
}

p{
  text-align: left;
  margin: 10px 0;
  font-family: TT Norms Medium;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0em;
  color: rgba(83, 92, 105, 0.7);
}

.email-header{
  font-family: TT Norms Medium;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.16px;
  text-align: left;
  color: #7D838C;
  margin-top: 5px;
  margin-bottom: 0;
}
.phone-btn{
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.button-save {
  background-color: #EFEFFF;
  color: #6266EA;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}
.button-save:hover {
  background-color: #DBDDFF;
  color: #6266EA;
}
.button-change {
  background-color: #EFEFFF;
  color: #6266EA;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.button-change:hover {
  background-color: #DBDDFF;
  color: #6266EA;
}
.divider {
  border-bottom: 1px solid rgba(50, 56, 74, 0.1); 
  width: auto;
  margin: 20px 0;
}
.custom-file-upload {
  width: 100%;
  height: 36px;
  display: flex;
  padding: 8px 10px;
  cursor: pointer;
  background-color: #F3F5F6;
  color: #D2D8DE;
  align-items: center;
  margin-bottom: 0;
  font-weight: 500;
  background-image: url(../../static/img/paperclip.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
}

.custom-file-upload input[type="file"] {
  display: none;
}
.password{
  color: #7D838C;
  font-family: TT Norms Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
}
h2{
  font-family: TT Norms Medium;
  margin: 0;
  padding-bottom: 20px;
  color: #535C69;
}
.photo-info{
  color: #D2D8DE;
  font-family: TT Norms Medium;
  font-size: 12px;
  line-height: 14.16px;
  text-align: left;
  
  margin: 5px 0;
}
input::placeholder {
  font-family: 'TT Norms Medium';
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0em;
  color: #D2D8DE;
}
.name{
  margin-bottom: 20px;
}
.change{
  justify-content: end;
  display: flex;
  gap: 10px;
}
.button-back{
  background: #FFFFFF;
  border: 1px solid #DDE1E5;
  color: #535C69;
}
.button-back:hover{
  color: #6266EA;
}
.input_wrapper {
  position: relative;
  width: 100%; /* Устанавливаем ширину контейнера инпута */
}

.input_warning {
  width: 100%;
  padding-right: 30px;
  transition: all 0.2s ease;
}

.svg_warning_wrapper {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 9;
  height: 14px;
}

.svg_warning {
  pointer-events: none;
}

.svg_warning_wrapper:hover::after {
  content: "Email адрес не подтвержден";
  position: absolute;
  bottom: -40px; /* Позиционируем сообщение под иконкой */
  left: 50%;
  transform: translateX(-50%);
  background-color: #FFFFFF;
  color: #7D838C;
  z-index: 15;
  padding: 5px 10px; /* Добавляем отступы для текста */
  border-radius: 5px;
  font-family: 'TT Norms Medium', sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  box-shadow: 0px 2px 8px 0px #7D879066;
  display: flex;
  max-width: 202px;
  height: auto;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.phone-container .svg_warning_wrapper:hover::after {
  content: "Телефон не подтвержден";
}
</style>