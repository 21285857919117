<template>
  <div class="main">
    <h2>Настройка профиля</h2>
    <div class="settings">
      <div class="forms">
        <div class="name">
          <div class="name-container">
            <label for="userName">Имя</label>
            <div class="input_container_top">
              <input type="text" id="userName" v-model="User.name">
              <button @click="saveName" class="button-save">Сохранить изменения</button>
            </div>
          </div>
        </div>
        <div class="divider"></div>
        <div class="photo">
          <div class="photo-container">
            <label for="userPhoto">Фотография</label>
            <div class="input_container">
              <label class="custom-file-upload">
                <input type="file" accept="image/*" ref="fileInput" @change="handleFileUpload($event)"/><span :class="{'inputFile-empty' : !fileNameVariable, 'inputFile-file' : fileNameVariable}">{{ fileNameVariable || 'Нажмите, чтобы загрузить' }}</span>
              </label>
              <button @click="saveImage" class="button-save">Сохранить изменения</button>
            </div>
            <p class="photo-info">до 5 МБ, PNG, JPG, JPEG. Для замены удалите миниатюру и загрузите заново</p>
          </div>
        </div>
        <div class="divider"></div>
        <div class="email">
          <div class="email-container"> 
            <label for="userMail">Email</label>
            <p><span class="email-header">Будьте внимательны!</span> Email является логином для входа в сервис, если вы измените Email, то при авторизации нужно использовать обновленный Email</p>
            <div class="input_container_mail">
              <div class="input_wrapper">
                <input type="mail" id="userMail" :value="User.email" disabled class="input_warning">
                <div class="svg_warning_wrapper">
                  <img src="../../static/img/warning.svg" alt="" class="svg_warning">
                </div>
              </div>
              <div class="email-btn">
                <button @click="acceptMail">Подтвердить Email</button>
                <button @click="changeMail" class="button-change">Изменить email</button>
                <ChangeMailPage v-if="showMail"/>
              </div>
            </div>
          </div>
        </div>
        <div class="divider"></div>
        <div class="phone">
          <div class="phone-container">
            <label for="userPhone">Телефон</label>
            <p>Получайте SMS-уведомления о новых заявках</p>
            <div class="input_container_phone">
              <div class="input_wrapper">
                <input id="userphone" :value="'+' + User.phone.toString()" disabled class="input_warning">
                <div class="svg_warning_wrapper">
                  <img src="../../static/img/warning.svg" alt="" class="svg_warning">
                </div>
              </div>
              <div class="phone-btn">
                <button @click="acceptPhone">Подтвердить телефон</button>
                <button @click="changePhone" class="button-change">Изменить Телефон</button>
                <ChangePhonePage v-if="showPhone"/>
              </div>
            </div>
          </div>
        </div>
        <div class="divider"></div>
        <div class="change">
          <label>Пароль</label>
          <p class="password">Был установлен {{when_changed}}</p>
          <button @click="showModals" type="button" class="button-change">Изменить пароль</button>
          <ChangePasswordPage v-if="showModal"/>
        </div>
      </div>
    </div>
    <MessageAlert :message="alertMessage" :color="alertColor"/>
  </div>
</template>

<script>
import ChangePasswordPage from './ChangePasswordPage.vue';
import ChangeMailPage from './ChangeMailPage.vue';
import ChangePhonePage from './ChangePhonePage.vue';
import { mapMutations } from 'vuex';
import MessageAlert from "../components/MessageAlert.vue";

export default {
  components: { ChangePasswordPage, ChangeMailPage, ChangePhonePage, MessageAlert },
  data() {
    return {
      showModal: false,
      showMail: false,
      showPhone: false,
      when_changed: 'Загрузка...',
      User: {
        name: '',
        avatar: '',
        email: '',
        phone: '',
      },

      fileNameVariable: '',

      alertMessage: null,
      alertColor: '',
    };
  },
  watch: {
    'User.name': function() {
      this.alertMessage = null;
    },
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      const fileName = file.name; // извлекаем название файла
      if (fileName.length > 30) {
        this.fileNameVariable = fileName.slice(0, 30) + '...' + fileName.slice(-4);
      }else{
        this.fileNameVariable = fileName.slice(0, 30);
      }
    },

    emailChanged(){
      this.alertMessage = 'Поле Email успешно изменено!';
      this.alertColor = '#0BB6A1';
    },
    passwordChanged(){
      this.alertMessage = 'Пароль успешно изменено!';
      this.alertColor = '#0BB6A1';
    },

    ...mapMutations(['setUpdateSidebar']),
    rerenderSidebar() {
      this.setUpdateSidebar();
    },

    showModals() {
      this.showModal = true;
    },
    changeMail() {
      this.showMail = true;
    },
    changePhone() {
      this.showPhone = true;
    },

    get_profile(){
      this.showMail = false
      this.$axios.post('/api/getprofile/', { user_id:  this.$store.state.registrationData.user_id}, {withCredentials: true})
      .then(response => {
        this.avatar = "" + response.data.profile.avatar
        this.User.name = response.data.profile.name
        this.company = response.data.profile.company_name
        this.User.phone =response.data.phone
        this.User.email =response.data.email
        console.log(response.data.password_set)
        if(response.data.password_set==0){
          this.when_changed = "сегодня"
        }else if(response.data.password_set==30){
          this.when_changed = `месяц назад`
        }else if(response.data.password_set>0){
          this.when_changed = `${response.data.password_set} дней назад`
        }else if(response.data.password_set<122){
          this.when_changed = `${Math.floor(response.data.password_set/30)} месяца назад`
        }else if(response.data.password_set>122){
          this.when_changed = `${Math.floor(response.data.password_set/30)} месяцев назад`
        }
      })
      .catch(error => {
        // Ошибка при получении данных
        console.error('Ошибка при получении данных о пользователе:', error);
        this.alertMessage = 'Произошла ошибка при получении данных о пользователе!';
        this.alertColor = '#F97F7F';
      });
    },
    saveName() {
      this.$axios.post('/api/change_name/', { 
          id: this.$store.state.registrationData.user_id,
          name: this.User.name 
        }, {withCredentials: true})
        .then(response => {
          console.log('Имя успешно сохранено:', response.data);
          this.rerenderSidebar();
          this.alertMessage = 'Поле Имя успешно изменено!';
          this.alertColor = '#0BB6A1';
        })
        .catch(error => {
          console.error('Ошибка при сохранении имени:', error);
          this.alertMessage = 'Произошла ошибка при сохранении имени!';
          this.alertColor = '#F97F7F';
        });
    },
    saveImage() {
      const formData = new FormData();
      formData.append('id', this.$store.state.registrationData.user_id);
      formData.append('avatar', this.$refs.fileInput.files[0]);

      this.$axios.post('/api/change_avatar/', formData, {
        headers: {
          withCredentials: true,
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(response => {
          console.log('Изображение успешно сохранено:', response.data);
          this.rerenderSidebar();
          this.alertMessage = 'Поле Фото успешно изменено!';
          this.alertColor = '#0BB6A1';
        })
        .catch(error => {
          console.error('Ошибка при сохранении изображения:', error);
          this.alertMessage = 'Произошла ошибка при сохранении фото!';
          this.alertColor = '#F97F7F';
        });
    },
  },
  mounted(){  
    this.get_profile();
  }
};
</script>

<style scoped>
.inputFile-empty{
  font-family: TT Norms Medium;
  color: #D2D8DE;
}

.inputFile-file{
  font-family: TT Norms Medium;
  color: #535C69;
}
.settings {
  text-align: center;
  width: fit-content;
  height: auto;
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 5px;
}

.name-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

label {
  margin-bottom: 10px;
  font-family: TT Norms Medium;
  font-size: 14px;
  line-height: 16.52px;
  letter-spacing: 0em;
}

.input_container {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.input_container_mail {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.input_container_phone {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.input_container_top {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

input {
  margin-bottom: 0;
  width: 50%;
}

.email-btn{
  display: flex;
  flex-direction: row;
  gap: 10px;
}

p{
  text-align: left;
  margin: 10px 0;
  width: 450px;
  font-family: TT Norms Medium;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0em;
  color: rgba(83, 92, 105, 0.7);
}

.email-header{
  font-family: TT Norms Medium;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(83, 92, 105, 0.7);
}
.phone-btn{
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.button-save {
  background-color: #EFEFFF;
  color: #6266EA;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}
.button-save:hover {
  background-color: #DBDDFF;
  color: #6266EA;
}
.button-change {
  background-color: #EFEFFF;
  color: #6266EA;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.button-change:hover {
  background-color: #DBDDFF;
  color: #6266EA;
}
.divider {
  border-bottom: 1px solid rgba(50, 56, 74, 0.1); 
  width: auto;
  margin: 20px 0;
}
.custom-file-upload {
  width: 50%;
  height: 36px;
  display: flex;
  padding: 8px 10px;
  cursor: pointer;
  background-color: #F3F5F6;
  color: #D2D8DE;
  align-items: center;
  margin-bottom: 0;
  font-weight: 500;
  background-image: url(../../static/img/paperclip.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  border-radius: 3px;
}

.custom-file-upload input[type="file"] {
  display: none;
}
.password{
  color: #7D838C;
  font-family: TT Norms Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
}
h2{
  font-family: TT Norms Medium;
  margin: 0;
  padding-bottom: 20px;
  color: #535C69;
}
.photo-info{
  color: #AFB6C1;
  font-family: TT Norms Medium;
  font-size: 12px;
  line-height: 14.16px;
  letter-spacing: 0em;
  margin: 5px 0;
}
.email-header{
  font-family: TT Norms Bold;
}

.input_wrapper {
  position: relative;
  width: 250px; /* Устанавливаем ширину контейнера инпута */
}

.input_warning {
  width: 100%;
  padding-right: 30px;
  transition: all 0.2s ease;
  pointer-events: none;
}

.svg_warning_wrapper {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 9;
  height: 14px;
}

.svg_warning {
  pointer-events: none;
}

.svg_warning_wrapper:hover::after {
  content: "Email адрес не подтвержден";
  position: absolute;
  bottom: -40px; /* Позиционируем сообщение под иконкой */
  left: 50%;
  transform: translateX(-50%);
  background-color: #FFFFFF;
  color: #7D838C;
  z-index: 15;
  padding: 5px 10px; /* Добавляем отступы для текста */
  border-radius: 5px;
  font-family: 'TT Norms Medium', sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  box-shadow: 0px 2px 8px 0px #7D879066;
  display: flex;
  max-width: 202px;
  height: auto;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.input_container_phone .svg_warning_wrapper:hover::after {
  content: "Телефон не подтвержден";
}
</style>