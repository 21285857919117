<template>
  <div class="event-container">
    <div class="event" :style="{ borderColor: calcColor(eventData.status), backgroundColor: calcColorBrighter(eventData.status) }"  @click="about = true, colorEditor = false" tabindex="1">
        <p class="event-name">{{ usluga.name }}</p>
        <p class="event-time">{{ calcDate(eventData.time) }}</p>
        
        <!--  v-text-ellipsis="usluga.name" -->
    </div>

    <div v-if="about" class="about">
      <div class="left">
        <div class="text-block">
          <p class="about-subHeader">Услуга</p>
          <p class="about-header">{{ usluga.name }}</p>
        </div>
        <div class="text-block">
          <p class="about-subHeader">Специалист</p>
          <p class="about-header">{{ sotrudnik.firstname + ' ' + sotrudnik.secondname }}</p>
        </div>
        <div class="text-block-row">
          <div class="text-block">
            <p class="about-subHeader">Стоимость</p>
            <p class="about-header">{{ usluga.cost }}</p>
          </div>
          <div class="text-block">
            <p class="about-subHeader">Время записи</p>
            <p class="about-header">{{ calcDate(eventData.time) }}</p>
          </div>
        </div>
        <router-link
          class="more"
          :to="{
            path: `/dashboard/clients/${client.id}/info`,
            params: { clientId: client.id }
          }"
        >
          Подробнее
        </router-link>
      </div>
      <div class="right">
        <svg class="close-icon" @click="about = false" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="20" rx="10" fill="#FAFAFA"/>
        <path d="M9.15143 9.99994L6.57568 12.5757L7.42421 13.4242L9.99996 10.8485L12.5757 13.4242L13.4242 12.5757L10.8485 9.99994L13.4242 7.42421L12.5757 6.57568L9.99996 9.15141L7.42423 6.57568L6.5757 7.42421L9.15143 9.99994Z" fill="#AFB6C1"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props:['eventData'],
    data() {
        return {
          about: false,

          usluga: Object,
          sotrudnik: Object,
          client: Object,
        };
    },
    methods: {
      calcDate(dateString) {
      try {
        const date = new Date(dateString);

        if (isNaN(date)) {
          throw new Error('Invalid date');
        }

        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        return `${hours}:${minutes}`;
      } catch (error) {
        console.error('Error parsing date:', error);
        return 'Invalid date';
      }
    },
     
      async getusluga(i) {
          try {
              const id = i;
              const response = await this.$axios.get(`/api/get_uslugabyid/?variable=${id}`);
              this.usluga = response.data;
          } catch (error) {
              console.error('Ошибка при получении данных о Услуге:', error);
              throw error; // throw error, чтобы предоставить возможность обработки ошибки вверх по стеку вызовов
          }
      },
      async getemployee(i) {
          try {
              const id = i;
              const response = await this.$axios.get(`/api/get_employeebyid/?variable=${id}`);
              this.sotrudnik = response.data;
          } catch (error) {
              console.error('Ошибка при получении данных о Сотруднике:', error);
              throw error; // throw error, чтобы предоставить возможность обработки ошибки вверх по стеку вызовов
          }
      },
      async getclient(i) {
          try {
              const id = i;
              const response = await this.$axios.get(`/api/get_clientbyid/?variable=${id}`);
              this.client = response.data;
          } catch (error) {
              console.error('Ошибка при получении данных о Клиенте:', error);
              throw error; // throw error, чтобы предоставить возможность обработки ошибки вверх по стеку вызовов
          }
      },
      calcColor(status){
        switch (status) {
          case 'New':
            return '#28CCF0'
          case 'Adopted':
            return '#F7D37D'
          case 'Canceled':
            return '#F97F7F'
          case 'Done':
            return '#00A490'
          default:
          return 'black'
        }
      },
      calcColorBrighter(status){
        switch (status) {
          case 'New':
            return '#28ccf01a'
          case 'Adopted':
            return '#f7d37d1a'
          case 'Canceled':
            return '#f97f7f1a'
          case 'Done':
            return '#00A4901a'
          default:
          return 'black'
        }
      }
    },
    mounted() {

      this.getusluga(this.eventData.usluga);
      this.getemployee(this.eventData.employee)
      this.getclient(this.eventData.client)
    },
}
</script>

<style scoped>
.color-circle{
  width: 30px;
  height: 30px;
  border-radius: 15px;
  cursor: pointer;
}
.colors-row{
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
}
.deleteBtn{
  background-color: white;
  font-family: TT Norms Medium;
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  text-align: left;
  color: #AFB6C1;
}
.color-editor{
  position: absolute;
  background-color: white;
  padding: 20px;
}
.about{
  position: absolute;
  z-index: 100;
  background-color: white;
  top: 110%;
  filter: drop-shadow(0 0 10px rgb(233, 233, 233));
}
.event-container{
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.event{
  padding: 10px;
  border: solid 1px #FFCF7D;
  border-radius: 5px;
  background-color: white;
  transition: all .2s ease;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.event:hover{
    filter: drop-shadow(0 0 10px rgb(233, 233, 233));
}
.event-name{
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event-name, .event-time{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: TT Norms Medium;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  margin: 0;
  color: #535C69;
}

.about{
  border-radius: 5px;
  padding: 10px;
  min-width: 220px;
  width: fit-content;
  display: flex;
  align-items: start;
  justify-content: space-between;
  opacity: 1;
  transition: all .2s ease;
}

.about-subHeader{
  font-family: TT Norms Medium;
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  text-align: left;
  color: #AFB6C1;
  margin: 0;
}
.about-header{
  font-family: TT Norms Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  text-align: left;
  color: #535C69;
  margin: 0;
}

.text-block{
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.left{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.text-block-row{
  display: flex;
  align-items: center;
  gap: 30px;
}

.more{
  font-family: TT Norms Medium;
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  text-align: left;
  color: #AFB6C1;
  text-decoration: none;
}

.close-icon:hover{
  cursor: pointer;
}
.close-icon:hover path{
  fill: #AFB6C1;
  transition: all .2s ease;
}
.close-icon:hover path{
  fill: #F4511E;
  transition: all .2s ease;
}
</style>