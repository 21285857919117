<template>
  <div class="modal">
    <div class="modal-content" v-if="step == 1">
      <p>Изменение пароля</p>
      <div class="change-password-modal">
        <div class="input_container">
          <label for="currentPassword">Текущий пароль</label>
          <input v-model="currentPassword" type="password" id="currentPassword" :class="{'input-error': error !== ''}">
        </div>
        <div class="input_container">
          <label for="newPassword">Новый пароль</label>
          <input v-model="newPassword" type="text" id="newPassword">
        </div>
        <div class="input_container">
          <label for="confirmPassword">Повторите новый пароль</label>
          <input v-model="confirmPassword" type="password" id="confirmPassword">
          <div class="error" v-if="error">{{error}}</div>
        </div>
        <div class="button-container">
          <button v-if="showChangeButton" class="button-change_hover" @click="generateResetPassToken">Сменить пароль</button>
          <button v-else class="button-change">Сменить пароль</button>
          <button @click="this.$parent.showModal = false" class="button-exit">Отмена</button>
        </div>
      </div>
    </div>

    <div class="modal-content" v-if="step == 2">
      <p>Подтверждение пароля</p>
      <div class="change-password-modal">
        <span>Для подтвердения смены пароля вам отправден код в письме на Email</span>
        <div class="input_container">
          <label for="code">Код подтверждения {{ timeLeft }}c</label>
          <input v-model="code" type="text" id="code">
          <div class="error" v-if="error">{{error}}</div>
        </div>
        <div class="button-container">
          <button v-if="showChangeButton" class="button-change_hover" @click="changePassword">Подтвердить</button>
          <button v-else class="button-change">Сменить пароль</button>
          <button @click="this.$parent.showModal = false" class="button-exit">Отмена</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {
      timeLeft: 60,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      showChangeButton: false,
      error: '',
      step: 1,
      code: '',
    };
  },
  watch: {
    currentPassword(value) {
      this.showChangeButton = this.newPassword !== '' && this.confirmPassword !== '' && value !== '';
      this.error = '';
    },
    newPassword(value) {
      this.showChangeButton = this.currentPassword !== '' && this.confirmPassword !== '' && value !== '';
      this.error = '';
    },
    confirmPassword(value) {
      this.showChangeButton = this.currentPassword !== '' && this.newPassword !== '' && value !== '';
      this.error = '';
    },
  },
  methods: {
    async generateResetPassToken() {
      const userId = this.$store.state.registrationData.user_id;
      try {
        
        const response = await this.$axios.post('/api/generatePassToken/', { user_id: userId, old_pass: this.currentPassword,}, {withCredentials: true});
        console.log(response.data)
        this.step = 2
        this.startTimer();
      } catch (error) {
        if (error.response.status == 400) {
         
          console.error('Error response:', error.response.data);
          if (this.step == 1) {
            this.error = "Неверный пароль"
          }else if(this.step == 2){
            this.error = "Неверный код"
          }

        } else {
          
          console.error('Error message:', error.message);
        }
      }
    },

    startTimer() {
      this.timer = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft -= 1;
        } else {
          clearInterval(this.timer);
        }
      }, 1000);
    },

    async changePassword() {
    if (this.newPassword == this.confirmPassword) {
      const data = {
        old_pass: this.currentPassword,
        new_pass: this.newPassword,
        user_id: this.$store.state.registrationData.user_id,
        token: this.code
      };

      try {
        const response = await this.$axios.post('/api/change_pass_two/', data, {withCredentials: true});
        console.log('Password changed:', response.data);
        this.$parent.showModal = false;
        this.$parent.passwordChanged();
      } catch (error) {
        console.error('Error changing password:', error);
        this.error = "Неверный пароль"
      }
    } else {
      this.error = "Пароли не совпадают"
    }
  }
  },
};
</script>

<style scoped>
span{
  color: #535C69;
  font-family: TT Norms Light;
  font-size: 14px;
  text-align: left;
}
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.modal-content {
  width: 360px;
  height: fit-content;
  background-color: #FFFFFF;
  padding: 40px;
  border-radius: 8px;
}
.button-container{
  display: flex;
  gap: 10px;
}
label{
  color: #535C69;
  font-family: TT Norms Medium;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
}
p{
  font-family: TT Norms Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #535C69;
  margin: 0;
  margin-bottom: 20px;
}
.button-change{
  background: #4C5D6E33;
  color: #FFFFFF;
}
.button-exit{
  border: 1px solid #4C5D6E33;
  background: #FFFFFF;
  color: #7D838C;
}
input{
  margin-bottom: 0;
}
.button-change_hover{
  background-color:#EFEFFF;
  color: #6266EA;
}
.button-change_hover:hover {
  background-color: #6266EA;
  color: #FFFFFF;
}
.error{
  font-family: TT Norms Medium;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #F97F7F;
}
.change-password-modal{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.input-error{
  border: 1px solid #F97F7F; /* устанавливаем стиль границы для случая, когда пароль не верный */
}
.input_container{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.button-exit:hover{
  color: #6266EA;
}

</style>
